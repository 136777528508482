// import { WebccRuntime, useToggleDisplay } from "@alumisoft/package_CadastroEmpresa";
import {
  useState,
  createContext,
  useMemo,
  SetStateAction,
  Dispatch,
} from "react";
import { IGetEmpresasById } from "shared/interfaces/empresa";

export type CadastroEmpresaContextProvider = {
  children: JSX.Element;
};

export type CadastroEmpresaContextValues = {
  dadosEmpresa?: IGetEmpresasById;
  setDadosEmpresa: Dispatch<SetStateAction<IGetEmpresasById | undefined>>;
  selectedItems: number[];
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
  dataUltimaAtualizacao: string | Date | undefined;
  setDataUltimaAtualizacao: Dispatch<SetStateAction<string | Date | undefined>>;
};

export const CadastroEmpresaContext = createContext(
  {} as CadastroEmpresaContextValues
);

export const CadastroEmpresaProvider = ({
  children,
}: CadastroEmpresaContextProvider) => {
  const [dadosEmpresa, setDadosEmpresa] = useState<IGetEmpresasById>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [dataUltimaAtualizacao, setDataUltimaAtualizacao] = useState<
    string | Date
  >();

  const values = useMemo(() => {
    return {
      dadosEmpresa,
      setDadosEmpresa,
      selectedItems,
      setSelectedItems,
      dataUltimaAtualizacao,
      setDataUltimaAtualizacao,
    };
  }, [dadosEmpresa, selectedItems, dataUltimaAtualizacao]);

  return (
    <CadastroEmpresaContext.Provider value={values}>
      {children}
    </CadastroEmpresaContext.Provider>
  );
};
