import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import criptoJS from "crypto-js";

import { useAuth } from "shared/hooks/auth";

import { Dropdown, Form, Nav, NavDropdown, Navbar } from "react-bootstrap";

// import { IFiltro } from "../../common/interfaces/produtos.interfaces";
// import { ProdutoContext } from "../../contexts/produtos/produtosContext";
// import { EsquadriaProvider } from "../../pages/Catalogos/hook";
import SelectComponent, { Items } from "components/Select";
import { FormProvider, useForm } from "react-hook-form";
import history from "shared/helpers/histoy";
import LogoMof from "assets/images/logoMof";
import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import { SidebarProvider } from "contexts/sidebarContext";
import { apiAntigo } from "services/axios/api";
import ReactSelect from "react-select";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import configs from "Config";

interface PrivateProviderProps {
  children?: React.ReactNode;
}

export const Private = ({ children }: PrivateProviderProps) => {
  const navigate = useNavigate();
  const { control, ...methods } = useForm({
    mode: "onBlur",
  });
  //Contexts
  const { user, signOut, signInChange, havePermission } = useAuth();
  const [users, setUsers] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (havePermission(["Master"])) {
          const { data } = await apiAntigo.get("/geral/selecaoTrocaUsuario");

          setUsers(data);
        }
      } catch (error: any) {
        getErrorsByApi(
          error,
          "Tivemos problema internos ao buscar esse Usuario"
        );
      }
    };

    fetchData();
  }, []);

  const handleChangeLogin = async ({ value }: any) => {
    await signInChange({ idUsuario: value });
    setShouldRedirect(true);
  };

  const navGerencia = <i className="fas fa-cogs mr-1">Gerência</i>;

  // const produtoContext = useContext(ProdutoContext);

  // const filtro = produtoContext.filtro;
  const location = useLocation();

  const queryString = require("querystring");
  const [modoEdicao, setModoEdicao] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const modoEdicao = await api.get("");
  //     setModoEdicao(modoEdicao.data);
  //   };

  //   fetchData();
  // }, []);

  // async function handleFiltro(filtro: IFiltro, modoEdicao: boolean) {
  //   replace(`/produtos?${queryString.stringify(filtro)}`);
  // }

  // function reloadPageIfNeeded() {
  //   if (reloadPage) {
  //     window.location.reload();
  //     setReloadPage(false);
  //   }
  // }

  // useEffect(() => {
  //   reloadPageIfNeeded();
  // }, [reloadPage]);

  const navProdutos = (
    <i className="fas fa-cubes mr-1">
      <span className="font-weight-normal font-padrao"> Produtos</span>
    </i>
  );

  const navAcoes = (
    <i className="fas fa-cogs mr-1">
      <span className="font-weight-normal font-padrao"> Ações</span>
    </i>
  );

  // useEffect(() => {
  //   handleFiltro(filtro, modoEdicao);
  // }, [filtro, modoEdicao]);

  useEffect(() => {
    if (user && shouldRedirect) {
      setShouldRedirect(false);
      const criptoValue = criptoJS.AES.encrypt(
        user.hashSession,
        configs.REACT_APP_API_KEY ?? ""
      ).toString();
      const encodedCriptoValue = btoa(criptoValue);
      window.location.href = `${configs.REACT_APP_SITE_ANTIGO_URL}trocarUsuario?hashId=${encodedCriptoValue}`;
    }
  }, [user, shouldRedirect]);

  return (
    <SidebarProvider>
      <>
        <header className="navbar navbar-expand-md pb-2">
          <div className="container-xl px-3">
            <a className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pr-0 pr-md-4">
              <LogoMof
                style={{
                  width: 110,
                  height: 32,
                }}
                className="navbar-brand-image"
              />
            </a>
            <div className="navbar-nav flex-row order-md-last user">
              <div className="nav-item selectUsuario mr-3">
                {users?.length != 0 && (
                  <Form style={{ zIndex: 1000 }}>
                    <ReactSelect
                      className="text-left"
                      name="usuarios"
                      placeholder="Selecione um usuário..."
                      options={users}
                      onChange={(e): any => handleChangeLogin(e)}
                    ></ReactSelect>
                  </Form>
                )}
              </div>
              <div className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle variant="none" className="mr-3">
                    <i className="far fa-user icon"></i>
                    {user?.nome}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link className="dropdown-item" to="/minhaConta">
                      Minha Conta
                    </Link>
                    <Dropdown.Item
                      onClick={() => {
                        signOut();
                        window.location.href = `${configs.REACT_APP_SITE_ANTIGO_URL}sair`;
                      }}
                    >
                      Sair
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </header>
        <div className="navbar navbar-light pt-0 mb-2">
          <div className="container-xl">
            <Navbar expand="lg" className="pl-0 pt-0">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto navMenus">
                  {havePermission([
                    "CadastroVidros",
                    "CadastroPerfis",
                    "CadastroComponentes",
                    "CadastroSuperficies",
                    "ConsultaProduto",
                  ]) ? (
                    <div className="d-none"></div>
                  ) : (
                    <Link
                      className="nav-link"
                      to={`${configs.REACT_APP_SITE_ANTIGO_URL}dashboard`}
                    >
                      <i className="fas fa-home mr-1"></i> Home
                    </Link>
                  )}

                  {havePermission([
                    "DashboardSistemista",
                    "DashboardVidros",
                    "DashboardComponentesSuperficies",
                    "DashboardAcabamentos",
                  ]) && (
                    <Link className="nav-link" to="/dashboardMof">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Dashboard
                    </Link>
                  )}

                  {havePermission([
                    "CadastroVidros",
                    "CadastroPerfis",
                    "CadastroComponentes",
                    "CadastroSuperficies",
                    "ConsultaProduto",
                  ]) && (
                    <NavDropdown title={navProdutos} id="basic-nav-dropdown">
                      {havePermission(["CadastroPerfis"]) && (
                        <a
                          className="dropdown-link-color"
                          href={`${configs.REACT_APP_SITE_ANTIGO_URL}produtos?status=&tipoProduto=1`}
                          onClick={() => {
                            setReloadPage(true);
                          }}
                        >
                          <NavDropdown.Item as="span">Perfil</NavDropdown.Item>
                        </a>
                      )}

                      {havePermission(["CadastroComponentes"]) && (
                        <a
                          className="dropdown-link-color"
                          href={`${configs.REACT_APP_SITE_ANTIGO_URL}produtos?status=&tipoProduto=2`}
                          onClick={() => {
                            // handleFiltro(filtro, modoEdicao);
                            setReloadPage(true);
                          }}
                        >
                          <NavDropdown.Item as="span">
                            Componente
                          </NavDropdown.Item>
                        </a>
                      )}

                      {havePermission(["CadastroVidros"]) && (
                        <a
                          className="dropdown-link-color"
                          href={`${configs.REACT_APP_SITE_ANTIGO_URL}produtos?status=&tipoProduto=3`}
                          onClick={() => {
                            // handleFiltro(filtro, modoEdicao);
                            setReloadPage(true);
                          }}
                        >
                          <NavDropdown.Item as="span">Vidro</NavDropdown.Item>
                        </a>
                      )}

                      {havePermission(["CadastroSuperficies"]) && (
                        <a
                          className="dropdown-link-color"
                          href={`${configs.REACT_APP_SITE_ANTIGO_URL}produtos?status=&tipoProduto=4`}
                          onClick={() => {
                            // handleFiltro(filtro, modoEdicao);
                            setReloadPage(true);
                          }}
                        >
                          <NavDropdown.Item as="span">
                            Superficie
                          </NavDropdown.Item>
                        </a>
                      )}
                    </NavDropdown>
                  )}
                  {havePermission(["CadastroAcabamentos"]) && (
                    <Link
                      className="nav-link"
                      to={`${configs.REACT_APP_SITE_ANTIGO_URL}acabamento`}
                    >
                      <i className="fas fa-bezier-curve mr-1"></i> Acabamentos
                    </Link>
                  )}
                  {havePermission(["Exportacao", "Importacao"]) && (
                    <NavDropdown title={navAcoes} id="basic-nav-dropdown">
                      {havePermission(["Importacao"]) && (
                        <a
                          className="dropdown-link-color"
                          href={`${configs.REACT_APP_SITE_ANTIGO_URL}importar`}
                        >
                          <NavDropdown.Item as="span">
                            Importar
                          </NavDropdown.Item>
                        </a>
                      )}
                      {havePermission(["Exportacao"]) && (
                        <a
                          className="dropdown-link-color"
                          href={`${configs.REACT_APP_SITE_ANTIGO_URL}exportacao`}
                        >
                          <NavDropdown.Item as="span">
                            Exportar
                          </NavDropdown.Item>
                        </a>
                      )}
                    </NavDropdown>
                  )}
                  {havePermission(["Liberacao", "ConsultaLiberacao"]) && (
                    <a
                      className="nav-link"
                      href={`${configs.REACT_APP_SITE_ANTIGO_URL}liberacoes`}
                    >
                      <i className="fas fa-network-wired mr-1"></i> Liberações
                    </a>
                  )}
                  {havePermission(["Configuracao"]) && (
                    <NavDropdown title={"Gerência"} id="basic-nav-dropdown">
                      <Link className="dropdown-link-color" to="/empresasMof">
                        <NavDropdown.Item as="span">Empresas</NavDropdown.Item>
                      </Link>

                      <Link
                        className="dropdown-link-color"
                        to="/perfisDeEmpresa"
                      >
                        <NavDropdown.Item as="span">
                          Perfis de Empresa
                        </NavDropdown.Item>
                      </Link>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}classesId`}
                      >
                        <NavDropdown.Item as="span">
                          Classes / ID
                        </NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}cores`}
                      >
                        <NavDropdown.Item as="span">Cores</NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}grupos`}
                      >
                        <NavDropdown.Item as="span">Grupos</NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}tiposDeVidros`}
                      >
                        <NavDropdown.Item as="span">
                          Tipos de Vidro
                        </NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}unidades`}
                      >
                        <NavDropdown.Item as="span">Unidades</NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}referencias`}
                      >
                        <NavDropdown.Item as="span">
                          Referências
                        </NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}linhas`}
                      >
                        <NavDropdown.Item as="span">Linhas</NavDropdown.Item>
                      </a>

                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}prenativo`}
                      >
                        <NavDropdown.Item as="span">Prenativo</NavDropdown.Item>
                      </a>
                      <a
                        className="dropdown-link-color"
                        href={`${configs.REACT_APP_SITE_ANTIGO_URL}ligas`}
                      >
                        <NavDropdown.Item as="span">
                          Ligas / Temperas
                        </NavDropdown.Item>
                      </a>
                    </NavDropdown>
                  )}
                  {havePermission(["Desenhador"]) && (
                    <a
                      className="nav-link"
                      href={`${configs.REACT_APP_SITE_ANTIGO_URL}desenhador`}
                    >
                      Desenhador
                    </a>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>

        <Grid container display={"flex"} justifyContent={"center"}>
          {children}
        </Grid>

        <footer className="footer footer-transparent d-print-none">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-6 text-left">
                <ul className="list-inline list-inline-dots ml-0 mb-0">
                  <li className="list-inline-item">
                    <a href="#" className="link-secondary">
                      TERMOS DE USO
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6 text-right">
                <ul className="list-inline list-inline-dots mb-0">
                  <li className="list-inline-item">
                    <span className="copyright">
                      © {new Date().getFullYear()} - Desenvolvido por{" "}
                      <a href="http://www.alumisoft.com.br/" target="blank">
                        {" "}
                        Alumisoft Sistemas
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    </SidebarProvider>
  );
};
