import * as React from "react";
import styled from "@emotion/styled";

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Wrapper
			{...other}
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && children}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: ${({ hidden }) => (hidden ? "none" : "flex")};
	overflow: hidden;
	width: 100%;
	height: 100%;
`;
