import { Box } from "@mui/material";
import { ReactNode } from "react";

interface BarScrollProps {
  children?: ReactNode;
  maxHeight?: number | string;
}

export function BarScroll({ children, maxHeight }: BarScrollProps) {
  return (
    <>
      <Box
        sx={{
          maxHeight: maxHeight,
          overflowY: "auto",
          padding: 1,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#3C90AD",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#306d81",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
