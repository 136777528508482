import {
  Button,
  FormControlLabel,
  List,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import styled from "styled-components";

export const StackCustom = styled(Stack)`
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.04),
    0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(33, 33, 33, 0.08);
  border-radius: 4px;
  padding: 35px;
  background: #ffffff;
  max-width: {
    md: 90vw;
    sm: 100vw;
    xs: 100vw;
  }
`;

export const DashboardStackCustom = styled(Stack)`
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  max-width: 1320px;
`;

export const CustomPaper = styled(Paper)`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 0.04) !important;
`;

export const ButtonFilterCustom = styled(Button)`
  width: 345px;
  height: 115px;
  border-radius: 20px !important;
  display: flex !important;
  flex-direction: column;
  padding-left: 30px !important;
  padding-right: 30px !important;
  align-items: flex-start !important;
  &.Mui-disabled {
    cursor: not-allowed;
  }
`;

export const StackCustomCadastro = styled(Stack)`
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.04),
    0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(33, 33, 33, 0.08);
  max-width: {
    md: 90vw;
    sm: 100vw;
    xs: 100vw;
  }
  background: #e2e8f0;
`;

export const FormControlCustom = styled(FormControlLabel)`
  display: flex;
  flex-direction: row-reverse;
  color: rgba(53, 131, 177, 1);
  justify-content: space-between !important;
  width: 60%;
  margin-left: 0px !important;
`;

export const CustomFieldText = styled(TextField)`
  background-color: rgba(226, 232, 240, 1);
  border: 2px;
  gap: 10px;
  padding: 4px !important;
  border-bottom: none !important;
  width: 100% !important;
`;

export const ListBarCustom = styled(List)`
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 240px;
  padding-right: 10px !important;
  ul {
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;
