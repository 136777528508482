import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ModalComponent from "components/Modal";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import "dayjs/locale/pt-br";
import { fontsSizes } from "styles/theme";
import { StackScroll } from "components/ListWithScrollbar";

interface ModalDataProps {
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  onSubmit: (dataInicial: Dayjs, dataFinal: Dayjs) => void;
  inicialData: Dayjs;
  finalData: Dayjs;
  maxData?: Dayjs;
  minData?: Dayjs;
}

export function ModalFiltroMesAno({
  isOpen,
  onClose,
  loading,
  onSubmit,
  inicialData,
  finalData,
  maxData = dayjs().endOf("year"),
  minData = dayjs("2000-01-01"),
}: ModalDataProps) {
  const [dataInicial, setDataInicial] = useState<Dayjs>(
    dayjs().startOf("month")
  );
  const [dataFinal, setDataFinal] = useState<Dayjs>(dayjs().endOf("month"));

  useEffect(() => {
    setDataInicial(inicialData);
    setDataFinal(finalData);
  }, [inicialData, finalData]);

  return (
    <ModalComponent
      open={isOpen}
      handleClose={onClose}
      height={"auto"}
      width={{ md: 750, sm: "90vw", xs: "90vw" }}
      sx={{
        maxHeight: "90vh",
      }}
    >
      <Stack height="100%" p={{ md: 4, sm: 4, xs: 2 }}>
        <Typography align="left" justifyContent="center" fontWeight={"600"}>
          Selecione o intervalo que deseja filtrar
        </Typography>
        <StackScroll height={"60vh"}>
          <Grid container mt={3} alignItems={"center"}>
            <Grid item md={6} sm={6} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                  <DateCalendar
                    value={dataInicial}
                    minDate={minData}
                    maxDate={dataFinal}
                    onChange={(value) => {
                      setDataInicial(dayjs(value).startOf("month"));
                    }}
                    views={["year", "month"]}
                    sx={{
                      width: { md: "auto", sm: "100%", xs: "100%" },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DemoContainer
                  components={["DateCalendar", "DateCalendar"]}
                  sx={{ alignItems: "center" }}
                >
                  <DateCalendar
                    value={dataFinal}
                    onChange={(value) => {
                      setDataFinal(dayjs(value).endOf("month"));
                    }}
                    views={["year", "month"]}
                    minDate={dataInicial}
                    maxDate={maxData}
                    sx={{
                      width: { md: "auto", sm: "100%", xs: "100%" },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </StackScroll>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
          }}
        >
          <Typography fontSize={fontsSizes.medium} mt={{ md: 0, sm: 0, xs: 2 }}>
            {dataInicial.format("MM/YYYY")} - {dataFinal.format("MM/YYYY")} -{" "}
            <Typography component={"span"} fontWeight={700}>
              {dataFinal.diff(dataInicial, "day")}{" "}
              {dataFinal.diff(dataInicial, "day") > 1 ? "dias" : "dia"}
            </Typography>
          </Typography>
          <Box
            marginLeft={"auto"}
            mt={{
              md: 0,
              sm: 0,
              xs: 2,
            }}
          >
            <Button
              size="medium"
              variant="text"
              color="primary"
              onClick={() => {
                onClose();
              }}
              sx={{ mr: 2 }}
            >
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              endIcon={<Save />}
              onClick={() => {
                onSubmit(dataInicial, dataFinal);
              }}
              loading={loading}
            >
              Salvar
            </LoadingButton>
          </Box>
        </Stack>
      </Stack>
    </ModalComponent>
  );
}
