import { yupResolver } from "@hookform/resolvers/yup";
import { Check, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Switch, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { CustomButton } from "components/CustomButtom";
import ModalComponent from "components/Modal";
import { ModalUnsavedEdit } from "components/ModalUnsavedEdit";
import { FormControlCustom } from "components/StackCustom/styles";
import { ControlledTextField } from "components/TextField/ControlledTextField";
import { CadastroEmpresaContext } from "contexts/cadastroEmpresaContext";
import { useNavigation } from "contexts/notificationsContext";
import { TokenContext } from "contexts/tokenContext";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useDebugValue,
  useEffect,
  useState,
} from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getAbasPerfisDeEmpresa,
  getPerfisDeEmpresa,
  getPerfisDeEmpresaById,
  postDesativarAtivarPerfilEmpresa,
  postPerfisDeEmpresa,
} from "services/modules/perfisDeEmpresa";
import { DadosCadastraisForm } from "shared/interfaces/formEmpresa";
import {
  IGetPerfisDeEmpresa,
  IPerfisDeEmpresa,
  IPostPerfisEmpresa,
} from "shared/interfaces/perfisDeEmpresa";
import { FSelect } from "shared/interfaces/select";
import { perfisDeEmpresaSchema } from "shared/schemas/cadastroPerfisDeEmpresa";
import { fontsSizes } from "styles/theme";

export type TipoStatusPerfilUsuario = "ATIVAR" | "DESATIVAR";

interface ModalFornecedorProps {
  isOpen: boolean;
  onClose: () => void;
  refreshData?: () => void;
  dataPerfisDeEmpresa?: IGetPerfisDeEmpresa;
  setNewPerfil?: Dispatch<SetStateAction<FSelect[]>>;
  handleSelectPerfilEmpresa?: (id: number) => void;
}

export function ModalPerfisDeEmpresa({
  isOpen,
  onClose,
  dataPerfisDeEmpresa,
  setNewPerfil,
  refreshData,
  handleSelectPerfilEmpresa,
}: ModalFornecedorProps) {
  const dadosCadastraisForm = useFormContext<DadosCadastraisForm>();
  const params = useParams();
  const { openNotification } = useNavigation();
  const [isOpenModalUnsaved, setIsOpenModalUnsaved] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [items, setItems] = useState<FSelect[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [perfilAtivo, setPerfilAtivo] = useState(true);

  const { token } = useContext(TokenContext);

  const [modalDisabled, setModalDisabled] = useState<{
    isOpen: boolean;
    id?: number;
    tipoStatus?: TipoStatusPerfilUsuario;
  }>({
    isOpen: false,
  });

  const resetForm = () => {
    reset({
      idPerfil: 0,
      nomePerfil: "",
      perfilAbaIds: [],
      abaIds: [],
    });
    setSelectedItems([]);
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    formState,
    ...methods
  } = useForm<IPostPerfisEmpresa>({
    resolver: yupResolver(perfisDeEmpresaSchema),
    mode: "onBlur",
  });

  const { setDataUltimaAtualizacao } = useContext(CadastroEmpresaContext);

  const handleSwitchChange = (id: number) => {
    const updatedSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(updatedSelectedItems);

    setValue("perfilAbaIds", updatedSelectedItems, {
      shouldDirty: true,
    });
  };

  const handleSubmitPerfisDeEmpresa = async (data: IPostPerfisEmpresa) => {
    setIsLoadingSubmit(true);
    try {
      const postData: IPostPerfisEmpresa = {
        idPerfil: data.idPerfil ?? 0,
        nomePerfil: data.nomePerfil,
        perfilAbaIds: selectedItems,
        abaIds: selectedItems,
        ativo: data.ativo ?? true,
      };

      if (params.id) {
        await postPerfisDeEmpresa(postData);
        openNotification({
          isOpen: true,
          message: "Perfil de Empresa atualizado com sucesso!",
          type: "success",
        });
        await getPerfisDeEmpresaById(Number(params.id));

        try {
          const result = await getPerfisDeEmpresa();
          if (result && Array.isArray(result)) {
            const formattedPerfis = result.map((perfil) => ({
              value: perfil.idPerfil,
              label: perfil.nomePerfil,
            }));
            const id =
              formattedPerfis.find((perfil) => perfil.label === data.nomePerfil)
                ?.value ?? 0;
            setNewPerfil?.(formattedPerfis);
            dadosCadastraisForm.setValue("idPerfilEmpresa", id, {
              shouldDirty: true,
            });
            handleSelectPerfilEmpresa?.(id);
            setDataUltimaAtualizacao(new Date());
            resetForm();
            setSelectedItems([]);
            refreshData?.();
            onClose();
          } else {
            setNewPerfil?.([]);
          }
        } catch (error: any) {
          openNotification({
            isOpen: true,
            message: error.message,
            type: "error",
          });
        } finally {
          // setIsLoadingPerfis(false);
        }
        return;
      }

      const result = await postPerfisDeEmpresa(postData);

      if (result) {
        openNotification({
          isOpen: true,
          message: "Perfil de Empresa cadastrado com sucesso!",
          type: "success",
        });

        reset({
          idPerfil: 0,
          nomePerfil: "",
          perfilAbaIds: [],
          abaIds: [],
        });
        setSelectedItems([]);
      }
      refreshData?.();
      resetForm();
      onClose();
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const fillDataPerfilData = async (data: IGetPerfisDeEmpresa) => {
    const result = await getPerfisDeEmpresaById(data.idPerfil);
    if (result) {
      const perfilAbaIds = result?.data.abas.map((item) => item.id) || [];
      setPerfilAtivo(result.data.ativo);
      setSelectedItems(perfilAbaIds);
      reset({
        idPerfil: result?.data.idPerfil,
        nomePerfil: result?.data.nomePerfil,
        perfilAbaIds: perfilAbaIds,
        ativo: result?.data.ativo,
      });
    }
  };

  async function handleDisabledPerfilEmpresa(
    id?: number,
    status?: TipoStatusPerfilUsuario
  ) {
    if (!id) return;
    const isAtivo = status === "ATIVAR" ? true : false;

    try {
      const response = await postDesativarAtivarPerfilEmpresa(id, isAtivo);
      if (response) {
        setValue("ativo", status === "ATIVAR" ? true : false);
        openNotification({
          isOpen: true,
          message: `Perfil de empresa foi ${
            isAtivo ? "ativada" : "desativada"
          } com sucesso!`,
          type: "success",
        });
        refreshData?.();
        if (dataPerfisDeEmpresa) {
          await fillDataPerfilData(dataPerfisDeEmpresa);
        }
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  const handleCheckModal = () => {
    setIsOpenModalUnsaved(false);
    resetForm();
    onClose();
  };

  const handleCloseModal = () => {
    if (formState.isDirty) {
      setIsOpenModalUnsaved(true);
    } else {
      resetForm();
      onClose();
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getAbasPerfisDeEmpresa();
      if (data) {
        setItems(data);
      }
    };
    if (token) fetchItems();
  }, [token]);

  useEffect(() => {
    if (dataPerfisDeEmpresa) {
      fillDataPerfilData(dataPerfisDeEmpresa);
      setSelectedItems(dataPerfisDeEmpresa.perfilAbaIds || []);
    }
  }, [dataPerfisDeEmpresa]);

  return (
    <FormProvider
      {...{
        control,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        clearErrors,
        formState,
        ...methods,
      }}
    >
      <ModalComponent
        open={isOpen}
        handleClose={handleCloseModal}
        height={"auto"}
        width={{ xs: "644px" }}
        title="Cadastro de Perfis de Empresa"
      >
        <Grid container spacing={2} mb={2} py={2} px={3}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="nomePerfil"
              label="Nome do perfil"
              maxLength={50}
              disabled={!perfilAtivo}
            />
          </Grid>

          <Grid item ml={4}>
            <FormControlCustom
              label={"Ativo"}
              control={
                <Switch
                  onClick={(e) => {
                    e.preventDefault();
                    setModalDisabled({
                      isOpen: true,
                      id: dataPerfisDeEmpresa?.idPerfil,
                      tipoStatus: !watch().ativo ? "ATIVAR" : "DESATIVAR",
                    });
                  }}
                  checked={watch().ativo}
                />
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: fontsSizes.medium,
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Perfil da empresa
            </Typography>
            <Divider sx={{ width: "470px" }} />
          </Grid>
          <Grid container spacing={2} ml={1}>
            {items.map((item) => (
              <Grid item xs={12} sm={12} key={item.value}>
                <FormControlCustom
                  label={item.label}
                  control={
                    <Switch
                      checked={selectedItems.includes(item.value)}
                      onChange={() => handleSwitchChange(item.value)}
                      disabled={!perfilAtivo}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"flex-end"} mt={"auto"} p={2}>
          <CustomButton onClick={handleCloseModal}>Cancelar</CustomButton>
          <LoadingButton
            type="submit"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit(handleSubmitPerfisDeEmpresa)}
            disabled={!formState.isDirty}
            loading={isLoadingSubmit}
            loadingPosition="end"
          >
            Salvar
          </LoadingButton>
        </Box>
        <ConfirmDeleteModal
          title={
            modalDisabled.tipoStatus === "DESATIVAR"
              ? "Tem certeza que deseja desativar essa Empresa?"
              : "Tem certeza que deseja ativar essa Empresa?"
          }
          description=""
          open={modalDisabled.isOpen}
          onClose={() => setModalDisabled({ isOpen: false })}
          onConfirm={() => {
            handleDisabledPerfilEmpresa(
              modalDisabled.id,
              modalDisabled.tipoStatus
            )
              .then(() => {
                return getPerfisDeEmpresa();
              })
              .finally(() => {
                setModalDisabled({ isOpen: false });
              });
          }}
          buttonTextDelete={
            modalDisabled.tipoStatus === "DESATIVAR" ? "DESATIVAR" : "ATIVAR"
          }
          customButtonConfirm={
            <LoadingButton
              variant="contained"
              size="medium"
              color={
                modalDisabled.tipoStatus === "DESATIVAR" ? "error" : "success"
              }
              endIcon={
                modalDisabled.tipoStatus === "DESATIVAR" ? (
                  <ClearIcon />
                ) : (
                  <Check />
                )
              }
              sx={{ color: "white" }}
              onClick={() => {
                console.log("modalDisabled", modalDisabled);
                handleDisabledPerfilEmpresa(
                  modalDisabled.id,
                  modalDisabled.tipoStatus
                )
                  .then(() => {
                    return getPerfisDeEmpresa();
                  })
                  .finally(() => {
                    setModalDisabled({ isOpen: false });
                  });
              }}
              loading={isLoadingDelete}
              loadingPosition="end"
            >
              {modalDisabled.tipoStatus === "DESATIVAR"
                ? "DESATIVAR"
                : "ATIVAR"}
            </LoadingButton>
          }
        />
      </ModalComponent>
      <ModalUnsavedEdit
        description="Ao sair todas as informações preenchidas serão apagadas, deseja continuar?"
        isOpen={isOpenModalUnsaved}
        onClose={() => setIsOpenModalUnsaved(false)}
        onYes={handleCheckModal}
        onNot={() => setIsOpenModalUnsaved(false)}
        type
      />
    </FormProvider>
  );
}
