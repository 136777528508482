import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import DashboardAcabamentos from "./acabamentos";
import DashboardComponenteSuperficies from "./componentesSuperficies";
import DashboardSistemista from "./sistemista";
import DashboardVidros from "./vidros";
import { ReactNode, useContext, useEffect, useState } from "react";
import {
  getDadosExportacao,
  getTipoDashboardEmpresaLogada,
} from "services/modules/dashboard/geral";
import { fontsSizes } from "styles/theme";
import { Restore, FilterList } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { DashboardStackCustom } from "components/StackCustom/styles";
import { TokenContext } from "contexts/tokenContext";

export const mappedColorsHeatMapCards = [
  { r: 83, g: 131, b: 236 },
  { r: 156, g: 85, b: 236 },
  { r: 241, g: 145, b: 30 },
  { r: 17, g: 30, b: 95 },
  { r: 58, g: 139, b: 167 },
];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export type ModalDataFiltroType = {
  isFiltering: boolean;
  isOpen: boolean;
  onSubmit: (dataInicial: Dayjs, dataFinal: Dayjs) => void;
  inicialData: Dayjs;
  finalData: Dayjs;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="Dashboards"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function DashboardIndex() {
  const [value, setValue] = useState(0);
  const [tiposDashboard, setTiposDashboard] = useState<string[]>([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const { token } = useContext(TokenContext);

  const [modalDataFiltro, setModalDataFiltro] = useState<ModalDataFiltroType>({
    isFiltering: true,
    isOpen: false,
    onSubmit: (inicial, final) => {
      setModalDataFiltro({
        ...modalDataFiltro,
        isFiltering: true,
        isOpen: false,
        inicialData: inicial,
        finalData: final,
      });
      setTriggerUpdateDashboard((prev) => !prev);
      return;
    },
    inicialData: dayjs().subtract(1, "year"),
    finalData: dayjs().endOf("day"),
  });
  const [triggerUpdateDashboard, setTriggerUpdateDashboard] = useState(false);

  const mappedDashboards: Record<string, ReactNode> = {
    Sistemista: (
      <DashboardSistemista
        modalDataFiltro={modalDataFiltro}
        setModalDataFiltro={setModalDataFiltro}
        triggerUpdateDashboard={triggerUpdateDashboard}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
      />
    ),
    "Fabricante de Componentes e Superficies": (
      <DashboardComponenteSuperficies
        modalDataFiltro={modalDataFiltro}
        setModalDataFiltro={setModalDataFiltro}
        triggerUpdateDashboard={triggerUpdateDashboard}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
      />
    ),
    "Fabricante de Vidros": (
      <DashboardVidros
        modalDataFiltro={modalDataFiltro}
        setModalDataFiltro={setModalDataFiltro}
        triggerUpdateDashboard={triggerUpdateDashboard}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
      />
    ),
    Acabamentos: (
      <DashboardAcabamentos
        modalDataFiltro={modalDataFiltro}
        setModalDataFiltro={setModalDataFiltro}
        triggerUpdateDashboard={triggerUpdateDashboard}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
      />
    ),
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getTiposDashboard = async () => {
    const dataTipos = await getTipoDashboardEmpresaLogada();
    setTiposDashboard(dataTipos?.map((tipo) => tipo.descricao) ?? []);
  };

  const exportarDados = async () => {
    const response = await getDadosExportacao(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData
    );

    if (!response || !response.data) return;

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;

    const fileName =
      response.headers["content-disposition"]?.split("filename=")[1] ||
      "MOF_DASHBOARD.xlsx";
    link.setAttribute("download", fileName.replace(/['"]/g, ""));

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (token) getTiposDashboard();
  }, [token]);

  return (
    <DashboardStackCustom sx={{ width: "100%" }} pt={2}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {tiposDashboard.map((tipo) => (
            <Tab label={tipo} key={tipo} />
          ))}
        </Tabs>
      </Box>
      <Grid container mt={2} display={"flex"} alignItems={"center"}>
        <Grid item md={6}>
          <Typography
            sx={{
              color: "rgba(97, 165, 188, 1)",
              fontSize: fontsSizes.large,
              fontWeight: 400,
            }}
          >
            DASHBOARD {tiposDashboard[value]?.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item md={6} display={"flex"} justifyContent={"end"}>
          <Typography
            sx={{
              fontSize: {
                xs: fontsSizes.small,
                md: fontsSizes.medium,
                color: "rgba(0, 0, 0, 0.6)",
                fontWeight: 400,
              },
            }}
          >
            {`De ${modalDataFiltro.inicialData.format(
              "MM/YYYY"
            )} a ${modalDataFiltro.finalData.format("MM/YYYY")}`}
            <Tooltip title="Aplicar filtro por data">
              <IconButton
                sx={{ color: "primary.main" }}
                onClick={() => {
                  setModalDataFiltro({
                    ...modalDataFiltro,
                    isOpen: true,
                  });
                }}
              >
                <FilterList />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reverter para o filtro padrão">
              <IconButton
                sx={{ color: "primary.main" }}
                onClick={() => {
                  setModalDataFiltro({
                    ...modalDataFiltro,
                    inicialData: dayjs().startOf("year"),
                    finalData: dayjs().endOf("year"),
                  });
                  setTriggerUpdateDashboard((prev) => !prev);
                }}
              >
                <Restore />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item md={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button
              sx={{ height: "40px" }}
              variant="outlined"
              onClick={exportarDados}
            >
              EXPORTAR DADOS
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {tiposDashboard.map((tipo, index) => (
        <CustomTabPanel value={value} index={index} key={tipo}>
          {mappedDashboards[tipo]}
        </CustomTabPanel>
      ))}
    </DashboardStackCustom>
  );
}
