import { api } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { IGetCidade, IGetEstado } from "shared/interfaces/localizacao";
import { FSelect } from "shared/interfaces/select";

export const getListarEstados = async (): Promise<FSelect[]> => {
  try {
    const response = await api.get<IGetEstado[]>("/Localidade/ListarEstados");

    const data: FSelect[] = response.data.map((item) => ({
      value: item.idEstado,
      label: item.siglaEstado,
    }));
    data.sort((a, b) => a.label.localeCompare(b.label));
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar o Estado";

    getErrorsByApi(error, errorMessage);
  }
  return [];
};

export const getListarCidadesPorIdEstado = async (
  id: number
): Promise<FSelect[]> => {
  try {
    const response = await api.get<IGetCidade[]>(
      `/Localidade/ListarCidadesPorIdEstado?idEstado=${id}`
    );

    const data: FSelect[] = response.data.map((item) => ({
      value: item.idCidade,
      label: item.nomeCidade,
    }));
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar a Cidade";

    getErrorsByApi(error, errorMessage);
  }
  return [];
};
