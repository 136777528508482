import { Tab, Tabs, useTheme } from "@mui/material";
import ErrorIcon from "assets/icons/error_icon";
import { a11yProps } from "shared/helpers/a11yProps";

export type TabEmpresasType = {
  label: string;
  element: JSX.Element;
  field: string;
};

interface TabsEmpresasProps {
  tabValue: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  hasErrorInTab: boolean;
  tabs: TabEmpresasType[];
  hasErrors: (field: string) => boolean;
}

export function TabsEmpresa({
  tabValue,
  handleChange,
  hasErrorInTab,
  tabs,
  hasErrors,
}: TabsEmpresasProps) {
  const theme = useTheme();

  const getBackgroundColor = (field: string, index: number) => {
    if (hasErrors(field)) {
      return "#D6365722";
    }
    if (tabValue === index) {
      return theme.palette.primary["100"];
    }

    return "rgba(235, 244, 249, 1)";
  };

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      variant="scrollable"
      TabIndicatorProps={{
        sx: {
          backgroundColor: hasErrorInTab ? theme.palette.error.main : "#3C90AD",
        },
      }}
    >
      {tabs.map(({ label, field }, index) => (
        <Tab
          key={index}
          label={label}
          icon={hasErrors(field) ? <ErrorIcon /> : undefined}
          iconPosition="end"
          {...a11yProps(0)}
          sx={{
            backgroundColor: getBackgroundColor(field, index),
            "& ,&.Mui-selected": {
              color: hasErrors(field) ? "#611424" : "rgba(0, 59, 92, 1)",
            },
            maxHeight: "50px",
            minHeight: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ))}
    </Tabs>
  );
}
