import { Controller } from "react-hook-form";
import TextField, { TextFieldProps } from ".";

export type IconData = {
	name: string;
	toolTip: string;
	onClick: () => void;
};

type ControlledTextFieldProps = {
	name: string;
	control: any;
} & TextFieldProps;

export const ControlledTextField = ({
	name,
	control,
	customIcons,
	...props
}: ControlledTextFieldProps) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					customIcons={customIcons}
					{...props}
					onBlur={(event) => {
						field.onBlur();
						props.onBlur?.(event);
					}}
					helperText={error?.message || props.helperText}
					error={props.error ?? !!error?.message}
					field={field}
				/>
			)}
		/>
	);
};
