import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { PreventExit } from "components/PreventExit";
import { useNavigation } from "contexts/notificationsContext";
import { usePreventExit } from "hooks/usePreventExit";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getEmpresaById,
  getTipoDashboards,
  postEmpresasDadosCadastrais,
} from "services/modules/empresas";
import {
  IPostEmpresasDadosCadastrais,
  ITipoDashboard,
} from "shared/interfaces/empresa";
import { DadosCadastraisForm } from "shared/interfaces/formEmpresa";

import { Save } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AccordionsSwitch from "components/AccordionsSwitch";
import { BarScroll } from "components/BarScroll";
import { DataNotFound } from "components/DataNotFound";
import { CadastroEmpresaContext } from "contexts/cadastroEmpresaContext";
import { useSearchZipCode } from "hooks/useSearchZipCode";
import Select from "react-select";
import { getPerfisDeEmpresaById } from "services/modules/perfisDeEmpresa";
import { getBrasiliaTime } from "shared/helpers/cadastroEmpresas";
import { fontsSizes } from "styles/theme";
import { FSelect } from "shared/interfaces/select";

export const SEARCH_BAR_EMPRESA_ID = "search-bar-componente-anchor";

export type DashboardFormType = {
  accordionsData: Record<
    number,
    { idReferencias: number[]; idProjetistas: number[] }
  >;
};

type FormTabelaPreco = {
  tipoSelecionados: Record<number, FSelect[]>;
};

export const DashboardForm = () => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    getValues,
    clearErrors,
    formState,
    ...methods
  } = useForm<DadosCadastraisForm>({
    mode: "onBlur",
  });

  const formDashboard = useForm<DashboardFormType>({
    defaultValues: {
      accordionsData: {},
    },
  });

  const formTabPreco = useForm<FormTabelaPreco>();

  const formStateDashboard = useFormState({ control: formDashboard.control });

  const { errors, isDirty, dirtyFields } = useFormState({ control });

  const {
    isOpenModalUnsaved,
    handleIsOpenModalUnsaved,
    handleLinkNavigateBlocked,
    handleOnCloseModalUnsavedEdit,
    linkNavigateBlocked,
    isGoBack,
  } = usePreventExit(isDirty && Object.values(dirtyFields).length > 0);

  const { fillCidadesByEstadoId } = useSearchZipCode();

  const params = useParams();
  const { openNotification } = useNavigation();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [tiposDashboards, setTiposDashboards] = useState<ITipoDashboard[]>([]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const { setDataUltimaAtualizacao } = useContext(CadastroEmpresaContext);

  const [selectedDashboards, setSelectedDashboards] = useState<Set<number>>(
    new Set()
  );
  const [selectedTabela, setSelectedTabela] = useState<Set<number>>(new Set());

  const [referenciasSelect, setReferenciasSelect] = useState<any[][]>(
    Array.from(
      {
        length: 5,
      },
      () => []
    )
  );
  const [projetistaSelect, setProjetistaSelect] = useState<any[][]>(
    Array.from(
      {
        length: 5,
      },
      () => []
    )
  );
  const [referenciasSelecionadas, setReferenciasSelecionadas] = useState<
    Record<number, { value: number; label: string }[]>
  >({});

  const [mappedIdReferencias, setMappedIdReferencias] =
    useState<Record<number, string>>();

  const [projetistasSelecionados, setProjetistasSelecionados] = useState<
    Record<number, { value: number; label: string }[]>
  >({});

  const [accordionSelected, setAccordionSelected] = useState<number[]>([]);

  const handleSwitchChange = (id: number, checked: boolean) => {
    const newSelectedDashboards = new Set(selectedDashboards);

    if (checked) {
      newSelectedDashboards.add(id);
      setAccordionSelected((prevState) => [...prevState, id]);
    } else {
      const filteredSelecteds = accordionSelected.filter((item) => {
        return item !== id;
      });

      const newReferenciasSelecionadas = { ...referenciasSelecionadas };
      const newProjetistasSelecionados = { ...projetistasSelecionados };

      delete newReferenciasSelecionadas[id];
      delete newProjetistasSelecionados[id];

      setReferenciasSelecionadas(newReferenciasSelecionadas);
      setProjetistasSelecionados(newProjetistasSelecionados);

      setAccordionSelected(filteredSelecteds);
      newSelectedDashboards.delete(id);
      const closeAccordion = {
        ...formDashboard.getValues("accordionsData"),
      };
      closeAccordion[id ?? 0] = {
        idReferencias: [],
        idProjetistas: [],
      };
      formDashboard.setValue("accordionsData", closeAccordion, {
        shouldDirty: true,
      });
    }
    setSelectedDashboards(newSelectedDashboards);
  };

  async function getDadosEmpresas(idEmpresa: number) {
    try {
      const dadosMapeados = await fetchTiposDashboards();
      const result = await getEmpresaById(idEmpresa);
      if (result) {
        if (result.data.idEstado) {
          await fillCidadesByEstadoId(result.data.idEstado);
        }

        if (result.data.idPerfil) {
          const perfilResult = await getPerfisDeEmpresaById(
            result.data.idPerfil
          );
          if (perfilResult?.data) {
            setValue("nomePerfilEmpresa", perfilResult.data.nomePerfil);
          }
        }

        const tabelaGetVMs = result.data.tabelaPrecoVMs;
        const newSelectedtabela = new Set(selectedTabela);

        tabelaGetVMs?.forEach((p) => {
          newSelectedtabela.add(p.idTipoMaterial ?? 0);
        });
        setSelectedTabela(newSelectedtabela);

        reset({
          cepEmpresa: result.data.cep,
          complementoEmpresa: result.data.complemento,
          idCidadeEmpresa: result.data.idCidade ?? null,
          idEstadoEmpresa: result.data.idEstado ?? null,
          documentoEmpresa: result.data.cpfCnpj,
          enderecoEmpresa: result.data.endereco,
          nomeFantasiaEmpresa: result.data.nomeFantasia,
          numeroEmpresa: result.data.numero ?? null,
          idPerfilEmpresa: result.data.idPerfil,
          razaoSocialEmpresa: result.data.razaoSocial,
          ativoEmpresa: result.data.ativo,
          perfilEmpresa: result.data.idPerfil.toString(),
          referenciasAplicabilidadeVM: result.data.referenciasAplicabilidadeVM,
          produtoNivelUmVM: result.data.produtoNivelUmVM,
          tipoDashboardVMs: result.data.tipoDashboardVMs,
          abasDoPerfilSelecionado:
            result?.data.abasUtilizadasPerfil.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
          abasParaAdicionar:
            result?.data.abasDisponiveisRecursosAdicionais.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
        });
        setSelectedItems(
          result.data.abasUtilizadasRecursosAdicionais.map((item) => item.id)
        );

        const newAccordionSelected: number[] = [];
        result.data?.tipoDashboardVMs?.forEach((item) => {
          if (item.idTipoDashboard) {
            newAccordionSelected.push(item.idTipoDashboard);
          }
        });
        setAccordionSelected(newAccordionSelected);

        const newReferenciasSelecionadas: Record<
          number,
          { value: number; label: string }[]
        > = {};
        const newProjetistasSelecionados: Record<
          number,
          { value: number; label: string }[]
        > = {};
        const newAccSelectedOptions: Record<
          number,
          {
            idReferencias: number[];
            idProjetistas: number[];
          }
        > = {};

        result.data.tipoDashboardVMs?.forEach((item) => {
          newReferenciasSelecionadas[item.idTipoDashboard ?? 0] =
            item.referencias?.map((ref) => {
              return { value: ref, label: dadosMapeados?.referencias[ref]! };
            })!;
          newProjetistasSelecionados[item.idTipoDashboard ?? 0] =
            item.projetistas?.map((proj) => {
              return { value: proj, label: dadosMapeados?.projetistas[proj]! };
            })!;
          newAccSelectedOptions[item.idTipoDashboard ?? 0] = {
            idReferencias: item.referencias!,
            idProjetistas: item.projetistas!,
          };
        });

        // TABELA
        const newTiposSelecionadosTabela = {
          ...formTabPreco.getValues("tipoSelecionados"),
        };
        tabelaGetVMs?.forEach((p) => {
          newTiposSelecionadosTabela[p.idTipoMaterial ?? 0] =
            p.idsReferencia?.map((ref) => {
              return {
                value: ref,
                label: mappedIdReferencias?.[ref] ?? "",
              };
            }) ?? [];
        });
        formTabPreco.setValue("tipoSelecionados", newTiposSelecionadosTabela);

        const newAccordionSelectedTabela: number[] = [];
        result.data?.tabelaPrecoVMs?.forEach((item) => {
          if (item) {
            newAccordionSelectedTabela.push(item?.idTipoMaterial);
          }
        });
        setAccordionSelected(newAccordionSelectedTabela);
        // TABELA

        setReferenciasSelecionadas(newReferenciasSelecionadas);
        setProjetistasSelecionados(newProjetistasSelecionados);
        formDashboard.setValue("accordionsData", newAccSelectedOptions);
      }
      if (result && result.data) {
        const tipoDashboardVMs = result.data.tipoDashboardVMs || [];

        const selectedIds = new Set<number>(
          tipoDashboardVMs
            .map((item) => item.idTipoDashboard)
            .filter((id): id is number => id !== undefined)
        );
        setSelectedDashboards(selectedIds);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  const tabelaVMs = Array.from(selectedTabela).map((idTipoMaterial) => {
    const nomesReferencia =
      formTabPreco.getValues("tipoSelecionados")[idTipoMaterial];
    const idsReferencia = nomesReferencia.map((item) => {
      return item.value;
    });

    return {
      idTipoMaterial,
      idsReferencia,
    };
  });

  const transformToEmpresa = (
    data: DadosCadastraisForm
  ): IPostEmpresasDadosCadastrais => {
    return {
      idEmpresa: params.id ? Number(params.id) : 0,
      nomeFantasia: data.nomeFantasiaEmpresa,
      cpfCnpj: data.documentoEmpresa,
      cep: data.cepEmpresa,
      ativo: data.ativoEmpresa ?? false,
      complemento: data?.complementoEmpresa ?? "",
      endereco: data.enderecoEmpresa,
      idCidade: data.idCidadeEmpresa ?? null,
      idEstado: data.idEstadoEmpresa ?? null,
      idPerfil: data.idPerfilEmpresa,
      numero: data.numeroEmpresa ?? null,
      razaoSocial: data.razaoSocialEmpresa,
      abas: selectedItems,
      tipoDashboardVMs: Array.from(selectedDashboards).map(
        (idTipoDashboard) => {
          const accOptions = formDashboard.getValues("accordionsData")[
            idTipoDashboard
          ] || {
            idProjetistas: [],
            idReferencias: [],
          };
          return {
            idTipoDashboard: idTipoDashboard,
            idEmpresa: Number(params.id) ?? 0,
            projetistas: accOptions.idProjetistas,
            referencias: accOptions.idReferencias,
          };
        }
      ),
      produtoNivelUmVM: data.produtoNivelUmVM,
      tabelaPrecoVMs: tabelaVMs,
      dataUltimaAtualizacao: getBrasiliaTime(),
      referenciasAplicabilidadeVM: data.referenciasAplicabilidadeVM,
    };
  };

  const handleSubmitDadosCadastrais = async (data: DadosCadastraisForm) => {
    if (selectedDashboards.size > 0) {
      const hasMissingReference = Array.from(selectedDashboards).some(
        (idTipoMaterial) => {
          const accordionData =
            formDashboard.getValues("accordionsData")[idTipoMaterial];

          if (!accordionData) return true;

          const hasReference = accordionData.idReferencias?.length > 0;
          const hasProjetistas = accordionData.idProjetistas?.length > 0;

          const findDashboard = tiposDashboards.find(
            (item) => item.idTipoDashboard === idTipoMaterial
          );

          if (findDashboard?.projetistas !== null) {
            if (hasReference && hasProjetistas) {
              return false;
            }
          } else {
            if (hasReference) {
              return false;
            }
          }

          return true;
        }
      );

      if (hasMissingReference) {
        openNotification({
          isOpen: true,
          message:
            "Por favor, adicione pelo menos um item para o campo de referência e projetista.",
          type: "error",
        });
        return;
      }
    }
    setIsLoadingSubmit(true);
    try {
      const postData: IPostEmpresasDadosCadastrais = transformToEmpresa(data);

      if (params.id) {
        await postEmpresasDadosCadastrais(postData);
        openNotification({
          isOpen: true,
          message: "Empresa atualizada com sucesso!",
          type: "success",
        });
        formDashboard.reset({
          accordionsData: formDashboard.getValues("accordionsData"),
        });
        return;
      }

      const result = await postEmpresasDadosCadastrais(postData);

      if (result) {
        openNotification({
          isOpen: true,
          message: "Empresa cadastrada com sucesso!",
          type: "success",
        });
        formDashboard.reset({
          accordionsData: formDashboard.getValues("accordionsData"),
        });
      }
      setDataUltimaAtualizacao(new Date());
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const fetchTiposDashboards = async () => {
    try {
      const result = await getTipoDashboards();
      if (result) {
        const newMappedAccSelectedOptions: Record<
          number,
          { idReferencias: number[]; idProjetistas: number[] }
        > = {};
        const newReferenciasSelecionadas: Record<
          number,
          { value: number; label: string }[]
        > = {};
        result.forEach((tipo) => {
          newMappedAccSelectedOptions[tipo.idTipoDashboard] = {
            idProjetistas: [],
            idReferencias: [],
          };
          newReferenciasSelecionadas[tipo.idTipoDashboard] = [];
        });
        formDashboard.setValue("accordionsData", newMappedAccSelectedOptions);
        setReferenciasSelecionadas(newReferenciasSelecionadas);
        setProjetistasSelecionados(newReferenciasSelecionadas);
        setReferenciasSelect(
          result.map((item) =>
            item.referencias.map((item) => ({
              value: item.id,
              label: item.descricao,
            }))
          )
        );
        setProjetistaSelect(
          result.map((item) =>
            item.projetistas
              ? item.projetistas.map((proj) => ({
                  value: proj.id,
                  label: proj.descricao,
                }))
              : []
          )
        );
        setTiposDashboards(result);
      }
      const referenciasMapeadas: Record<number, string> = {};
      const projetistasMapeadas: Record<number, string> = {};
      const referencias = result?.map((item) => item.referencias).flat();
      const projetistas = result?.map((item) => item.projetistas).flat();
      referencias?.forEach(
        (ref) => (referenciasMapeadas[ref.id] = ref.descricao)
      );
      projetistas?.forEach((proj) => {
        if (proj !== null) {
          projetistasMapeadas[proj.id] = proj.descricao;
        }
      });
      const dadosMapeados = {
        referencias: referenciasMapeadas,
        projetistas: projetistasMapeadas,
      };
      return dadosMapeados;
    } catch (error) {
      openNotification({
        isOpen: true,
        message: "Erro ao buscar tipos de dashboard.",
        type: "error",
      });
    }
  };
  useEffect(() => {
    params.id && getDadosEmpresas(Number(params.id));
  }, [params.id]);

  return (
    <PreventExit
      handleIsOpenModalUnsaved={handleIsOpenModalUnsaved}
      handleLinkNavigateBlocked={handleLinkNavigateBlocked}
      isOpenModalUnsaved={isOpenModalUnsaved}
      handleOnCloseModalUnsavedEdit={handleOnCloseModalUnsavedEdit}
      linkNavigateBlocked={linkNavigateBlocked}
      isGoBack={isGoBack}
    >
      <FormProvider
        {...{
          control,
          handleSubmit,
          reset,
          setValue,
          watch,
          setError,
          getValues,
          clearErrors,
          formState,
          ...methods,
        }}
      >
        <Grid container p={1.5}>
          <Grid md={12}>
            <BarScroll maxHeight={"50vh"}>
              {tiposDashboards.length === 0 ? (
                <Grid
                  sx={{
                    minHeight: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DataNotFound visible />
                </Grid>
              ) : (
                tiposDashboards.map((tipo, index) => (
                  <AccordionsSwitch
                    key={tipo.idTipoDashboard}
                    checked={selectedDashboards.has(tipo.idTipoDashboard)}
                    onChangeSwtich={(e, checked) => {
                      handleSwitchChange(tipo.idTipoDashboard, checked);
                    }}
                    descricao={tipo.descricao}
                    id={tipo.idTipoDashboard}
                  >
                    <Grid
                      container
                      spacing={3}
                      mb={3}
                      display={"flex"}
                      justifyContent={"space-around"}
                    >
                      <Grid
                        item
                        xs={projetistaSelect[index].length === 0 ? 12 : 6}
                      >
                        <Typography
                          sx={{
                            fontSize: fontsSizes.medium,
                            color: "rgba(0, 0, 0, 0.87)",
                          }}
                          display={"flex"}
                        >
                          Referências para consulta no dashboard (nível 1)
                        </Typography>

                        <Select
                          isClearable={false}
                          maxMenuHeight={290}
                          menuPosition="fixed"
                          isMulti
                          key={index}
                          options={referenciasSelect[index]}
                          value={referenciasSelecionadas[tipo.idTipoDashboard]}
                          required
                          isDisabled={
                            !selectedDashboards.has(tipo.idTipoDashboard)
                          }
                          onChange={(selectedOptions) => {
                            const aux = {
                              ...formDashboard.getValues("accordionsData"),
                            };
                            if (!aux[tipo.idTipoDashboard]) {
                              aux[tipo.idTipoDashboard] = {
                                idReferencias: [],
                                idProjetistas: [],
                              };
                            }
                            if (selectedOptions && selectedOptions.length > 0) {
                              aux[tipo.idTipoDashboard].idReferencias =
                                selectedOptions.map((opt) => opt.value);
                            } else {
                              aux[tipo.idTipoDashboard].idReferencias = [];
                            }
                            formDashboard.setValue("accordionsData", aux, {
                              shouldDirty: true,
                            });

                            setReferenciasSelecionadas((prev) => {
                              const auxReferenciasSelecionadas = { ...prev };
                              auxReferenciasSelecionadas[tipo.idTipoDashboard] =
                                [...selectedOptions];

                              return auxReferenciasSelecionadas;
                            });
                          }}
                          placeholder="Selecione o tipo de material"
                        />
                      </Grid>
                      {projetistaSelect[index].length === 0 ? (
                        <Grid></Grid>
                      ) : (
                        <Grid item md={6}>
                          <Typography
                            sx={{
                              fontSize: fontsSizes.medium,
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                            display={"flex"}
                          >
                            Projetista para consultas no dashboard (nível 2)
                          </Typography>

                          <Select
                            isClearable={false}
                            maxMenuHeight={290}
                            menuPosition="fixed"
                            isMulti
                            key={index}
                            options={projetistaSelect[index]}
                            value={
                              projetistasSelecionados[tipo.idTipoDashboard]
                            }
                            isDisabled={
                              !selectedDashboards.has(tipo.idTipoDashboard)
                            }
                            onChange={(selectedOptions) => {
                              const aux = {
                                ...formDashboard.getValues("accordionsData"),
                              };
                              if (!aux[tipo.idTipoDashboard]) {
                                aux[tipo.idTipoDashboard] = {
                                  idReferencias: [],
                                  idProjetistas: [],
                                };
                              }
                              if (
                                selectedOptions &&
                                selectedOptions.length > 0
                              ) {
                                aux[tipo.idTipoDashboard].idProjetistas =
                                  selectedOptions.map((opt) => opt.value);
                              } else {
                                aux[tipo.idTipoDashboard].idProjetistas = [];
                              }

                              formDashboard.setValue("accordionsData", aux, {
                                shouldDirty: true,
                              });

                              setProjetistasSelecionados((prev) => {
                                const auxReferenciasSelecionadas = {
                                  ...prev,
                                };
                                auxReferenciasSelecionadas[
                                  tipo.idTipoDashboard
                                ] = [...selectedOptions];

                                return auxReferenciasSelecionadas;
                              });
                            }}
                            placeholder="Selecione o tipo de material"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionsSwitch>
                ))
              )}
            </BarScroll>
          </Grid>
          {tiposDashboards.length === 0 ? (
            <Grid></Grid>
          ) : (
            <Grid item md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<Save />}
                  onClick={handleSubmit(handleSubmitDadosCadastrais)}
                  loading={isLoadingSubmit}
                  disabled={!formStateDashboard.isDirty}
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  Salvar
                </LoadingButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </PreventExit>
  );
};
