import { Button, Paper, Stack, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { BarScroll } from "components/BarScroll";
import { StripedDataGrid } from "components/StripedDataGrid";

interface ListaDashBoardProps<T> {
  dataRows: T[];
  columns: GridColumns<any>;
  title?: string;
  maxHeight?: number | string;
  onClick?: () => void;
}

export function ListaDashBoard<T>({
  dataRows,
  columns,
  title,
  maxHeight = 405,
  onClick,
}: ListaDashBoardProps<T>) {
  return (
    <>
      <Stack
        alignItems={"center"}
        direction={"row"}
        justifyContent={"center"}
        mb={1}
        spacing={1}
      >
        {title && (
          <Typography variant={"body1"} color={"primary.main"}>
            {title}
          </Typography>
        )}
        {onClick && (
          <Button variant="outlined" onClick={onClick}>
            VER MAIS
          </Button>
        )}
      </Stack>
      <BarScroll maxHeight={"60vh"}>
        <Paper>
          <StripedDataGrid
            rows={dataRows}
            columns={columns}
            loading={false}
            hideDeleteButton
            checkboxSelection={false}
            rowHeight={70}
            hideFooter
            sx={{ maxHeight }}
          />
        </Paper>
      </BarScroll>
    </>
  );
}
