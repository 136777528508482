import { Grid, Paper, Stack, Typography } from "@mui/material";
import { ButtonFilterCustom } from "components/StackCustom/styles";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  AddLocationOutlined,
} from "@mui/icons-material";
import { mappedColorsHeatMapCards } from "pages/Dashboard";
import { applyFactorRGB } from "shared/helpers/dashboard";
import { ListaOrcamentoPorRegiao } from "shared/interfaces/dashboardSistemista";
import { Dispatch, SetStateAction } from "react";
import NewGrafico from "../NewGrafico";
import { DataNotFoundFilter } from "components/DataNotFound";

export interface GraficoHeatMapProps {
  porcentagemOrcamentosPorRegiao?: ListaOrcamentoPorRegiao | null;
  selectedRegion: string;
  setSelectedRegion: Dispatch<SetStateAction<string>>;
}

export function GraficoHeatMap({
  porcentagemOrcamentosPorRegiao,
  selectedRegion,
  setSelectedRegion,
}: GraficoHeatMapProps) {
  const todasAsRegioes = [
    "Norte",
    "Nordeste",
    "Sudeste",
    "Sul",
    "Centro-Oeste",
  ];

  const porcentagemOrcamentos = todasAsRegioes.map((nomeRegiao, index) => {
    const regiaoEncontrada = porcentagemOrcamentosPorRegiao?.find(
      (item) => item.nomeRegiao === nomeRegiao
    );

    return {
      nomeRegiao,
      porcentagemAno: regiaoEncontrada?.porcentagemAno ?? 0,
      porcentagemOrcamentos: regiaoEncontrada?.porcentagemOrcamentos ?? 0,
    };
  });

  return (
    <Grid container>
      <Grid item md={4} mt={3}>
        {porcentagemOrcamentos?.map((regiao, index) => (
          <ButtonFilterCustom
            key={regiao.nomeRegiao}
            sx={{
              backgroundColor:
                selectedRegion === regiao.nomeRegiao || !selectedRegion
                  ? applyFactorRGB(mappedColorsHeatMapCards[index], 0)
                  : "rgba(211, 211, 211, 0.7)",
              color: "rgba(255, 255, 255, 1)",
              "&:hover": {
                backgroundColor: applyFactorRGB(
                  mappedColorsHeatMapCards[index],
                  -30
                ),
              },
              my: 1,
            }}
            onClick={() =>
              setSelectedRegion((prev) =>
                regiao.nomeRegiao === prev ? "" : regiao.nomeRegiao
              )
            }
          >
            <Typography sx={{ fontWeight: 400 }}>
              {regiao.nomeRegiao}
            </Typography>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography mt={1} mb={1} sx={{ fontWeight: 700 }}>
                {regiao.porcentagemOrcamentos}% orçamentos
              </Typography>
              <AddLocationOutlined />
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                color:
                  regiao.porcentagemAno < 0
                    ? "rgb(180, 0, 0)"
                    : "rgb(130, 255, 183)",
              }}
            >
              {regiao.porcentagemAno < 0 ? (
                <ArrowDownwardOutlined />
              ) : (
                <ArrowUpwardOutlined />
              )}
              <Typography sx={{ fontWeight: 400 }}>
                {regiao.porcentagemAno}% ao ano
              </Typography>
            </Grid>
          </ButtonFilterCustom>
        ))}
      </Grid>
      <Grid item md={8} mt={1}>
        <Stack alignItems={"center"}>
          <Typography variant="h6" color={"primary.main"}>
            % de orçamentos/obras calculados com meus projetos por região
          </Typography>
          <Paper sx={{ padding: "10px" }}>
            <NewGrafico selectedRegion={selectedRegion} />
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
}
