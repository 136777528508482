import {
  Check,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Grid, Paper } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import BackdropLoading from "components/BackdropLoading";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { FloatMenuCrud } from "components/FloatMenuCrud";
import NavigationHeader from "components/NavigationHeader";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import { StripedDataGrid } from "components/StripedDataGrid";
import { FilterColumn } from "components/StripedDataGrid/FilterColumn";
import { TitleTable } from "components/TitleTable";
import { useNavigation } from "contexts/notificationsContext";
import { TIME_DEBOUNCE, usePagination } from "hooks/usePagination";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  deleteEmpresa,
  getDadosPaginacao,
  getEmpresas,
  postDesativarAtivarEmpresa,
} from "services/modules/empresas";
import { useResponsive } from "shared/hooks/useResponsive";
import { IEmpresa } from "shared/interfaces/empresa";

export const SEARCH_BAR_EMPRESA_ID = "search-bar-componente-anchor";

export type TipoStatusEmpresa = "ATIVAR" | "DESATIVAR";

type HookParams = {
  onEdit(data: IEmpresa): void;
  showDetails(nomeEmpresa: string, nomeFantasia: string, cpf: number): void;
  onRemove(id: number, tipoStatus: TipoStatusEmpresa): void;
};

export const Empresas = () => {
  const refNomeEmpresa = useRef<HTMLInputElement>();
  const refNomeFantasia = useRef<HTMLInputElement>();
  const refCpf = useRef<HTMLInputElement>();
  const [modalDelete, setModalDelete] = useState<{
    isOpen: boolean;
    id?: number;
  }>({
    isOpen: false,
  });

  const [modalDisabled, setModalDisabled] = useState<{
    isOpen: boolean;
    id?: number;
    tipoStatus?: TipoStatusEmpresa;
  }>({
    isOpen: false,
  });

  const { openNotification } = useNavigation();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  let activeRequests = 0;

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<any>(null);
  const [isLoadingDatas, setIsLoadingDatas] = useState(false);
  const [empresass, setEmpresas] = useState<IEmpresa[]>([]);
  const [totalItemsPagina, settotalItens] = useState<number>(0);
  const [empresasFiltradas, setEmpresasFiltradas] = useState<IEmpresa[]>([]);

  //const [atualizouToken, setAtualizouToken] = useState(false);

  const { control, ...methods } = useForm({
    mode: "onBlur",
  });

  const [filterColumnsDebounced, setFilterColumnsDebounced] = useState<{
    column?: "razaoSocial" | "nomeFantasia" | "cpfCnpj";
    value?: string;
  }>();

  const debouncedFilterColumn = useCallback(
    debounce(
      (column: "razaoSocial" | "nomeFantasia" | "cpfCnpj", value: string) => {
        setFilterColumnsDebounced({ column, value });
      },
      TIME_DEBOUNCE
    ),
    [setFilterColumnsDebounced]
  );

  const {
    pagination,
    debouncedSearch,
    handlePagination,
    handleTotalItems,
    totalItems,
  } = usePagination({
    search: filterColumnsDebounced?.value || "",
    page: 1,
    pageSize: 10,
    direction: "asc",
    orderBy: "",
    totalItemsPage: 0,
  });
  const useListaDeEmpresasColumns = useMemo(() => {
    return ({ onEdit, onRemove, showDetails }: HookParams) => {
      const columns: GridColDef[] = [
        {
          field: "nomeFantasia",
          headerName: "Nome fantasia",
          flex: 0.4,
          renderHeader: () => {
            return (
              <FilterColumn
                inputRef={refNomeFantasia}
                label="Nome fantasia"
                name="nomeFantasia"
                onChange={(event) => {
                  if (refNomeEmpresa.current) {
                    refNomeEmpresa.current.value = "";
                  }
                  if (refCpf.current) {
                    refCpf.current.value = "";
                  }

                  debouncedFilterColumn("nomeFantasia", event.target.value);
                }}
              />
            );
          },
        },
        {
          field: "razaoSocial",
          headerName: "Nome da empresa",
          flex: 0.4,
          renderHeader: () => {
            return (
              <FilterColumn
                inputRef={refNomeEmpresa}
                label="Nome da empresa"
                name="razaoSocial"
                onChange={(event) => {
                  if (refNomeFantasia.current) {
                    refNomeFantasia.current.value = "";
                  }
                  if (refCpf.current) {
                    refCpf.current.value = "";
                  }
                  debouncedFilterColumn("razaoSocial", event.target.value);
                }}
              />
            );
          },
        },

        {
          field: "cpfCnpj",
          headerName: "CNPJ/CPF",
          flex: 0.4,
          renderHeader: () => {
            return (
              <FilterColumn
                inputRef={refCpf}
                label="CNPJ/CPF"
                name="cpf"
                onChange={(event) => {
                  if (refNomeEmpresa.current) {
                    refNomeEmpresa.current.value = "";
                  }
                  if (refNomeFantasia.current) {
                    refNomeFantasia.current.value = "";
                  }

                  debouncedFilterColumn("cpfCnpj", event.target.value);
                }}
              />
            );
          },
        },
        {
          field: "parceiro",
          headerName: "Parceiro",
          sortable: false,
          flex: 0.15,
          renderCell: (params) => {
            return params.row.parceiro ? (
              <CheckCircle
                color="success"
                sx={{ display: "flex", justifyContent: "center" }}
              />
            ) : (
              <CheckCircleOutline
                color="disabled"
                sx={{ display: "flex", justifyContent: "center" }}
              />
            );
          },
        },
        {
          field: "statusEmpresa",
          headerName: "Status empresa",
          sortable: false,
          flex: 0.2,
          renderCell: (params) => {
            return params.row.ativo ? (
              <CheckCircle
                color="success"
                sx={{ display: "flex", justifyContent: "center" }}
              />
            ) : (
              <CheckCircleOutline
                color="disabled"
                sx={{ display: "flex", justifyContent: "center" }}
              />
            );
          },
        },
        {
          field: "options",
          sortable: false,
          headerName: "",
          align: "center",
          flex: 0.1,
          renderCell: (params) => {
            return (
              <FloatMenuCrud
                onEdit={() => onEdit(params.row as IEmpresa)}
                hasDelete={false}
                otherOptions={[
                  {
                    icon: <ChevronRight />,
                    label: params.row.ativo ? "DESATIVAR" : "ATIVAR",
                    onClickButton: () =>
                      onRemove(
                        params.id as number,
                        params.row.ativo ? "DESATIVAR" : "ATIVAR"
                      ),
                    color: params.row.ativo ? "error" : "success",
                  },
                ]}
                showDuplicateOption={false}
              />
            );
          },
        },
      ];
      return columns;
    };
  }, []);

  const columns = useListaDeEmpresasColumns({
    onEdit(id) {
      navigate(`/empresasMof/editar/${id.idEmpresa}`);
    },
    onRemove: (id, tipoStatus) =>
      setModalDisabled({
        isOpen: true,
        id: Number(id),
        tipoStatus,
      }),
    showDetails(nomeEmpresa = "", nomeFantasia = "", cpf = 0) {
      setShowDetails(true);
      const details = { nomeEmpresa, nomeFantasia, cpf };
      setDetails(details);
    },
  });

  const getEmpresasDatas = async (params?: any) => {
    setIsLoadingDatas(true);
    try {
      const result = await getEmpresas(params);
      const totalItemsPagina = await getDadosPaginacao();
      if (result) {
        settotalItens(totalItemsPagina);
        setEmpresas(result);
        return;
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Não foi possivel carregar as Empresas",
        type: "error",
      });
    } finally {
      if (activeRequests === 0) {
        setIsLoadingDatas(false);
      }
    }
    setEmpresas([]);
  };

  async function handleDeleteEmpresa(id?: number) {
    if (!id) return;
    setIsLoadingDelete(true);
    try {
      const result = await deleteEmpresa(id);
      if (result) {
        openNotification({
          isOpen: true,
          message: "A Empresa foi exluida com sucesso!",
          type: "success",
        });
        getEmpresasDatas(pagination);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }

  async function handleDisabledPerfilEmpresa(
    id?: number,
    status?: TipoStatusEmpresa
  ) {
    if (!id) return;
    const isAtivo = status === "ATIVAR" ? true : false;

    try {
      const response = await postDesativarAtivarEmpresa(id, isAtivo);
      if (response) {
        openNotification({
          isOpen: true,
          message: `A Empresa foi ${
            isAtivo ? "ativada" : "desativada"
          } com sucesso!`,
          type: "success",
        });
        await getEmpresasDatas();
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  function handleNavigateToEdit(id: string | number) {
    navigate(`/empresasMof/editar/${id}`);
  }

  // #ESPERAR OS FILTROS DO BACK
  function handleUpdatePage(newPage: number) {
    handlePagination({ page: newPage });

    setTimeout(() => {
      const element = document.getElementById(SEARCH_BAR_EMPRESA_ID);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 200);
  }

  useEffect(() => {
    const token = sessionStorage.getItem("@MOF:token");
    if (!token) return;
    if (filterColumnsDebounced) {
      const paginacao = {
        page: 1,
        limit: 10,
        sortBy: "",
        direction: pagination?.direction || "asc",
        filtrar: filterColumnsDebounced.value || "",
      };
      handlePagination(paginacao);
      getEmpresasDatas(paginacao);

      setEmpresasFiltradas(() => {
        const newEmpresas = empresass.filter((empresa) => {
          switch (filterColumnsDebounced.column) {
            case "razaoSocial":
              return empresa.razaoSocial
                .toLowerCase()
                .includes(filterColumnsDebounced.value?.toLowerCase() ?? "");
            case "nomeFantasia":
              return empresa.nomeFantasia
                .toLowerCase()
                .includes(filterColumnsDebounced.value?.toLowerCase() ?? "");
            case "cpfCnpj":
              return empresa.cpfCnpj
                .toLowerCase()
                .includes(filterColumnsDebounced.value?.toLowerCase() ?? "");
          }
        });
        if (newEmpresas.length == 0) {
          return [];
        }
        return newEmpresas;
      });
    } else {
      getEmpresasDatas(pagination);
    }
  }, [filterColumnsDebounced, sessionStorage.getItem("@MOF:token")]);

  useEffect(() => {
    const token = sessionStorage.getItem("@MOF:token");
    if (!token) return;
    getEmpresasDatas(pagination);
  }, [pagination]);

  return (
    <>
      <DashboardStackCustom>
        <BackdropLoading isOpen={isLoadingDelete} />
        <NavigationHeader
          title={"EMPRESAS"}
          label={"Empresas"}
          buttonLabel={"Cadastrar"}
          buttonLink="/empresasMof/cadastrarEmpresa"
          hasBackButton={false}
        />
        <Grid container display={"flex"} alignItems={"center"}>
          <StackCustom>
            <Grid container mb={4}>
              <Grid item xs={12} sm={8} md={12}>
                <TitleTable title="Listagem de empresas MOF" />
              </Grid>
            </Grid>
            <Paper>
              <StripedDataGrid
                rows={empresass}
                columns={columns}
                getRowId={(row: IEmpresa) => row.idEmpresa}
                onClickDelete={(ids) => null}
                page={pagination.page - 1}
                pageSize={pagination.pageSize}
                rowCount={totalItemsPagina}
                loading={isLoadingDatas || isLoadingDelete}
                onPageChange={(newPage) =>
                  handlePagination({ page: newPage + 1 })
                }
                onPageSizeChange={(newPageSize) => {
                  handlePagination({
                    pageSize: newPageSize,
                    page: 1,
                  });
                }}
                checkboxSelection={false}
                rowHeight={70}
                onChangeSingleColumnOrder={(value) => {
                  const sortBy = value.sortBy.replace(/-([a-z])/g, (g) =>
                    g[1].toUpperCase()
                  );

                  handlePagination({
                    orderBy: sortBy,
                    direction: value.direction,
                    page: 1,
                  });
                }}
                sx={{ display: !isDesktop ? "none" : "inherit" }}
              />
            </Paper>
            <Grid
              container
              spacing={3}
              sx={{
                display: {
                  xs: "inherit",
                  md: "none",
                },
              }}
            >
              {/* {empresass.map((item) => (
                <Grid key={item.idEmpresa} item xs={12} sm={6}>
                  <CardLevelOne
                    values={item}
                    fields={{
                      idEmpresa: "Nome Empresa",
                      cpfCnpj: "Cpj",
                      nomeFantasia: "Nome Fantasia",
                      razaoSocial: "Razão Social",
                    }}
                    buttonText="Ver Empresa"
                    keyExtractor={(values) => values.idEmpresa}
                    onPressButton={() =>
                      handleNavigateToEdit(item?.idEmpresa as number)
                    }
                  />
                </Grid>
              ))} */}

              {/* <Grid item xs={12}>
                <MobilePagination
                  page={pagination.page}
                  pageSize={pagination.pageSize}
                  totalItems={totalItems}
                  onChangePage={handleUpdatePage}
                />
              </Grid> */}
            </Grid>
          </StackCustom>
        </Grid>
        <ConfirmDeleteModal
          title={
            modalDisabled.tipoStatus === "DESATIVAR"
              ? "Tem certeza que deseja desativar essa Empresa?"
              : "Tem certeza que deseja ativar essa Empresa?"
          }
          description=""
          open={modalDisabled.isOpen}
          onClose={() => setModalDisabled({ isOpen: false })}
          onConfirm={() => {
            handleDisabledPerfilEmpresa(
              modalDisabled.id,
              modalDisabled.tipoStatus
            )
              .then(() => {
                return getEmpresasDatas();
              })
              .finally(() => {
                setModalDisabled({ isOpen: false });
              });
          }}
          buttonTextDelete={
            modalDisabled.tipoStatus === "DESATIVAR" ? "DESATIVAR" : "ATIVAR"
          }
          customButtonConfirm={
            <LoadingButton
              variant="contained"
              size="medium"
              color={
                modalDisabled.tipoStatus === "DESATIVAR" ? "error" : "success"
              }
              endIcon={
                modalDisabled.tipoStatus === "DESATIVAR" ? (
                  <ClearIcon />
                ) : (
                  <Check />
                )
              }
              sx={{ color: "white" }}
              onClick={() => {
                handleDisabledPerfilEmpresa(
                  modalDisabled.id,
                  modalDisabled.tipoStatus
                )
                  .then(() => {
                    return getEmpresasDatas();
                  })
                  .finally(() => {
                    setModalDisabled({ isOpen: false });
                  });
              }}
              loading={isLoadingDelete}
              loadingPosition="end"
            >
              {modalDisabled.tipoStatus === "DESATIVAR"
                ? "DESATIVAR"
                : "ATIVAR"}
            </LoadingButton>
          }
        />
      </DashboardStackCustom>
    </>
  );
};
