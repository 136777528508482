import React from "react";
import { createRoot } from "react-dom/client";
import { Map } from "react-map-gl/maplibre";
import DeckGL from "@deck.gl/react";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";

import type { MapViewState } from "@deck.gl/core";
import { Grid } from "@mui/material";

const testeMap: DataPoint[] = [];

const DATA_URL =
  "https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/screen-grid/uber-pickup-locations.json"; // eslint-disable-line

const INITIAL_VIEW_STATE: MapViewState = {
  longitude: -55.1379,
  latitude: -14.9225,
  zoom: 3.2,
  maxZoom: 16,
  pitch: 0,
  bearing: 0,
};

const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json";

type DataPoint = [longitude: number, latitude: number, count: number];

export default function NewGrafico({
  data = testeMap,
  intensity = 2,
  threshold = 0.03,
  radiusPixels = 30,
  mapStyle = MAP_STYLE,
  selectedRegion = "",
}: {
  data?: string | DataPoint[];
  intensity?: number;
  threshold?: number;
  radiusPixels?: number;
  mapStyle?: string;
  selectedRegion: string;
}) {
  const layers = [
    new HeatmapLayer<DataPoint>({
      data,
      id: "heatmap-layer",
      pickable: false,
      getPosition: (d) => [d[0], d[1]],
      getWeight: (d) => d[2],
      radiusPixels,
      intensity,
      threshold,
    }),
  ];

  return (
    <DeckGL
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      layers={layers}
      height={"600px"}
      width={"810px"}
      style={{
        position: "relative",
        borderRadius: "20px",
        maxHeight: "600px",
        maxWidth: "850px",
      }}
    >
      <Map reuseMaps mapStyle={mapStyle} style={{ borderRadius: "20px" }} />
    </DeckGL>
  );
}
