import ModalComponent from "components/Modal";
import { ListaDashBoard } from "../ListaDashboard";
import { Paper } from "@mui/material";
import { ResponsiveType } from "shared/interfaces/styling";
import { GridColumns } from "@mui/x-data-grid";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  width?: ResponsiveType;
  height?: ResponsiveType;
  columns: GridColumns<any>;
  rows: any[];
}

export function ModalLista({
  isOpen,
  onClose,
  title,
  width = { xs: "90svw", md: "70svw" },
  height = { xs: "90svh", md: "70svh" },
  columns,
  rows = [],
}: ModalListaProps) {
  return (
    <ModalComponent
      open={isOpen}
      handleClose={onClose}
      title={title}
      width={width}
      height={height}
    >
      <ListaDashBoard columns={columns} dataRows={rows} maxHeight={"auto"} />
    </ModalComponent>
  );
}
