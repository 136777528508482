import {
  AccordionSummary,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode, useEffect, useState } from "react";
import { fontsSizes } from "styles/theme";

interface AccordionsProps {
  id: number;
  descricao: string;
  checked: boolean;
  onChangeSwtich?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  children: ReactNode;
}

export default function AccordionsSwitch({
  id,
  descricao,
  checked,
  onChangeSwtich,
  children,
}: AccordionsProps) {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (!checked) {
      setExpanded(false);
    }
  }, [checked]);

  return (
    <Accordion expanded={isExpanded && checked} sx={{ marginBottom: 2 }}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          />
        }
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
      >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item key={id}>
            <Typography
              sx={{
                fontSize: fontsSizes.large,
                fontWeight: 700,
                color: "#3C90AD",
              }}
            >
              <Typography>{descricao}</Typography>
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Switch checked={checked} onChange={onChangeSwtich} />}
              label=""
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
