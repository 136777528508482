import { ReactNode, createContext } from "react";
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { ChevronRight, Edit, PostAdd } from "@mui/icons-material";
import { fontsSizes } from "styles/theme";

type FloatMenuContextType = {
  onEdit(id: number | string): void;
  onDuplicate(id: number | string): void;
  onRemove(id: number | string): void;
};

export const FloatMenuContext = createContext<FloatMenuContextType>(
  {} as FloatMenuContextType
);

type FloatMenuCrudProps = {
  onDelete?: () => void;
  hasDelete?: boolean;
  onEdit: () => void;
  onDuplicate?: () => void;
  showDuplicateOption?: boolean;
  deleteButtonText?: string;
  disableEdit?: boolean;
  disableRemove?: boolean;
  otherOptions?: {
    label: string;
    onClickButton: () => void;
    icon: ReactNode;
    disabled?: boolean;
    color?:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
  }[];
};

export const FloatMenuCrud = ({
  onDelete,
  onDuplicate,
  onEdit,
  showDuplicateOption = true,
  deleteButtonText,
  otherOptions = [],
  disableEdit,
  disableRemove,
  hasDelete = true,
}: FloatMenuCrudProps) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div style={{ margin: "0 auto" }}>
          <IconButton {...bindTrigger(popupState)}>
            <GridMoreVertIcon color="primary" />
          </IconButton>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Stack width={193} py={1} px={2}>
              <Button
                variant="text"
                onClick={() => {
                  bindPopover(popupState).onClose();
                  setTimeout(() => {
                    onEdit();
                  }, 100);
                }}
                endIcon={<Edit color="primary" />}
                sx={{
                  height: 52,
                  "&:disabled": {
                    "& *": {
                      color: "rgba(0, 0, 0, 0.26)",
                    },
                  },
                }}
                disabled={disableEdit}
              >
                <Typography
                  fontSize={fontsSizes.medium}
                  fontWeight={500}
                  fontFamily="Inter"
                  color="primary"
                >
                  EDITAR
                </Typography>
              </Button>
              <Divider />
              {showDuplicateOption && (
                <>
                  <Button
                    variant="text"
                    onClick={() => {
                      bindPopover(popupState).onClose();
                      if (onDuplicate !== undefined) {
                        onDuplicate();
                      }
                    }}
                    endIcon={<PostAdd color="primary" />}
                    sx={{
                      height: 52,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      fontFamily="Inter"
                      color="primary"
                    >
                      DUPLICAR
                    </Typography>
                  </Button>
                  <Divider />
                </>
              )}

              {hasDelete && (
                <Button
                  variant="text"
                  onClick={() => {
                    bindPopover(popupState).onClose();
                    onDelete?.();
                  }}
                  endIcon={<ChevronRight color="error" />}
                  sx={{
                    height: 52,
                    "&:disabled": {
                      "& *": {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    },
                  }}
                  disabled={disableRemove}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    fontFamily="Inter"
                    color="error"
                  >
                    {deleteButtonText ?? "EXCLUIR"}
                  </Typography>
                </Button>
              )}
              {hasDelete && <Divider />}

              {otherOptions.length > 0 &&
                otherOptions.map((option, index) => {
                  return (
                    <Stack key={index}>
                      <Button
                        variant="text"
                        onClick={() => {
                          bindPopover(popupState).onClose();
                          option.onClickButton?.();
                        }}
                        endIcon={option.icon}
                        sx={{
                          height: 52,
                        }}
                        color={option.color ?? "primary"}
                        disabled={option.disabled}
                      >
                        <Typography
                          fontSize={fontsSizes.medium}
                          fontWeight={500}
                          fontFamily="Inter"
                        >
                          {option.label}
                        </Typography>
                      </Button>

                      <Divider />
                    </Stack>
                  );
                })}
            </Stack>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
