import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: { codigo: string; descricao: string; porcentagemUso: number }[];
}

export function ModalListaComponentes({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "codigo",
          headerName: "Código",
          sortable: false,
          flex: 0.15,
        },
        {
          field: "descricao",
          headerName: "Descrição",
          sortable: false,
          flex: 0.35,
        },
        {
          field: "porcentagemUso",
          headerName: "% de uso",
          sortable: false,
          flex: 0.15,
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
