import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Switch, Typography } from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import ModalComponent from "components/Modal";
import { ModalUnsavedEdit } from "components/ModalUnsavedEdit";
import SelectComponent from "components/Select";
import { FormControlCustom } from "components/StackCustom/styles";
import { ControlledTextField } from "components/TextField/ControlledTextField";
import { useNavigation } from "contexts/notificationsContext";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getEmpresaById } from "services/modules/empresas";
import {
  getUsuarioById,
  postUsuario,
} from "services/modules/empresas/usuarios";
import { FSelect } from "shared/interfaces/select";
import { IGetUsuarioById, IPostUsuario } from "shared/interfaces/usuarios";
import { fontsSizes } from "styles/theme";

interface ModalFornecedorProps {
  isOpen: boolean;
  onClose: () => void;
  refreshData?: () => void;
  dataUsuario?: IGetUsuarioById;
}

const tipoUsuarioEnum = {
  1: "Master",
  2: "Administrador",
  3: "Usuário Geral",
  4: "Usuário MOF",
};

export function ModalCadastroUsuario({
  isOpen,
  onClose,
  dataUsuario,
}: ModalFornecedorProps) {
  const params = useParams();
  const { openNotification } = useNavigation();
  const [isOpenModalUnsaved, setIsOpenModalUnsaved] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isAtivo, setIsAtivo] = useState(dataUsuario?.ativo || false);
  const [items, setItems] = useState<FSelect[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const tipoUsuarioEnumArray = Object.entries(tipoUsuarioEnum).map(
    ([value, label]) => ({
      value: Number(value),
      label,
    })
  );
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    formState,
    ...methods
  } = useForm<IPostUsuario>({
    mode: "onBlur",
  });

  const resetForm = () => {
    reset({
      idUsuario: 0,
      nome: "",
      tipoUsuario: 0,
      abasUtilizadasIds: [],
      ativo: false,
      email: "",
      idEmpresa: 0,
    });
    setSelectedItems([]);
  };

  const handleSwitchChange = (id: number) => {
    setSelectedItems((prev) => {
      const isSelected = prev.some((item) => item.id === id);
      if (isSelected) {
        return prev.filter((item) => item.id !== id);
      } else {
        return [...prev, { id, nome: "", disponivel: true }];
      }
    });
  };

  const handleSubmitUsuario = async (data: IPostUsuario) => {
    setIsLoadingSubmit(true);
    try {
      const postData: IPostUsuario = {
        idUsuario: data.idUsuario ?? 0,
        nome: data.nome,
        email: data.email,
        ativo: data.ativo ?? true,
        tipoUsuario: Number(data.tipoUsuario),
        idEmpresa: dataUsuario?.idEmpresa ?? Number(params.id),
        abasUtilizadasIds: selectedItems.map((x) => x.id),
      };

      if (postData.idUsuario > 0) {
        await postUsuario(postData);
        openNotification({
          isOpen: true,
          message: "Usuário atualizado com sucesso!",
          type: "success",
        });
      } else {
        await postUsuario(postData);
        openNotification({
          isOpen: true,
          message: "Usuário cadastrado com sucesso!",
          type: "success",
        });
      }

      resetForm();
      onClose();
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const fillDataPerfilData = async (data: IGetUsuarioById) => {
    const result = await getUsuarioById(data.idUsuario);
    if (result) {
      const perfilAbaIds = (result?.data.abasUtilizadas || []).map((aba) => ({
        id: aba.id,
        nome: aba.nome,
        disponivel: aba.disponivel,
      }));
      setSelectedItems(perfilAbaIds);
      reset({
        idUsuario: result?.data.idUsuario,
        nome: result?.data.nome,
        email: result?.data.email,
        ativo: result?.data.ativo,
        idEmpresa: Number(data.idEmpresa),
        tipoUsuario: result?.data.tipoUsuario,
        abasUtilizadasIds: perfilAbaIds.map((aba) => aba.id),
      });
    }
  };

  const handleCheckModal = () => {
    reset({
      nome: "",
      email: "",
      tipoUsuario: 0,
      ativo: false,
      abasUtilizadasIds: [],
    });
    setIsOpenModalUnsaved(false);
    onClose();
  };

  const handleCloseModal = () => {
    resetForm();
    if (formState.isDirty) {
      setIsOpenModalUnsaved(true);
    } else {
      onClose();
    }
  };

  const handleAtivoChange = (checked: boolean) => {
    setIsAtivo(checked);
    setValue("ativo", checked);
  };
  const isAbaChecked = (value: number) => {
    return selectedItems.some((aba) => aba.id === value);
  };

  useEffect(() => {
    const fetchItems = async () => {
      const empresaId = dataUsuario?.idEmpresa || Number(params.id);
      const result = await getEmpresaById(empresaId);
      const abasUtilizadasEmpresa = result?.data?.abasUtilizadasPerfil || [];
      const abasAdicionaisEmpresa =
        result?.data?.abasUtilizadasRecursosAdicionais || [];

      const formattedItems = [
        ...abasUtilizadasEmpresa.map((item) => ({
          value: item.id,
          label: item.nome,
        })),
        ...abasAdicionaisEmpresa.map((item) => ({
          value: item.id,
          label: item.nome,
        })),
      ];

      setItems(formattedItems);
    };

    fetchItems();
  }, [dataUsuario, params.id]);

  useEffect(() => {
    if (dataUsuario) {
      fillDataPerfilData(dataUsuario);
      setSelectedItems(dataUsuario.abasUtilizadas || []);
      setIsAtivo(dataUsuario.ativo ?? false);
    } else {
      resetForm();
    }
  }, [dataUsuario]);

  return (
    <FormProvider
      {...{
        control,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        clearErrors,
        formState,
        ...methods,
      }}
    >
      <ModalComponent
        open={isOpen}
        handleClose={handleCloseModal}
        height={"auto"}
        width={{ xs: "644px" }}
        title="Cadastro de Usuário"
      >
        <Grid container spacing={2} mb={2} py={2} px={3}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              name="nome"
              label="Nome do Usuário"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              name="email"
              label="E-mail"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectComponent
              items={tipoUsuarioEnumArray}
              control={control}
              name="tipoUsuario"
              label={"Permissão do Usuário"}
              style={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <FormControlCustom
              label={"Ativo"}
              control={
                <Switch
                  onChange={(e) => handleAtivoChange(e.target.checked)}
                  checked={watch().ativo}
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: fontsSizes.medium,
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Perfil do usuário
            </Typography>
            <Divider sx={{ width: "470px" }} />
          </Grid>

          <Grid container spacing={2} ml={1}>
            {items.map((item) => (
              <Grid item xs={12} sm={12} key={item.value}>
                <FormControlCustom
                  label={item.label}
                  control={
                    <Switch
                      checked={isAbaChecked(item.value)}
                      onChange={() => handleSwitchChange(item.value)}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"flex-end"} mt={"auto"} p={2}>
          <CustomButton onClick={handleCloseModal}>Cancelar</CustomButton>
          <LoadingButton
            type="submit"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit(handleSubmitUsuario)}
            loading={isLoadingSubmit}
            loadingPosition="end"
          >
            Salvar
          </LoadingButton>
        </Box>
      </ModalComponent>
      <ModalUnsavedEdit
        description="Ao sair todas as informações preenchidas serão apagadas, deseja continuar?"
        isOpen={isOpenModalUnsaved}
        onClose={() => setIsOpenModalUnsaved(false)}
        onYes={handleCheckModal}
        onNot={() => setIsOpenModalUnsaved(false)}
        type
      />
    </FormProvider>
  );
}
