import { DeleteForever } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import ModalComponent from "components/Modal";
import { ReactNode } from "react";
import { fontsSizes } from "styles/theme";

type ConfirmDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  description: string;
  secondDescription?: string;
  isLoadingDelete?: boolean;
  sx?: SxProps<Theme>;
  buttonTextDelete?: string;
  customButtonConfirm?: ReactNode;
};

export default function ConfirmDeleteModal({
  open,
  onClose,
  onConfirm,
  title,
  description,
  isLoadingDelete,
  secondDescription,
  buttonTextDelete,
  customButtonConfirm,
  sx,
}: ConfirmDeleteModalProps) {
  return (
    <ModalComponent
      open={open}
      handleClose={onClose}
      height={"auto"}
      width={450}
      title={title}
      isAlert={true}
      sxModal={sx}
    >
      <Stack height="100%" justifyContent="space-between">
        <Typography
          variant="body2"
          align="left"
          justifyContent="center"
          mx={3}
          color="GrayText"
          fontSize={fontsSizes.medium}
        >
          {description}
        </Typography>
        <Typography
          variant="body2"
          align="left"
          justifyContent="center"
          mx={3}
          color="GrayText"
          fontSize={fontsSizes.medium}
        >
          {secondDescription}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          mx={3}
          my={2}
        >
          <CustomButton onClick={onClose} sx={{ mr: 2, px: 2 }}>
            Cancelar
          </CustomButton>
          {customButtonConfirm ? (
            customButtonConfirm
          ) : (
            <LoadingButton
              variant="contained"
              size="medium"
              color="error"
              endIcon={<DeleteForever />}
              onClick={onConfirm}
              loading={isLoadingDelete}
              loadingPosition="end"
            >
              {buttonTextDelete ?? "EXCLUIR"}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </ModalComponent>
  );
}
