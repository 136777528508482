import dayjs from "dayjs";
import { apiDashboard } from "services/axios/api";
import { parseDate } from "shared/helpers/dashboard";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  ListaClientesMaisUtilizaramProjetos,
  ListaComponentesMaisCalculadosEmOrcamentos,
  ListaLinhasMaisCalculadosEmOrcamentos,
  ListaOrcamentoPorRegiao,
  ListaOrcamentosPorPeriodo,
  ListaPerfisMaisCalculadosEmOrcamentos,
  ListaPorcentagemComponentesSemAplicabilidade,
  ListaProjetosMaisCalculadosEmOrcamentos,
  ListaRankingTipologiasMaisUtilizadasEmOrcamentos,
} from "shared/interfaces/dashboardSistemista";

export async function getPorcentagemOrcamentosPorRegiao(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentoPorRegiao> =
      await apiDashboard.get(
        "/Sistemista/ListarPorcentagemOrcamentosPorRegiao",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getQuantidadeOrcamentosPorPeriodo(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentosPorPeriodo> =
      await apiDashboard.get(
        "/Sistemista/ListarQuantidadeOrcamentosPorPeriodo",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getLinhasMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaLinhasMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/Sistemista/ListarLinhasMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getProjetosMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaProjetosMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/Sistemista/ListarProjetosMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getPerfisMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaPerfisMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/Sistemista/ListarPerfisMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";
    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getComponentesMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaComponentesMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/Sistemista/ListarComponentesMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getClientesMaisUtilizaramProjetos(
  qtdRanking: number,
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaClientesMaisUtilizaramProjetos> =
      await apiDashboard.get(
        "/Sistemista/ListarClientesMaisUtilizaramProjetos",
        {
          params: dates
            ? {
                qtdRanking,
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : { qtdRanking },
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getPorcentagemComponentesSemAplicabilidade(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaPorcentagemComponentesSemAplicabilidade> =
      await apiDashboard.get(
        "/Sistemista/ListarPorcentagemComponentesSemAplicabilidade",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getRankingTipologiasMaisUtilizadasEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaRankingTipologiasMaisUtilizadasEmOrcamentos> =
      await apiDashboard.get(
        "/Sistemista/ListarRankingTipologiasMaisUtilizadasEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}
