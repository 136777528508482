import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Private } from "layouts/Private";
import { Public } from "layouts/Public";
import Dashboard from "../pages/Dashboard";
import { Login } from "pages/Login";
import { PerfisDeEmpresa } from "pages/PerfisDeEmpresa";
import { Empresas } from "pages/Empresas";
import { CadastroDeEmpresas } from "pages/Empresas/Formulario/telaCadastros";
import { CadastroEmpresaProvider } from "contexts/cadastroEmpresaContext";
import { getTokenFromUrl } from "shared/helpers/tokenHelper";
import { TokenContext } from "contexts/tokenContext";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "shared/hooks/auth";
import configs from "Config";

const AppRoutes = () => {
  const { setToken } = useContext(TokenContext);
  const { signInChange } = useAuth();

  const handleToken = async () => {
    await getTokenFromUrl();
    const token = sessionStorage.getItem("@MOF:token");
    const decodedJwt = jwtDecode(token ?? "");
    await signInChange({
      idUsuario:
        "IdUsuario" in decodedJwt
          ? parseInt(decodedJwt.IdUsuario as string)
          : 0,
    });
    setToken(token ?? "");
  };

  useEffect(() => {
    handleToken();
  }, []);

  const isAuthenticated = true;

  return (
    <Router basename={configs.REACT_APP_BASENAME}>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/login"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route
          path="/dashboardMof"
          element={
            isAuthenticated ? (
              <Private>
                <Dashboard />
              </Private>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/perfisDeEmpresa"
          element={
            <Private>
              <PerfisDeEmpresa />
            </Private>
          }
        />
        <Route
          path="/empresasMof"
          element={
            <Private>
              <Empresas />
            </Private>
          }
        />
        <Route
          path="/empresasMof/cadastrarEmpresa"
          element={
            <Private>
              <CadastroEmpresaProvider>
                <CadastroDeEmpresas />
              </CadastroEmpresaProvider>
            </Private>
          }
        />
        <Route
          path="/empresasMof/editar/:id"
          element={
            <Private>
              <CadastroEmpresaProvider>
                <CadastroDeEmpresas />
              </CadastroEmpresaProvider>
            </Private>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
