import { api } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  IGetUsuarioById,
  IUsuariosDaEmpresa,
  IPostUsuario,
  IAbasUsuariosDaEmpresa,
  IApiResponse,
} from "shared/interfaces/usuarios";
import { FSelect } from "shared/interfaces/select";

export const getUsuariosEmpresa = async (params?: any, pagination?: any) => {
  try {
    const paginacao = {
      page: pagination?.page || 1,
      limit: pagination?.pageSize || 10,
      sortBy: pagination?.sortBy || "",
      direction: pagination?.direction || "asc",
      filtrar: pagination?.filtrar || "",
    };

    const { data } = await api.get<IApiResponse>(
      `/Usuario/ListarUsuariosPorIdEmpresa?idEmpresa=${params.id}&page=${paginacao.page}&limit=${paginacao.limit}&sortBy=${paginacao.sortBy}&direction=${paginacao.direction}&filtrar=${paginacao.filtrar}`
    );
    return data.items;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Usuários";

    getErrorsByApi(error, errorMessage);
  }
};

export const getDadosPaginacao = async (params?: any, pagination?: any) => {
  try {
    const paginacao = {
      page: pagination?.page || 1,
      limit: pagination?.pageSize || 10,
      sortBy: pagination?.sortBy || "",
      direction: pagination?.direction || "asc",
      filtrar: pagination?.filtrar || "",
    };
    const { data } = await api.get<IApiResponse>(
      `/Usuario/ListarUsuariosPorIdEmpresa?idEmpresa=${params.id}&page=${paginacao.page}&limit=${paginacao.limit}&sortBy=${paginacao.sortBy}&direction=${paginacao.direction}&filtrar=${paginacao.filtrar}`
    );
    return data.totalRecords;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.errors?.Mensagens?.[0] ||
      "Tivemos problemas internos ao buscar as Empresas";

    getErrorsByApi(error, errorMessage);

    return 0;
  }
};

export const getAbasUsuariosDaEmpresa = async (params?: any) => {
  try {
    const response = await api
      .get<IAbasUsuariosDaEmpresa[]>(`/Usuario/ListarUsuariosPorIdEmpresa`, {
        params,
      })
      .then((res) => res.data);
    const data: FSelect[] = response.map((item) => ({
      value: item.id,
      label: item.nome,
    }));
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Usuários de Empresas";

    getErrorsByApi(error, errorMessage);
  }
};

export async function getUsuarioById(idUsuario: number) {
  if (!idUsuario) throw new Error("Id do Usuário não informado");
  try {
    const response: ApiResponse<IGetUsuarioById> = await api.get(
      `/Usuario/ObterUsuarioPorId?idUsuario=${idUsuario}`
    );
    return response;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar o Usuário";

    getErrorsByApi(error, errorMessage);
  }

  return null;
}

export const postUsuario = async (params: IPostUsuario) => {
  try {
    const data = await api
      .post("/Usuario/SalvarUsuario", params)
      .then((res) => res.data);
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos com esse usuário";

    getErrorsByApi(error, errorMessage);
  }
};

export const deleteUsuario = async (idUsuario: number) => {
  if (!idUsuario) throw new Error("Id do Usuário não informado");
  try {
    const response = await api.delete(
      `/Usuario/ExcluirUsuario?idUsuario=${idUsuario}`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao deletar esse Usuario";

    getErrorsByApi(error, errorMessage);
  }
  return null;
};

export const EnviarDadosAcesso = async (idUsuario: number) => {
  if (!idUsuario) throw new Error("Id do Usuário não informado");
  try {
    const response = await api.post(
      `/Usuario/EnviarDadosAcesso?idUsuario=${idUsuario}`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao enviar os dados de acesso desse usuário";

    getErrorsByApi(error, errorMessage);
  }
  return null;
};
