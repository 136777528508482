import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: { sistema: string; percentual: number }[];
}

export function ModalListaLinha({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "id",
          headerName: "Posição",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "sistema",
          headerName: "Sistema",
          sortable: false,
          flex: 0.6,
        },
        {
          field: "percentual",
          headerName: "% em orçamentos calculados",
          sortable: false,
          flex: 0.4,
        },
      ]}
      rows={data.map((d, index) => {
        return { ...d, id: index + 1 };
      })}
    />
  );
}
