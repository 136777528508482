import { TextField } from "@mui/material";
import { CustomFieldText } from "components/StackCustom/styles";

interface FilterColumnProps {
  label: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  // value: string;
  inputRef?: React.Ref<any> | undefined;
}

export function FilterColumn({
  label,
  name,
  onChange,
  // value,
  inputRef,
}: FilterColumnProps) {
  return (
    <CustomFieldText
      variant="standard"
      label={label}
      type="text"
      name={name}
      // value={value}
      defaultValue={""}
      onChange={onChange}
      onKeyDown={(event) => event.stopPropagation()}
      onClick={(event) => event.stopPropagation()}
      inputRef={inputRef}
    />
  );
}
