import ModalComponent from "components/Modal";
import { ListaDashBoard } from "../ListaDashboard";
import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: {
    codigo: string;
    sistemista: string;
    linha: string;
    semAp: number;
  }[];
}

export function ModalListaCompsSemAp({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "codigo",
          headerName: "Código",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "sistemista",
          headerName: "Sistemista",
          sortable: false,
          flex: 0.35,
        },
        {
          field: "linha",
          headerName: "Linha",
          sortable: false,
          flex: 0.35,
        },
        {
          field: "semAp",
          headerName: "% de componentes sem aplicabilidades",
          sortable: false,
          flex: 0.35,
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
