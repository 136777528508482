import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: {
    codigo: string;
    descricao: string;
    projetista: string;
    linha: string;
  }[];
}

export function ModalListaProjetos({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "codigo",
          headerName: "Código",
          sortable: false,
          flex: 0.15,
        },
        {
          field: "descricao",
          headerName: "Descrição",
          sortable: false,
          flex: 0.35,
        },
        {
          field: "projetista",
          headerName: "Projetista/Sistemista",
          sortable: false,
          flex: 0.35,
        },
        {
          field: "linha",
          headerName: "Linha/Sistema",
          sortable: false,
          flex: 0.35,
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
