import axios from "axios";
import { ViaCepProps } from "shared/interfaces/zipCode";

export async function getZipCode(
	zipCodeValue: string,
): Promise<ViaCepProps | null> {
	const zipCode = zipCodeValue.replace(".", "").replace("-", "");

	return await axios
		.get<ViaCepProps>(`https://viacep.com.br/ws/${zipCode}/json/`)
		.then((response) => {
			return response.data;
		})
		.catch(() => {
			return null;
		});
}
