import { createGlobalStyle } from "styled-components";

import "bootstrap/dist/css/bootstrap.css";

import "./tabler.min.css";

import "../assets/fontawesome/css/all.min.css";

import "react-toastify/dist/ReactToastify.css";

import "react-dual-listbox/lib/react-dual-listbox.css";

import "./toastStyle.css";

export const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  font-family: var(--tblr-font-sans-serif);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714;
  color: #232e3c;
  background-color: #f4f6fa;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.font-padrao { font-family: var(--tblr-font-sans-serif) !important; }
.border-none { border:none !important; }

.d-contents {
    display: contents;
}

.btn i {
    height: auto;
}
.btn svg {
    height: auto;
    width: auto;
    margin: 0;
    stroke-width: 1.6px;
}

.filtro-lateral {
    height: 200px;
}

.border-mof {
    border-color: #3C90AD!important;
}

input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;

}

/* Edge */
::-webkit-input-placeholder { 
  color: #a7abb1;
}
/* Internet Explorer 10-11 */
:-ms-input-placeholder { 
  color: #a7abb1;
}
::placeholder {
  color: #a7abb1;
}

/*Navbar*/
.navbar {
    background-color: #3C90AD !important;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0;
    min-height: auto;
    height: 64px;
}

.navbar-light {
    background-color: #FFF !important;
    color:#3C90AD !important;
    height: 48px;
}

.navbar .nav-link {
  font-weight: 400;
    color: #2C3955 !important;
    border-radius: 0 !important;
    padding: 0 30px 0 30px !important;
}

.navbar .active {
    border-bottom: solid 2px;
}

.busca,
.busca form,
.busca form input {
    width: 100% !important;
    z-index: 1;
}

.navbar .navbar-brand:focus.busca,
.navbar .navbar-brand:hover.busca {
    opacity: 1;
}

.busca input.form-control {
    border: none;
}

.select-busca button {
    background-color: #FFF;
    height: 35px;
    border: 1px solid rgba(101, 109, 119, .24);
}

.busca form input {
    color: #FFF !important;
    background-color: #36435C;
    background-image: url(../assets/image/search-solid.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position-y: center;
    background-position-x: 0.5rem;
    padding-left: 32px;
}

.busca form input:focus {
    color: #3C90AD !important;
}

.user {
    min-height: auto !important;
}

.notificacao::after,
.carrinho::after {
    display: none !important;
}

.user .btn {
    padding: 0 0 0 10px;
}

.user .btn:focus {
    box-shadow: none;
}

.btn-icon i {
    margin: 0 !important;
    margin-inline-start: 0 !important;
}

.user .btn-none {
    background: none;
    border: none;
    color: #FFF;
}

.user i.fas {
    padding-right: 10px;
}

.badge {
    border-radius: 10px;
    font-size: 10px;
    height: 1rem;
    min-width: 1rem;
}

.badge-xl {
    border-radius: 4px;
    font-size: 12px;
    height: 1rem;
    min-width: 1rem;
    padding: 15px;
}


.btn .badge {
    top: -7px;
    left: -18px;
}

.rdrCalendarWrapper {
    box-shadow: 1px 1px 81px rgba(41, 60, 74, .18);
    position: absolute;
    z-index: 99;
    top: 40px;
    right: 0;
}

.btn.data {
    height: min-content;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    transform: none;
}

.dropdown-link-color {
    color: #3C90AD !important;
    text-decoration: none;
}
.dropdown-link-color:focus, .dropdown-link-color:hover {
    color: inherit;
    text-decoration: none;
    background-color: rgba(101,109,119,.06);
}
.dropdown-link-color span:active {
    color: inherit;
    text-decoration: none;
    background-color: rgba(101,109,119,.06);
}

/*Modal*/
.modal-open .modal {
    backdrop-filter: blur(4px);
}

.modal-backdrop {
    background-color: #232e3c;
}

/*Card*/
.card-img-top-fabricante {
    position: absolute;
    top: 16px;
    z-index: 1;
    max-height: 50px;
    max-width: 100px;
    width: auto;
}

.card-img-top-fabricante-list {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 16px;
    margin-right: 16px;
    z-index: 1;
}

/*Cores MOF*/
.btn-mof {
    background-color: #3C90AD;
    color: #FFF;
}

.btn-mof:hover {
    background-color: #337991;
    color: #FFF;
    border-color: rgba(101, 109, 119, .24);
}

.options {
    min-height:320px;
    box-shadow: none !important;
}

.options:focus {
    box-shadow: none !important;
}

.options option {
    padding: 5px 5px;
}

.table .form-check {
  margin-top: .5rem;
  text-align: center;
}

.check .form-check-label {
    padding:5px 5px 5px 30px;
    border: solid 1px rgba(101,109,119,.24) !important;
    border-radius: 3px;
}
.check .form-check-input{
    position: relative !important;
    left: 30px !important;
    top:8px;
}
.check .form-check-input:checked+.form-check-label {
    z-index: 2 !important;
    color: #206bc4 !important;
    background: rgba(32,107,196,.04) !important;
    border: solid 1px #90b5e2 !important;
    border-radius: 3px;
}
/*Modal*/
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -3rem -1rem auto;
}

/* Table */
.border-table-side {
    border-left: 1px solid #656d7729;
    border-right: 1px solid #656d7729;
}
.column-table-1b {
    width: auto;
}
.column-table-2b {
    width: auto;
}
.column-table-3b {
    width: auto;
}
.column-table-4b {
    width: auto;
}
@media (min-width: 768px) {
    .column-table-1b {
        width: 8%;
    } 
    .column-table-2b {
        width: 15%;
    } 
    .column-table-3b {
        width: 19%;
    } 
    .column-table-4b {
        width: 23%;
    }
}
@media (min-width: 992px) {
    .column-table-1b {
        width: 6%;
    } 
    .column-table-2b {
        width: 12%;
    } 
    .column-table-3b {
        width: 15%;
    } 
    .column-table-4b {
        width: 17%;
    } 
}

.tabela-liberacao {
  font-size: 0.8rem;
}

/* Form Validation */   
.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #cd201f;
}

/* Form - Select */
.form-select {    
    background-color: #fff;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;   
}

/* Breadcrumbs */
.breadcrumb-item:hover:not(:last-child) {
    text-decoration: underline !important;
    cursor: pointer;
}
.breadcrumb-item {
    text-transform: capitalize;
}

.tabProduto{
    border: none !important;
}
.tabProduto .nav-link {
    border: solid 1px rgba(101,109,119,.24) !important;
    border-radius: 3px;
    position:relative;
    margin-right: 10px;
    padding: .5rem 1rem;
}
.tabProduto .nav-link.active {
    color: #206bc4 !important;
    background: rgba(32,107,196,.04) !important;
    border: solid 1px #90b5e2 !important;
    border-radius: 3px;
}
/* Alerts */
.alerts .btn {
    margin-top: 0.5rem;
}
.alerts h2 {
    margin: 0px 0px 0.5em !important;
} 

/* Select Groups*/
.selectgroup {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}

.selectgroup-item + .selectgroup-item {
  margin-left: -1px;
}

.selectgroup-item:not(:first-child) .selectgroup-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.selectgroup-item:not(:last-child) .selectgroup-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.selectgroup-button {
  display: block;
  border: 1px solid rgba(0, 40, 100, 0.12);
  text-align: center;
  padding: 0.282rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #9aa0ac;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  min-width: 2.375rem;
}

.selectgroup-button-icon {
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1rem;
}

.selectgroup-input:checked + .selectgroup-button {
  border-color: #467fcf;
  z-index: 1;
  color: #467fcf;
  background: #edf2fa;
}

.selectgroup-input:focus + .selectgroup-button {
  border-color: #467fcf;
  z-index: 2;
  color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.selectgroup-pills {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start;
}

.selectgroup-pills .selectgroup-item {
  margin-right: .5rem;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.selectgroup-pills .selectgroup-button {
  border-radius: 3px !important;
}

/* List Box */
.rdl-move {
    width: fit-content;
    align-items: center;
    justify-content: center;
    color: #232e3c;
    background-color: #fff;
    border-color: rgba(101,109,119,.24);
    white-space: nowrap;    
    font-weight: 500;   
    padding: .4375rem 1rem;
    font-size: .875rem;
    border-radius: 3px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.rdl-move:hover {
    color: #232e3c;
    border-color: #a7abb1 !important;
    background-color: #f4f6fa !important;
}
.rdl-move-left {
    margin-left: auto;
}
.rdl-move-all.rdl-move-left {
    order:-1;
    margin-bottom: 1rem;    
}  
.rdl-move-all.rdl-move-right {
    margin-bottom: 1rem
}    
.rdl-actions {
    width: 33.33%;
    justify-content: center;
    margin: 0;
    flex-direction: row;
    -ms-flex-direction: row;    
}
.rdl-actions-left {
    order:0;
    margin-right: .25rem
}
.rdl-actions-right {
    order:1;
    
}
.rdl-control-label {
    font-size: .67rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 1.6;
    color: #656d77;    
    pointer-events: none;
    display: block;
    padding: .25rem .75rem !important;
    margin-bottom: 0;
    white-space: nowrap;
    border-bottom: 1px solid rgba(101,109,119,.16);
}
.rdl-list-box {       
    border: 1px solid rgba(101,109,119,.16);
    border-radius: 3px;
    box-shadow: rgb(35 46 60 / 4%) 0 2px 4px 0;
    background: #ffffff;
}
.rdl-filter-container {
    padding: 1rem;
    border-bottom: 1px solid rgba(101,109,119,.16);    
}
.rdl-filter {
    margin: 0;
    height: auto;    
    padding: .4375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(101,109,119,.24);
    -webkit-appearance: none;
    appearance: none;
    border-radius: 3px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;   
}
.rdl-filter:focus {
    color: inherit;
    background-color: #fff;
    border-color: #90b5e2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(32 107 196 / 25%);   
}
.rdl-control-container {
    padding: 1rem;
}
.rdl-control {
    border: none;
    padding: 0;
    min-height: 320px;    
}
.rdl-control:focus {   
    outline: 0;
    box-shadow: none;    
}
.rdl-control option {   
    padding: 5px;    
}

/* Spinner Loading */
.small-box-loader {
    min-height: 200px;
}
.medium-box-loader {
    min-height: 400px;
}

/* Pagination */
.pagination .page-item {
    font-size: 1.2rem;
}

.modal-90w {
    width: 90%;
    max-width: none!important;
}

.modal-40w {
    width: 40%;
    max-width: none!important;
}

/* Custom Switch */
.custom-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding-left: 0;
  cursor: pointer;
}

.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-switches-stacked {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.custom-switches-stacked .custom-switch {
  margin-bottom: .5rem;
}

.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #e9ecef;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid rgba(0, 40, 100, 0.12);
  transition: .3s border-color, .3s background-color;
}

.custom-switch-indicator:before {
  content: '';
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
  transition: .3s left;
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #467fcf;
}

.custom-switch-input:checked ~ .custom-switch-indicator:before {
  left: calc(1rem + 1px);
}

.custom-switch-description {
  margin-left: .55rem;
  color: #6e7687;
  transition: .3s color;
  font-size: .94rem;
}

.custom-switch-input:checked ~ .custom-switch-description {
  color: #232e3c;
}


/* Item - Produtos */

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.item {
  opacity: 1;
  animation: appear 300ms ease;
  margin-bottom: 1rem;
}

@keyframes appearInativo {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
.item.inativo {
    opacity: 0.6;
    animation: appearInativo 300ms ease;
    margin-bottom: 1rem;
}
.item.inativo .card{
    background-color: #f9f9f9;
}

.block-with-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 60px;  
}

/* Tags */

.tag-produtos {
    
}

.tag-produtos .tag {
    font-size: 0.65rem;
    padding: 0 .40rem;
    line-height: 1.9em;
}

.tag {
  font-size: 0.75rem;
  color: #6e7687;
  background-color: #e9ecef;
  border-radius: 3px;
  padding: 0 .5rem;
  line-height: 2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;
}

a.tag:hover {
  background-color: rgba(110, 118, 135, 0.2);
  color: inherit;
}

.tag-addon {
  display: inline-block;
  padding: 0 .5rem;
  color: inherit;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.06);
  margin: 0 -.5rem 0 .5rem;
  text-align: center;
  min-width: 1.5rem;
}

.tag-addon:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tag-addon i {
  vertical-align: middle;
  margin: 0 -.25rem;
}

a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;
}

a.tag-addon:hover {
  background: rgba(0, 0, 0, 0.16);
  color: inherit;
}

.tag-avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px 0 0 3px;
  margin: 0 .5rem 0 -.5rem;
}

.tag-blue {
  background-color: #467fcf;
  color: #fff;
}

.tag-indigo {
  background-color: #6574cd;
  color: #fff;
}

.tag-purple {
  background-color: #a55eea;
  color: #fff;
}

.tag-pink {
  background-color: #f66d9b;
  color: #fff;
}

.tag-red {
  background-color: #cd201f;
  color: #fff;
}

.tag-orange {
  background-color: #fd9644;
  color: #fff;
}

.tag-yellow {
  background-color: #f1c40f;
  color: #fff;
}

.tag-green {
  background-color: #5eba00;
  color: #fff;
}

.tag-teal {
  background-color: #2bcbba;
  color: #fff;
}

.tag-cyan {
  background-color: #17a2b8;
  color: #fff;
}

.tag-white {
  background-color: #fff;
  color: #fff;
}

.tag-gray {
  background-color: #868e96;
  color: #fff;
}

.tag-gray-dark {
  background-color: #343a40;
  color: #fff;
}

.tag-azure {
  background-color: #45aaf2;
  color: #fff;
}

.tag-lime {
  background-color: #7bd235;
  color: #fff;
}

.tag-primary {
  background-color: #467fcf;
  color: #fff;
}

.tag-secondary {
  background-color: #868e96;
  color: #fff;
}

.tag-success {
  background-color: #5eba00;
  color: #fff;
}

.tag-info {
  background-color: #45aaf2;
  color: #fff;
}

.tag-warning {
  background-color: #f1c40f;
  color: #fff;
}

.tag-danger {
  background-color: #cd201f;
  color: #fff;
}

.tag-light {
  background-color: #f8f9fa;
  color: #fff;
}

.tag-dark {
  background-color: #343a40;
  color: #fff;
}

.tag-rounded {
  border-radius: 50px;
}

.tag-rounded .tag-avatar {
  border-radius: 50px;
}

.tags {
  margin-bottom: -.5rem;
  font-size: 0;
}

.tags > .tag {
  margin-bottom: .5rem;
}

.tags > .tag:not(:last-child) {
  margin-right: .5rem;
}

.selectUsuario {
  width: 200px !important;
  font-size: .625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .04em;
  line-height: 1.6;
  color: #656d77;
}

.card-table.ver-mais tr td:first-child {
  min-width: 220px;
}

/*Bug select*/
.menu-select-bug{
  z-index:20 !important;
}

/*Componente collapsible*/
.Collapsible {
  background-color: white; 
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; 
  }
  .Collapsible__contentInner p:last-child {
      margin-bottom: 0; 
  }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #cacaca;
  color: white; 
  }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    position: absolute;    
    content: 'v';
    font-weight: bold;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; 
  }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); 
  }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; 
  }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; 
}

.CustomTriggerCSS--open {
  background-color: darkslateblue; 
}

.Collapsible__custom-sibling {
  padding: 0px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; 
}
.compostos tr, td, th{

  padding: 10px;
  
  margin: auto;
  
  border: none;
  
  }
  .chart-container {
    width: 100%;
    height: 400px; /* ou a altura desejada */
  }

/* Adapte o valor de min-width conforme necessário */
.date-picker-container {
  min-width: 150px; /* Defina a largura mínima desejada */
}
// PROJETOS CEM WEB
.page-header.page-desenhador {
  margin: 0 0 0.5rem;
}

.custom-checkbox-projetos {
  width: 20px;
  height: 20px;
}

.checkbox-column {
  width: 50px;
}

.checkbox-column .checkbox-column-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-projeto {
  position: relative;
}

.tooltip .image-projeto{
  object-fit: contain;
}

.tooltip-projeto .tooltip-content {
  display: none;
  z-index: 10;
  position: absolute;
  top: -50%;
  left: 100px;
  width: 300px;
  height: 300px;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tooltip-projeto .tooltip-content>img {
  object-fit: contain;
}

.tooltip-projeto .image-projeto:hover+.tooltip-content {
  display: flex;
}

.filtros-projetos {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.filtros-projetos .selects-projetos {
  width: 60%;
  display: flex;
}

.filtros-projetos .selects-projetos .subheader {
  width: 100%;
}

.filtros-projetos .selects-projetos .subheader:not(:first-child) {
  margin-left: 1rem;
}

.filtros-projetos .selects-projetos .select-projetos {
  min-width: 200px;
  width: 100%;
}

.filtros-projetos .searchbox-projetos {
  margin-left: auto;
  margin-top: 15px;
  width: 30%;
}

.filtros-projetos .searchbox-projetos>input {
  height: 39px;
}

.voltar-desenhador {
  position: fixed;
}

.navMenus {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.voltar-desenhador .button-voltar-desenhador {
  background-color: #005C8F;
}

.maplibregl-control-container { 
  display: none !important;
}
`;
