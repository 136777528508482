import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  CircularProgress,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  TextFieldProps,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { styleInput } from "../TextField/index";

export interface Items {
  value: string | number;
  label: string;
}

type SelectFieldProps = Omit<TextFieldProps, "error"> & {
  id?: string;
  name: string;
  error?: any;
  label?: string;
  items?: Items[];
  style?: React.CSSProperties;
  control?: any;
  toggleInputData?: (value: any, element: string) => void;
  disabled?: boolean;
  ativo?: boolean;
  sx?: SxProps<Theme>;
  selectValue?: any;
  loading?: boolean;
  className?: string;
  disablePortal?: boolean;
  onClickInput?: () => void;
};

const SelectComponent: React.FC<SelectFieldProps> = ({
  error,
  label,
  items,
  name,
  id,
  control,
  toggleInputData,
  disabled = false,
  ativo = true,
  selectValue,
  loading,
  sx,
  className,
  disablePortal,
  onClickInput,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name, onBlur } }) => (
        <TextField
          {...props}
          id={id}
          data-testid={id}
          className={className}
          select
          label={label}
          name={name}
          value={control ? value ?? "" : selectValue ?? ""}
          disabled={disabled}
          onChange={(e) => {
            onChange(e.target.value);
            toggleInputData?.(e.target.value, name);
          }}
          helperText={error?.message}
          variant="filled"
          error={Boolean(error)}
          onBlur={onBlur}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            onClick: onClickInput,
            endAdornment: loading && (
              <InputAdornment position="end">
                <CircularProgress
                  color="primary"
                  size={15}
                  sx={{
                    marginRight: 3,
                  }}
                />
              </InputAdornment>
            ),
            name: name,
            ...props.InputProps,
          }}
          inputProps={{
            "data-testid": `${id}-input`,
            ...props.inputProps,
          }}
          SelectProps={{
            displayEmpty: true,
            MenuProps: {
              PaperProps: { sx: { maxHeight: 200 } },
              disablePortal: disablePortal,
            },
          }}
          sx={{
            width: "100%",
            borderRadius: "4px",
            ...styleInput,
            ...sx,
          }}
        >
          <MenuItem value="" disabled={ativo}>
            <span>Selecione</span>
          </MenuItem>
          {items?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default SelectComponent;
