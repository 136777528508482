import { Grid, Typography } from "@mui/material";
import { fontsSizes } from "styles/theme";
import WarningIcon from "@mui/icons-material/Warning";

export const DataNotFound = (props: any) => {
  return (
    <>
      {props.visible && (
        <Grid
          container
          className=" d-flex flex-column justify-content-center text-center my-4"
        >
          <Grid item justifyContent={"center"}>
            <WarningIcon
              sx={{
                marginLeft: "10px",
                color: "#3C90AD",
                fontSize: fontsSizes.xxxlarge,
                fontWeight: 600,
              }}
            />
            <Typography
              sx={{
                color: "#3C90AD",
                fontSize: fontsSizes.mlarge,
                fontWeight: 600,
              }}
            >
              Nenhum dado disponível!
            </Typography>

            {props.children}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const DataNotFoundFilter = (props: any) => {
  return (
    <>
      {props.visible && (
        <Grid
          container
          className=" d-flex flex-column justify-content-center text-center my-4"
        >
          <Grid item justifyContent={"center"}>
            <WarningIcon
              sx={{
                marginLeft: "10px",
                color: "#3C90AD",
                fontSize: fontsSizes.xxxlarge,
                fontWeight: 600,
              }}
            />
            <Typography
              sx={{
                color: "#3C90AD",
                fontSize: fontsSizes.mlarge,
                fontWeight: 600,
              }}
            >
              Nenhum registro encontrado com os filtros atuais
            </Typography>

            {props.children}
          </Grid>
        </Grid>
      )}
    </>
  );
};
