import dayjs from "dayjs";
import { apiDashboard } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  ListaClientesMaisUtilizaramProjetos,
  ListaLinhasMaisCalculadosEmOrcamentos,
  ListaOrcamentoPorRegiao,
  ListaOrcamentosPorPeriodo,
  ListaPorcentagemComponentesSemAplicabilidade,
} from "shared/interfaces/dashboardSistemista";
import {
  ListaProdutosMaisCalculadosEmOrcamentos,
  ListaSistemistasMaisCalculadosEmOrcamentos,
} from "shared/interfaces/dashboardCompSuperficies";
import { parseDate } from "shared/helpers/dashboard";

export async function getPorcentagemOrcamentosPorRegiao(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentoPorRegiao> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarPorcentagemOrcamentosPorRegiao",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getQuantidadeOrcamentosPorPeriodo(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentosPorPeriodo> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarQuantidadeOrcamentosPorPeriodo",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getLinhasMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaLinhasMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarLinhasMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getProdutosMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaProdutosMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarProdutosMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getSistemistasMaisCalculadosEmOrcamentos(
  qtdRanking: number,
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaSistemistasMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarSistemistasMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                qtdRanking,
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : { qtdRanking },
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getClientesMaisUtilizaramProjetos(
  qtdRanking: number,
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaClientesMaisUtilizaramProjetos> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarClientesMaisUtilizaramProjetos",
        {
          params: dates
            ? {
                qtdRanking,
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : { qtdRanking },
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getPorcentagemComponentesSemAplicabilidade(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaPorcentagemComponentesSemAplicabilidade> =
      await apiDashboard.get(
        "/FabricanteComponenteSuperficie/ListarPorcentagemComponentesSemAplicabilidade",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}
