import { Dayjs } from "dayjs";

type RGBColor = {
  r: number;
  g: number;
  b: number;
};

export function rgbStringByRGBindividuals({ r, g, b }: RGBColor) {
  return `rgb(${r},${g},${b})`;
}

export function applyFactorRGB({ r, g, b }: RGBColor, f: number) {
  return `rgb(${r + f > 255 ? 255 : r + f < 0 ? 0 : r + f}, ${
    g + f > 255 ? 255 : g + f < 0 ? 0 : g + f
  }, ${b + f > 255 ? 255 : b + f < 0 ? 0 : b + f})`;
}

type ParsedDate = {
  mesInicial: number;
  mesFinal: number;
  anoInicial: number;
  anoFinal: number;
};

export function parseDate(
  initialData?: Dayjs,
  finalData?: Dayjs
): ParsedDate | null {
  if (!initialData || !finalData) return null;
  const mesInicialString = initialData.format("MM");
  const anoInicialString = initialData.format("YYYY");

  const mesFinalString = finalData.format("MM");
  const anoFinalString = finalData.format("YYYY");

  const parsedMesInicial = parseInt(mesInicialString);
  const parsedAnoInicial = parseInt(anoInicialString);

  const parsedMesFinal = parseInt(mesFinalString);
  const parsedAnoFinal = parseInt(anoFinalString);

  if (
    isNaN(parsedMesInicial) ||
    isNaN(parsedAnoInicial) ||
    isNaN(parsedMesFinal) ||
    isNaN(parsedAnoFinal)
  ) {
    return null;
  }

  return {
    mesInicial: parsedMesInicial,
    anoInicial: parsedAnoInicial,
    mesFinal: parsedMesFinal,
    anoFinal: parsedAnoFinal,
  };
}
