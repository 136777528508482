import { useState, createContext, Dispatch, SetStateAction } from "react";

export type TokenContextProvider = {
  children: JSX.Element;
};

export type TokenContextValues = {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
};

export const TokenContext = createContext({} as TokenContextValues);

export const TokenProvider = ({ children }: TokenContextProvider) => {
  const [token, setToken] = useState("");

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
