import { Dayjs } from "dayjs";
import { api, apiDashboard } from "services/axios/api";
import { parseDate } from "shared/helpers/dashboard";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";

type TipoDashboard = {
  idTipoDashboard: number;
  descricao: string;
};

export async function getTipoDashboardEmpresaLogada() {
  try {
    const response: ApiResponse<TipoDashboard[]> = await api.get(
      "/TipoDashboard/ListarTipoDashboardPorEmpresaLogada"
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getDadosExportacao(initialData: Dayjs, finalData: Dayjs) {
  try {
    const dates = parseDate(initialData, finalData);
    const response = await apiDashboard("/Relatorio/ExportarDadosRelatorio", {
      responseType: "blob",
      params: {
        mesInicial: dates?.mesInicial,
        anoInicial: dates?.anoInicial,
        mesFinal: dates?.mesFinal,
        anoFinal: dates?.anoFinal,
        regiao: undefined,
      },
    });
    return response;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações para exportação";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}
