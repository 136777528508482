import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomButton } from "components/CustomButtom";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "shared/hooks/useResponsive";
import { fontsSizes } from "styles/theme";
import * as S from "./styles";

interface LinksProps {
  label: string;
  link?: string;
}

interface NavigationHeaderProps {
  title: string;
  label: string;
  links?: LinksProps[];
  buttonLabel?: string;
  buttonLink?: string;
  buttonOnClick?: () => void;
  inforAdd?: boolean;
  customElement?: React.ReactNode;
  goBack?: () => void;
  hasBackButton?: boolean;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  title,
  links,
  buttonLabel,
  buttonLink,
  label,
  customElement,
  buttonOnClick,
  goBack,
  hasBackButton = true,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  function getBreadCrumbs() {
    const breadcrumbsLinks: LinksProps[] = [];

    links && breadcrumbsLinks.push(...links);

    breadcrumbsLinks.push({
      label,
    });

    return breadcrumbsLinks;
  }

  return (
    <Grid
      pb={1}
      mb={2}
      borderBottom={`2px solid ${theme.palette.primary[200]}`}
      width="100%"
      container
    >
      <Grid item xs={12} sm={6} md display="flex" alignItems="center">
        {hasBackButton && (
          <IconButton onClick={goBack} sx={{ mr: 1, width: 32, height: 32 }}>
            <ChevronLeft />
          </IconButton>
        )}

        <Stack>
          <Typography
            variant="h3"
            align="left"
            justifyContent="center"
            sx={{
              mb: 1,
            }}
          >
            {title}
          </Typography>
          <Breadcrumbs
            separator={<ChevronRight sx={{ fontSize: fontsSizes.small }} />}
            aria-label="breadcrumb"
            sx={{
              "& .MuiBreadcrumbs-separator": {
                mx: 0,
              },
            }}
          >
            {getBreadCrumbs().map((item, index) => (
              <S.Link
                key={`${item.label}-${index}`}
                onClick={() => item?.link && navigate(item.link)}
                hasLink={!!item?.link}
              >
                {item.label}
              </S.Link>
            ))}
          </Breadcrumbs>
        </Stack>
      </Grid>

      {buttonLabel && (
        <Grid
          item
          xs={12}
          sm={6}
          mt={isMobile ? 2.5 : 0}
          display="flex"
          justifyContent={{
            md: "flex-end",
          }}
        >
          <CustomButton
            variant="contained"
            onClick={() =>
              buttonLink ? navigate(buttonLink) : buttonOnClick?.()
            }
            endIcon={<AddIcon />}
            sx={{
              [theme.breakpoints.up("md")]: {
                width: 240,
                alignSelf: "center",
              },
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
          >
            {buttonLabel}
          </CustomButton>
        </Grid>
      )}

      {customElement}
    </Grid>
  );
};

export default NavigationHeader;
