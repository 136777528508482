import { ApiErrorResponse } from "shared/interfaces/api";

const CODE_ERRORS = [400, 401, 404, 500];

export function getErrorsByApi(error: any, defaultMessage?: string) {
  if (!error) return;
  if (error?.data) {
    const response = error.data as ApiErrorResponse;

    if (CODE_ERRORS.includes(response.statusCode)) {
      if (Array.isArray(response.messages)) {
        throw new Error(
          response.messages.reduce((acc: string, curr: string) => {
            const new_msg = `${acc}\n- ${curr}`;
            return new_msg;
          })
        );
      }

      throw new Error(response.messages);
    }
  }

  if (error?.message)
    throw new Error(error.message ?? "Tivemos problemas ao realizar essa ação");

  throw new Error(defaultMessage ?? "Tivemos problemas ao realizar essa ação");
}
