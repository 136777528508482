import { NotificationsProvider } from "contexts/notificationsContext";
import RoutesComponent from "./routes";
import { Fragment } from "react/jsx-runtime";
import AlertComponent from "components/Alert";
import { GlobalStyle } from "styles/global";
import { AuthProvider } from "shared/hooks/auth";
import { TokenProvider } from "contexts/tokenContext";

function App() {

  return (
    <NotificationsProvider>
      <AuthProvider>
        <TokenProvider>
          <Fragment>
            <AlertComponent />
            <RoutesComponent />
          </Fragment>
        </TokenProvider>
      </AuthProvider>
      <GlobalStyle />
    </NotificationsProvider>
  );
}

export default App;
