import { CircularProgress, Grid } from "@mui/material";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { GraficoColuna } from "./components/GraficoColuna";
import { GraficoBarra, Serie } from "./components/GraficoBarra";
import { ListaDashBoard } from "./components/ListaDashboard";
import {
  getPorcentagemOrcamentosPorRegiao,
  getQuantidadeOrcamentosPorPeriodo,
  getTipoVidrosMaisCalculadosEmOrcamentos,
  getVidrosMaisCalculadosEmOrcamentos,
  getClientesMaisUtilizaramProjetos,
} from "services/modules/dashboard/vidros";
import { DashBoardVidrosDataType } from "shared/interfaces/dashboardVidros";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { GraficoBarraEmpilhada } from "./components/GraficoBarraEmpilhada";
import { ModalFiltroMesAno } from "components/ModalFiltroMesAno";
import { GraficoHeatMap } from "./components/GraficoHeatMap";
import { DashboardProps } from "shared/interfaces/dashboardSistemista";
import { ModalListaVidros } from "./components/Modais/ModalListaVidros";
import { ModalClientes } from "./components/Modais/ModalClientes";

function DashboardVidros({
  modalDataFiltro,
  setModalDataFiltro,
  triggerUpdateDashboard,
  selectedRegion,
  setSelectedRegion,
}: DashboardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVidrosMaisCalculadosOpen, setIsModalVidrosMaisCalculadosOpen] =
    useState(false);
  const [isModalClientesOpen, setIsModalClientesOpen] = useState(false);

  const [dashBoardData, setDashboardData] = useState<DashBoardVidrosDataType>();

  const getDashBoardData = async (
    inicialData?: dayjs.Dayjs,
    finalData?: dayjs.Dayjs,
    regiaoSelecionada?: string,
    shouldShowLoading: boolean = true
  ) => {
    if (shouldShowLoading) setIsLoading(true);
    Promise.all([
      getPorcentagemOrcamentosPorRegiao(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getQuantidadeOrcamentosPorPeriodo(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getTipoVidrosMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getVidrosMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getClientesMaisUtilizaramProjetos(
        5,
        inicialData,
        finalData,
        regiaoSelecionada
      ),
    ])
      .then((res) => {
        setDashboardData({
          porcentagemOrcamentosPorRegiao: res[0],
          quantidadeOrcamentosPorPeriodo: res[1],
          tipoVidrosMaisCalculadosEmOrcamentos: res[2],
          vidrosMaisCalculadosEmOrcamentos: res[3],
          clientesMaisUtilizaramProjetos: res[4],
        });
      })
      .catch((error) => {
        setDashboardData(undefined);
        getErrorsByApi(
          error,
          "Tivemos problemas ao buscar as informações do Dashboard"
        );
      })
      .finally(() => {
        if (shouldShowLoading) setIsLoading(false);
      });
  };

  const dadosTipoVidrosMaisCalculados = useMemo(() => {
    const series: Serie[] = [];
    const categories: string[] = [];
    dashBoardData?.tipoVidrosMaisCalculadosEmOrcamentos?.forEach((tipo) => {
      categories.push(tipo.tipo);
      tipo.linhas.forEach((linha) => {
        const serie = series.find((s) => s.name === linha.linha);
        if (serie) {
          serie.data.push(linha.porcentagem);
        } else {
          series.push({ name: linha.linha, data: [linha.porcentagem] });
        }
      });
    });

    return {
      categories,
      series,
    };
  }, [dashBoardData?.tipoVidrosMaisCalculadosEmOrcamentos]);

  const dadosVidrosMaisCalculados = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.vidrosMaisCalculadosEmOrcamentos
      ?.slice(0, 5)
      .forEach((vidro) => {
        labels.push(vidro.codigo);
        data.push(vidro.porcentagem);
        total += vidro.porcentagem;
      });

    const outros = 100 - total;

    return {
      labels: outros > 0 && outros < 100 ? [...labels, "Outros"] : labels,
      series: outros > 0 && outros < 100 ? [...data, outros] : data,
    };
  }, [dashBoardData?.vidrosMaisCalculadosEmOrcamentos]);

  useEffect(() => {
    getDashBoardData(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData,
      selectedRegion ?? undefined
    );
  }, [triggerUpdateDashboard]);

  useEffect(() => {
    getDashBoardData(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData,
      selectedRegion ?? undefined,
      false
    );
  }, [selectedRegion]);

  return (
    <>
      <DashboardStackCustom>
        <Grid container mt={4} display={"flex"} alignItems={"center"}>
          {dashBoardData && !isLoading ? (
            <StackCustom>
              <Grid container>
                <Grid item xs={12}>
                  <GraficoHeatMap
                    porcentagemOrcamentosPorRegiao={
                      dashBoardData.porcentagemOrcamentosPorRegiao
                    }
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                  />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <GraficoColuna
                    yaxisLabel="N° de orçamentos/obras"
                    title="Quantidade de orçamentos/obras calculados com meus vidros por período"
                    series={[
                      {
                        name: String(
                          dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                            ? dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                                .anoAnterior
                            : parseInt(dayjs().format("YYYY")) - 1
                        ),
                        color: "#12629F",
                        data:
                          dashBoardData?.quantidadeOrcamentosPorPeriodo?.map(
                            (periodo) => {
                              return {
                                quantidade:
                                  periodo.quantidadeOrcamentosAnoAnterior,
                                mes: periodo.mesId,
                              };
                            }
                          ) ?? [],
                      },
                      {
                        name: String(
                          dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                            ? dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                                .anoAtual
                            : dayjs().format("YYYY")
                        ),
                        color: "#9F7812",
                        data:
                          dashBoardData?.quantidadeOrcamentosPorPeriodo?.map(
                            (periodo) => {
                              return {
                                quantidade:
                                  periodo.quantidadeOrcamentosAnoAtual,
                                mes: periodo.mesId,
                              };
                            }
                          ) ?? [],
                      },
                    ]}
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6.9} mt={3}>
                    <GraficoBarraEmpilhada
                      title="Tipos de vidros mais calculados nos orçamentos/obra por linha"
                      series={dadosTipoVidrosMaisCalculados.series}
                      categories={dadosTipoVidrosMaisCalculados.categories}
                    />
                  </Grid>
                  <Grid item xs={12} md={5.1} mt={3}>
                    <GraficoBarra
                      onClick={() => setIsModalVidrosMaisCalculadosOpen(true)}
                      title="Vidros mais calculados nos orçamentos/obras"
                      series={[
                        {
                          name: "%",
                          data: dadosVidrosMaisCalculados.series,
                        },
                      ]}
                      categories={dadosVidrosMaisCalculados.labels}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <ListaDashBoard
                    onClick={() => setIsModalClientesOpen(true)}
                    columns={[
                      {
                        field: "ranking",
                        headerName: "",
                        sortable: false,
                        flex: 0.1,
                      },
                      {
                        field: "nome",
                        headerName: "Empresas que mais orçaram",
                        sortable: false,
                        flex: 0.5,
                      },
                      {
                        field: "cpfCnpj",
                        headerName: "CNPJ/CPF",
                        sortable: false,
                        flex: 0.35,
                      },
                      {
                        field: "cidade",
                        headerName: "Cidade",
                        sortable: false,
                        flex: 0.25,
                      },
                      {
                        field: "estado",
                        headerName: "Estado",
                        sortable: false,
                        flex: 0.25,
                      },
                    ]}
                    title="Clientes que mais utilizaram meus vidros nos cálculos de orçamentos/obras"
                    dataRows={
                      dashBoardData?.clientesMaisUtilizaramProjetos
                        ?.slice(0, 5)
                        .map((cliente) => {
                          return {
                            ...cliente,
                            id: cliente.ranking,
                          };
                        })!
                    }
                  />
                </Grid>
              </Grid>
            </StackCustom>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </DashboardStackCustom>
      <ModalFiltroMesAno
        isOpen={modalDataFiltro.isOpen}
        onClose={() =>
          setModalDataFiltro({
            ...modalDataFiltro,
            isOpen: false,
          })
        }
        finalData={modalDataFiltro.finalData}
        inicialData={modalDataFiltro.inicialData}
        onSubmit={modalDataFiltro.onSubmit}
      />
      <ModalListaVidros
        isOpen={isModalVidrosMaisCalculadosOpen}
        onClose={() => setIsModalVidrosMaisCalculadosOpen(false)}
        title="Vidros mais calculados nos orçamentos/obras"
        data={
          dashBoardData?.vidrosMaisCalculadosEmOrcamentos?.map((vidro) => {
            return {
              codigo: vidro.codigo,
              porcentagemUso: vidro.porcentagem,
              descricao: vidro.descricao,
              linha: vidro.linhaMaterial,
            };
          }) ?? []
        }
      />
      <ModalClientes
        isOpen={isModalClientesOpen}
        onClose={() => setIsModalClientesOpen(false)}
        title="Clientes que mais utilizaram meus projetos"
        data={
          dashBoardData?.clientesMaisUtilizaramProjetos?.map((cliente) => {
            return {
              razaoSocial: cliente.nome,
              cnpj: cliente.cpfCnpj,
              estado: cliente.estado,
              cidade: cliente.cidade,
              porcentagemUso: cliente.porcentagemUso,
            };
          }) ?? []
        }
      />
    </>
  );
}

export default DashboardVidros;
