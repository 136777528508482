import { CheckCircle, CheckCircleOutline, Email } from "@mui/icons-material";
import { Grid, IconButton, Paper } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FloatMenuCrud } from "components/FloatMenuCrud";
import NavigationHeader from "components/NavigationHeader";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import { TitleTable } from "components/TitleTable";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { StripedDataGrid } from "components/StripedDataGrid";
import { useNavigation } from "contexts/notificationsContext";
import {
  deleteUsuario,
  EnviarDadosAcesso,
  getDadosPaginacao,
  getUsuariosEmpresa,
} from "services/modules/empresas/usuarios";
import { useResponsive } from "shared/hooks/useResponsive";
import {
  IGetUsuarioById,
  IUsuariosDaEmpresa,
} from "shared/interfaces/usuarios";
import { useUsuarios } from "./hooks/useUsuarios";
import { ModalCadastroUsuario } from "./modalCadastroUsuario";
import { debounce } from "lodash";
import { TIME_DEBOUNCE } from "hooks/usePagination";
import { FilterColumn } from "components/StripedDataGrid/FilterColumn";

export const SEARCH_BAR_PESSOA_ID = "search-bar-componente-anchor";

type HookParams = {
  onEdit(data: IGetUsuarioById): void;
  onRemove(id: number): void;
  onSendEmail(id: number): void;
};

export const UsuariosEmpresa = () => {
  const refNomeUsuario = useRef<HTMLInputElement>();
  const refEmailUsuario = useRef<HTMLInputElement>();
  const [idEmpresa, setIdEmpresa] = useState<any>();
  const { openNotification } = useNavigation();
  const params = useParams();
  const [modalDelete, setModalDelete] = useState<{
    isOpen: boolean;
    id?: number;
  }>({
    isOpen: false,
  });
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingDatas, setIsLoadingDatas] = useState(false);
  let activeRequests = 0;
  const [isOpenModalCadastroUsuario, setIsOpenModalCadastroUsuario] = useState<{
    isOpen: boolean;
    data?: IGetUsuarioById;
  }>({
    isOpen: false,
  });
  const [usuarios, setUsuarios] = useState<IUsuariosDaEmpresa[]>([]);
  const { isDesktop } = useResponsive();
  const [searchText, setSearchText] = useState("");
  const { control, ...methods } = useForm({
    mode: "onBlur",
  });
  const [usuariosFiltrados, setUsuariosFiltrados] = useState<
    IUsuariosDaEmpresa[]
  >([]);

  const [filterColumnsDebounced, setFilterColumnsDebounced] = useState<{
    column?: "nome" | "email";
    value?: string;
  }>();

  const debouncedFilterColumn = useCallback(
    debounce((column: "nome" | "email", value: string) => {
      setFilterColumnsDebounced({ column, value });
    }, TIME_DEBOUNCE),
    [setFilterColumnsDebounced]
  );

  const [totalItemsPagina, settotalItens] = useState<number>(0);
  const useClientesColumns = ({
    onEdit,
    onRemove,
    onSendEmail,
  }: HookParams) => {
    const columns: GridColDef[] = [
      {
        field: "nome",
        headerName: "Nome",
        flex: 0.5,
        renderHeader: () => {
          return (
            <FilterColumn
              inputRef={refNomeUsuario}
              label="Nome"
              name="nome"
              onChange={(event) => {
                if (refEmailUsuario.current) {
                  refEmailUsuario.current.value = "";
                }
                debouncedFilterColumn("nome", event.target.value);
              }}
            />
          );
        },
      },
      {
        field: "email",
        headerName: "E-mail",
        flex: 0.5,
        renderHeader: () => {
          return (
            <FilterColumn
              inputRef={refEmailUsuario}
              label="E-mail"
              name="email"
              onChange={(event) => {
                if (refNomeUsuario.current) {
                  refNomeUsuario.current.value = "";
                }
                debouncedFilterColumn("email", event.target.value);
              }}
            />
          );
        },
      },
      {
        field: "ativo",
        headerName: "Usuário(s) ativos",
        sortable: false,
        flex: 0.12,
        renderCell: (params) => {
          return params.row.ativo ? (
            <CheckCircle
              color="success"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          ) : (
            <CheckCircleOutline
              color="disabled"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          );
        },
      },
      {
        field: "options",
        sortable: false,
        headerName: "",
        align: "center",
        flex: 0.1,
        renderCell: (params: any) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FloatMenuCrud
                onEdit={() => onEdit(params.row as IGetUsuarioById)}
                onDelete={() => onRemove(params.id as number)}
                showDuplicateOption={false}
                deleteButtonText="Excluir"
              />
              <IconButton
                onClick={() => onSendEmail(params.row.idUsuario)}
                title="Enviar Email"
              >
                <Email />
              </IconButton>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  const {
    pagination,
    totalItems,
    isLoadingData,
    handlePagination,
    handleUpdatePage,
    getPessoasData,
  } = useUsuarios();

  const columns = useClientesColumns({
    onEdit: (data) => {
      setIsOpenModalCadastroUsuario({
        isOpen: true,
        data,
      });
    },
    onRemove: (id) =>
      setModalDelete({
        isOpen: true,
        id: Number(id),
      }),
    onSendEmail: (id: number) => handleSendEmail(id),
  });

  const filteredUsuarios = usuarios.filter((usuario) =>
    usuario.nome.toLowerCase().includes(searchText.toLowerCase())
  );

  const getUsuariosData = async (params?: any, pagination? : any) => {
    setIsLoadingDatas(true);

    const totalItemsPagina= await getDadosPaginacao(params, pagination)
    settotalItens(totalItemsPagina)
    try {
      const result = await getUsuariosEmpresa(params, pagination);
      if (result) {
        const users = (result || [])?.map((usuario) => ({
          idEmpresa: params.id,
          nome: usuario?.nome,
          idUsuario: usuario?.idUsuario,
          email: usuario?.email,
          ativo: usuario?.ativo,
        }));
        users.sort((a, b) => a.nome.localeCompare(b.nome));
        setUsuarios(users);
        return;
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Não foi possivel carregar os usuários",
        type: "error",
      });
    } finally {
      if (activeRequests === 0) {
        setIsLoadingDatas(false);
      }
    }
    setUsuarios([]);
  };

  const handleSendEmail = async (idUsuario: number) => {
    try {
      const result = await EnviarDadosAcesso(idUsuario);
      if (result) {
        openNotification({
          isOpen: true,
          message: "Dados de acesso enviados com sucesso!",
          type: "success",
        });
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Erro ao enviar dados de acesso.",
        type: "error",
      });
    }
  };

  async function handleDeleteUsuario(id?: number) {
    if (!id) return;
    setIsLoadingDelete(true);
    try {
      const result = await deleteUsuario(id);
      if (result) {
        openNotification({
          isOpen: true,
          message: "Usuário foi excluido com sucesso!",
          type: "success",
        });
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }

  useEffect(() => {
    if (filterColumnsDebounced) {
      const paginacao = {
        page: 1,
        limit:  10,
        sortBy:  "",
        direction: pagination?.direction || "asc",
        filtrar: filterColumnsDebounced.value || ""
      };
      getUsuariosData(params, paginacao);

      setUsuariosFiltrados(() => {
        const newEmpresas = usuarios.filter((usu) => {
          switch (filterColumnsDebounced.column) {
            case "nome":
              return usu.nome
                .toLowerCase()
                .includes(filterColumnsDebounced.value?.toLowerCase() ?? "");
            case "email":
              return usu.email
                .toLowerCase()
                .includes(filterColumnsDebounced.value?.toLowerCase() ?? "");
          }
        });
        return newEmpresas.length > 0 ? newEmpresas : [];
      });
    }
    else{
      const paginacao = {
        page: 1,
        limit:  10,
        sortBy:  "",
        direction: pagination?.direction || "asc",
        filtrar: ""
      };
      getUsuariosData(params, paginacao);
    }
  }, [filterColumnsDebounced]);

  useEffect(() => {
    if (!isOpenModalCadastroUsuario.isOpen) {
      getUsuariosData(params, pagination);
    }
  }, [isOpenModalCadastroUsuario.isOpen, params, pagination]);

  return (
    <FormProvider
      {...{
        control,
        ...methods,
      }}
    >
      <DashboardStackCustom>
        <NavigationHeader
          title={"Usuários da Empresa"}
          label={"Usuários da Empresa"}
          buttonLabel={"Cadastrar"}
          buttonOnClick={() => {
            setIsOpenModalCadastroUsuario({ isOpen: true });
          }}
          hasBackButton={false}
        />
        <Grid container display={"flex"} alignItems={"center"}>
          <StackCustom>
            <Grid container mb={4}>
              <Grid item xs={12} sm={8} md={12}>
                <TitleTable title="Listagem de usuários da empresa" />
              </Grid>
            </Grid>
            <Paper>
              <StripedDataGrid
                rows={usuarios}
                columns={columns}
                getRowId={(row: IUsuariosDaEmpresa) => row.idUsuario}
                onClickDelete={(ids) => null}
                page={pagination.page - 1}
                pageSize={pagination.pageSize}
                rowCount={totalItemsPagina}
                loading={isLoadingData || isLoadingDelete}
                onPageChange={(newPage) =>
                  handlePagination({ page: newPage + 1 })
                }
                onPageSizeChange={(newPageSize) => {
                  handlePagination({
                    pageSize: newPageSize
                    //page: 1,
                  });
                }}
                hideDeleteButton
                checkboxSelection={false}
                rowHeight={70}
                onChangeSingleColumnOrder={(value) => {
                  const sortBy = value.sortBy.replace(/-([a-z])/g, (g) =>
                    g[1].toUpperCase()
                  );

                  handlePagination({
                    orderBy: sortBy,
                    direction: value.direction,
                    page: 1,
                  });
                }}
                sx={{ display: !isDesktop ? "none" : "inherit" }}
              />
            </Paper>
          </StackCustom>
        </Grid>
      </DashboardStackCustom>
      <ModalCadastroUsuario
        isOpen={isOpenModalCadastroUsuario.isOpen}
        onClose={() => setIsOpenModalCadastroUsuario({ isOpen: false })}
        dataUsuario={isOpenModalCadastroUsuario.data}
      />
      <ConfirmDeleteModal
        title="Tem certeza que deseja excluir este Usuário?"
        description="Essa operação não pode ser desfeita."
        open={modalDelete.isOpen}
        onClose={() => setModalDelete({ isOpen: false })}
        onConfirm={() => {
          handleDeleteUsuario(modalDelete.id);
          setModalDelete({ isOpen: false });
        }}
      />
    </FormProvider>
  );
};
