import {
  Check,
  CheckCircle,
  CheckCircleOutline,
  ChevronRight,
  DeleteForever,
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { FloatMenuCrud } from "components/FloatMenuCrud";
import NavigationHeader from "components/NavigationHeader";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import { StripedDataGrid } from "components/StripedDataGrid";
import { FilterColumn } from "components/StripedDataGrid/FilterColumn";
import { TitleTable } from "components/TitleTable";
import { useNavigation } from "contexts/notificationsContext";
import { TIME_DEBOUNCE } from "hooks/usePagination";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  deletePerfisDeEmpresa,
  getDadosPaginacao,
  getPerfisDeEmpresa,
  postDesativarAtivarPerfilEmpresa,
} from "services/modules/perfisDeEmpresa";
import { useResponsive } from "shared/hooks/useResponsive";
import {
  IGetPerfisDeEmpresa,
  IPerfisDeEmpresa,
} from "shared/interfaces/perfisDeEmpresa";
import { usePerfisDeEmpresa } from "./hooks/usePessoa";
import { ModalPerfisDeEmpresa } from "./modalCadastroPerfis";
import { LoadingButton } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import { TokenContext } from "contexts/tokenContext";

export const SEARCH_BAR_PESSOA_ID = "search-bar-componente-anchor";

export type TipoStatusPerfilUsuario = "ATIVAR" | "DESATIVAR";

type HookParams = {
  onEdit(data: IGetPerfisDeEmpresa): void;
  onRemove(id: number): void;
  onDisabled(id: number, tipoStatus: TipoStatusPerfilUsuario): void;
};

interface PerfilDeEmpresaProps {
  refreshData?: () => void;
}

export const PerfisDeEmpresa = ({ refreshData }: PerfilDeEmpresaProps) => {
  const refPerfilEmpresa = useRef<HTMLInputElement>();
  const { openNotification } = useNavigation();
  const [modalDelete, setModalDelete] = useState<{
    isOpen: boolean;
    id?: number;
  }>({
    isOpen: false,
  });
  const [modalDisabled, setModalDisabled] = useState<{
    isOpen: boolean;
    id?: number;
    tipoStatus?: TipoStatusPerfilUsuario;
  }>({
    isOpen: false,
  });
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingDatas, setIsLoadingDatas] = useState(false);
  let activeRequests = 0;
  const [isOpenModalCadastroPerfis, setIsOpenModalCadastroPerfis] = useState<{
    isOpen: boolean;
    data?: IGetPerfisDeEmpresa;
  }>({
    isOpen: false,
  });
  const [perfisDeEmpresa, setPerfisDeEmpresa] = useState<IPerfisDeEmpresa[]>(
    []
  );

  const { token } = useContext(TokenContext);

  const [isAtivo, setIsAtivo] = useState(false);
  const [perfisDeEmpresasFiltradas, setPerfisDeEmpresasFiltradas] = useState<
    IPerfisDeEmpresa[]
  >([]);

  const { isDesktop } = useResponsive();
  const [totalItemsPagina, settotalItens] = useState<number>(0);
  const { control, ...methods } = useForm({
    mode: "onBlur",
  });

  const { pagination, totalItems, isLoadingData, handlePagination } =
    usePerfisDeEmpresa();

  const [filterColumnsDebounced, setFilterColumnsDebounced] = useState<{
    column?: "nomePerfil";
    value?: string;
  }>();

  const debouncedFilterColumn = useCallback(
    debounce((column: "nomePerfil", value: string) => {
      setFilterColumnsDebounced({ column, value });
    }, TIME_DEBOUNCE),
    [setFilterColumnsDebounced]
  );

  const usePerfisDeEmpresaColumns = ({
    onEdit,
    onRemove,
    onDisabled,
  }: HookParams) => {
    const columns: GridColDef[] = [
      {
        field: "nomePerfil",
        headerName: "Perfil",
        flex: 1,
        renderHeader: () => {
          return (
            <FilterColumn
              inputRef={refPerfilEmpresa}
              label="Perfil"
              name="nomePerfil"
              onChange={(event) => {
                debouncedFilterColumn("nomePerfil", event.target.value);
              }}
            />
          );
        },
      },
      {
        field: "ativo",
        headerName: "Perfis ativos",
        sortable: false,
        flex: 0.12,
        renderCell: (params) => {
          return params.row.ativo ? (
            <CheckCircle
              color="success"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          ) : (
            <CheckCircleOutline
              color="disabled"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          );
        },
      },
      {
        field: "options",
        sortable: false,
        headerName: "",
        align: "center",
        flex: 0.1,
        renderCell: (params: any) => {
          return (
            <FloatMenuCrud
              onEdit={() => onEdit(params.row as IGetPerfisDeEmpresa)}
              showDuplicateOption={false}
              deleteButtonText="Excluir"
              onDelete={() => onRemove(params.id as number)}
              otherOptions={[
                {
                  icon: <ChevronRight />,
                  label: params.row.ativo ? "DESATIVAR" : "ATIVAR",
                  onClickButton: () =>
                    onDisabled(
                      params.id as number,
                      params.row.ativo ? "DESATIVAR" : "ATIVAR"
                    ),
                  color: params.row.ativo ? "error" : "success",
                },
              ]}
            />
          );
        },
      },
    ];
    return columns;
  };

  const columns = usePerfisDeEmpresaColumns({
    onEdit: (data) => {
      setIsOpenModalCadastroPerfis({
        isOpen: true,
        data,
      });
    },
    onRemove: (id) =>
      setModalDelete({
        isOpen: true,
        id: Number(id),
      }),
    onDisabled: (id, tipoStatus) =>
      setModalDisabled({
        isOpen: true,
        id: Number(id),
        tipoStatus,
      }),
  });

  const getPerfisDeEmpresaData = async (params?: any) => {
    setIsLoadingDatas(true);
    try {
      const result = await getPerfisDeEmpresa(params);
      const totalItemsPagina = await getDadosPaginacao(params);
      if (result) {
        setPerfisDeEmpresa(result);
        settotalItens(totalItemsPagina);
        return;
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Não foi possivel carregar os Perfis de Empresa",
        type: "error",
      });
    } finally {
      if (activeRequests === 0) {
        setIsLoadingDatas(false);
      }
    }
    setPerfisDeEmpresa([]);
  };

  async function handleDeletePerfisDeEmpresa(id?: number) {
    if (!id) return;
    setIsLoadingDelete(true);
    try {
      const result = await deletePerfisDeEmpresa(id);
      if (result) {
        openNotification({
          isOpen: true,
          message: "Perfil de Empresa foi excluido com sucesso!",
          type: "success",
        });

        await getPerfisDeEmpresaData();
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }

  async function handleDisabledPerfilEmpresa(
    id?: number,
    status?: TipoStatusPerfilUsuario
  ) {
    if (!id) return;
    const isAtivo = status === "ATIVAR" ? true : false;

    try {
      const response = await postDesativarAtivarPerfilEmpresa(id, isAtivo);
      if (response) {
        setIsAtivo(isAtivo);
        openNotification({
          isOpen: true,
          message: `Perfil de Empresa foi ${
            isAtivo ? "ativado" : "desativado"
          } com sucesso!`,
          type: "success",
        });
        await getPerfisDeEmpresaData();
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  useEffect(() => {
    if (token) {
      if (filterColumnsDebounced) {
        const paginacao = {
          page: 1,
          limit: 10,
          sortBy: "",
          direction: pagination?.direction || "asc",
          filtrar: filterColumnsDebounced.value || "",
        };
        handlePagination(paginacao);
        getPerfisDeEmpresaData(paginacao);

        setPerfisDeEmpresasFiltradas(() => {
          const newPerfilEmpresas = perfisDeEmpresa.filter((perfil) => {
            switch (filterColumnsDebounced.column) {
              case "nomePerfil":
                return perfil.nomePerfil
                  .toLowerCase()
                  .includes(filterColumnsDebounced.value?.toLowerCase() ?? "");
            }
          });
          if (newPerfilEmpresas.length == 0) {
            return [];
          }
          return newPerfilEmpresas;
        });
      } else {
        getPerfisDeEmpresaData(pagination);
      }
    }
  }, [filterColumnsDebounced, token]);

  useEffect(() => {
    if (token) getPerfisDeEmpresaData(pagination);
  }, [pagination, token]);

  return (
    <FormProvider
      {...{
        control,
        ...methods,
      }}
    >
      <DashboardStackCustom>
        <NavigationHeader
          title={"PERFIS DE EMPRESAS"}
          label={"Perfis de Empresas"}
          buttonLabel={"Cadastrar"}
          buttonOnClick={() => {
            setIsOpenModalCadastroPerfis({ isOpen: true });
          }}
          hasBackButton={false}
        />
        <Grid container display={"flex"} alignItems={"center"}>
          <StackCustom>
            <Grid container mb={4}>
              <Grid item xs={12} sm={8} md={12}>
                <TitleTable title="Listagem de perfis de empresas" />
              </Grid>
            </Grid>
            <Paper>
              <StripedDataGrid
                rows={perfisDeEmpresa}
                columns={columns}
                getRowId={(row: IPerfisDeEmpresa) => row.idPerfil}
                onClickDelete={(ids) => null}
                page={pagination.page - 1}
                pageSize={pagination.pageSize}
                rowCount={totalItemsPagina}
                loading={isLoadingData || isLoadingDelete}
                onPageChange={(newPage) =>
                  handlePagination({ page: newPage + 1 })
                }
                onPageSizeChange={(newPageSize) => {
                  handlePagination({
                    pageSize: newPageSize,
                    page: 1,
                  });
                }}
                checkboxSelection={false}
                rowHeight={70}
                onChangeSingleColumnOrder={(value) => {
                  const sortBy = value.sortBy.replace(/-([a-z])/g, (g) =>
                    g[1].toUpperCase()
                  );

                  handlePagination({
                    orderBy: sortBy,
                    direction: value.direction,
                    page: 1,
                  });
                }}
                sx={{ display: !isDesktop ? "none" : "inherit" }}
              />
            </Paper>
          </StackCustom>
        </Grid>
      </DashboardStackCustom>
      <ModalPerfisDeEmpresa
        isOpen={isOpenModalCadastroPerfis.isOpen}
        onClose={() => setIsOpenModalCadastroPerfis({ isOpen: false })}
        refreshData={() => {
          getPerfisDeEmpresaData();
        }}
        dataPerfisDeEmpresa={isOpenModalCadastroPerfis.data}
      />
      <ConfirmDeleteModal
        title="Tem certeza que deseja excluir o Perfil de Empresa?"
        description="Essa operação não pode ser desfeita."
        open={modalDelete.isOpen}
        onClose={() => setModalDelete({ isOpen: false })}
        onConfirm={() => {
          handleDeletePerfisDeEmpresa(modalDelete.id)
            .then(() => {
              return getPerfisDeEmpresaData();
            })
            .finally(() => {
              setModalDelete({ isOpen: false });
            });
        }}
      />
      <ConfirmDeleteModal
        title={
          modalDisabled.tipoStatus === "DESATIVAR"
            ? "Tem certeza que deseja desativar esse Perfil de empresa?"
            : "Tem certeza que deseja ativar esse Perfil de empresa?"
        }
        description=""
        open={modalDisabled.isOpen}
        onClose={() => setModalDisabled({ isOpen: false })}
        onConfirm={() => {
          handleDisabledPerfilEmpresa(
            modalDisabled.id,
            modalDisabled.tipoStatus
          )
            .then(() => {
              return getPerfisDeEmpresaData();
            })
            .finally(() => {
              setModalDisabled({ isOpen: false });
            });
        }}
        buttonTextDelete={
          modalDisabled.tipoStatus === "DESATIVAR" ? "DESATIVAR" : "ATIVAR"
        }
        customButtonConfirm={
          <LoadingButton
            variant="contained"
            size="medium"
            color={
              modalDisabled.tipoStatus === "DESATIVAR" ? "error" : "success"
            }
            endIcon={
              modalDisabled.tipoStatus === "DESATIVAR" ? (
                <ClearIcon />
              ) : (
                <Check />
              )
            }
            sx={{ color: "white" }}
            onClick={() => {
              handleDisabledPerfilEmpresa(
                modalDisabled.id,
                modalDisabled.tipoStatus
              )
                .then(() => {
                  return getPerfisDeEmpresaData();
                })
                .finally(() => {
                  setModalDisabled({ isOpen: false });
                });
            }}
            loading={isLoadingDelete}
            loadingPosition="end"
          >
            {modalDisabled.tipoStatus === "DESATIVAR" ? "DESATIVAR" : "ATIVAR"}
          </LoadingButton>
        }
      />
    </FormProvider>
  );
};
