import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: {
    codigo: string;
    descricao: string;
    porcentagem: number;
    grupo?: string;
  }[];
}

export function ModalListaProdutos({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "codigo",
          headerName: "Código",
          sortable: false,
          flex: 0.1,
        },
        {
          field: "descricao",
          headerName: "Descrição",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "porcentagem",
          headerName: "Porcentagem",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "grupo",
          headerName: "Grupo",
          sortable: false,
          flex: 0.2,
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
