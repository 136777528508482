const ErrorIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1611_98346"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="15"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00008 2.6665C4.78008 2.6665 2.16675 5.27984 2.16675 8.49984C2.16675 11.7198 4.78008 14.3332 8.00008 14.3332C11.2201 14.3332 13.8334 11.7198 13.8334 8.49984C13.8334 5.27984 11.2201 2.6665 8.00008 2.6665ZM8.58342 11.4165H7.41675V10.2498H8.58342V11.4165ZM8.58342 9.08317H7.41675V5.58317H8.58342V9.08317Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1611_98346)">
        <rect y="0.5" width="16" height="16" fill="#D63657" />
      </g>
    </svg>
  );
};

export default ErrorIcon;
