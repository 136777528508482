import { api } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  IAbasPerfisDeEmpresa,
  IApiResponse,
  IGetPerfisDeEmpresaById,
  IPerfisDeEmpresa,
  IPostPerfisEmpresa,
} from "shared/interfaces/perfisDeEmpresa";
import { FSelect } from "shared/interfaces/select";

export const getPerfisDeEmpresa = async (params?: any) => {
  try {
    const { data } = await api.get<IApiResponse>("/Perfil/ListarPerfis", {
      params: {
        sortBy: params?.sortBy,
        page: params?.page,
        limit: params?.pageSize,
        maxRecords: params?.totalItem,
        direction: params?.direction,
        Filtrar: params?.filtrar || ""
      },

    });
    const sortedData = data.items.sort((a, b) =>
      a.nomePerfil.localeCompare(b.nomePerfil)
    );

    return sortedData;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Perfis de Empresas";

    getErrorsByApi(error, errorMessage);
  }
};
export const getDadosPaginacao = async (params?: any) => {
  try {
    const { data } = await api.get<IApiResponse>("/Perfil/ListarPerfis", {
      params: {
        sortBy: params?.sortBy,
        page: params?.page,
        limit: params?.pageSize,
        maxRecords: params?.totalItem,
        direction: params?.direction,
        Filtrar: params?.search
      },

    });
    return data.totalRecords
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.errors?.Mensagens?.[0] ||
      "Tivemos problemas internos ao buscar as Empresas";

    getErrorsByApi(error, errorMessage);

    return 0;
  }
};
export const getPerfisDeEmpresaAtivos = async (params?: any) => {
  try {
    const { data } = await api.get<IPerfisDeEmpresa[]>(
      "/Perfil/ListarPerfisAtivoInativo?ativo=true",
      {
        params,
      }
    );
    const sortedData = data.sort((a, b) =>
      a.nomePerfil.localeCompare(b.nomePerfil)
    );

    return sortedData;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Perfis de Empresas";

    getErrorsByApi(error, errorMessage);
  }
};

export const getAbasPerfisDeEmpresa = async (params?: any) => {
  try {
    const response = await api
      .get<IAbasPerfisDeEmpresa[]>(`/Perfil/ListarPerfilAbas`, {
        params,
      })
      .then((res) => res.data);
    const data: FSelect[] = response.map((item) => ({
      value: item.id,
      label: item.nome,
    }));
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Perfis de Empresas";

    getErrorsByApi(error, errorMessage);
  }
};

export async function getPerfisDeEmpresaById(idPerfil: number) {
  if (!idPerfil) throw new Error("Id da Empresa não informado");
  try {
    const response: ApiResponse<IGetPerfisDeEmpresaById> = await api.get(
      `/Perfil/ObterPerfilPorId?idPerfil=${idPerfil}`
    );
    return response;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Perfis de Empresas";

    getErrorsByApi(error, errorMessage);
  }

  return null;
}

export const postPerfisDeEmpresa = async (params: IPostPerfisEmpresa) => {
  try {
    const data = await api
      .post("/Perfil/SalvarPerfil", params)
      .then((res) => res.data);
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao salvar o Perfil de Empresa";

    getErrorsByApi(error, errorMessage);
  }
};

export const deletePerfisDeEmpresa = async (idPerfil: number) => {
  if (!idPerfil) throw new Error("Id do Perfil não informado");
  try {
    const response = await api.delete(
      `/Perfil/ExcluirPerfil?idPerfil=${idPerfil}`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao deletar esse Perfil";

    getErrorsByApi(error, errorMessage);
  }
  return null;
};

export const postDesativarAtivarPerfilEmpresa = async (
  idPerfil: number,
  ativarDesativar: boolean
) => {
  if (!idPerfil) throw new Error("Id do Perfil não informado");
  try {
    const response = await api.post(
      `/Perfil/AtivarDesativar?idPerfil=${idPerfil}&ativar=${ativarDesativar}`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos com esse Perfil";

    getErrorsByApi(error, errorMessage);
  }
  return null;
};
