import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Divider,
	IconButton,
	Stack,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ModalUnsavedEditProps {
	description: string;
	isOpen: boolean;
	onClose: () => void;
	onYes: () => void;
	onNot: () => void;
	onCancel?: () => void;
	title?: string;
	type?: boolean;
	isAlert?: boolean;
	sx?: SxProps<Theme>;
}

export const ModalUnsavedEdit: React.FC<ModalUnsavedEditProps> = ({
	description,
	isOpen,
	onClose,
	onYes,
	onNot,
	onCancel,
	isAlert = false,
	title,
	type,
	sx,
}: ModalUnsavedEditProps) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-describedby="unsaved-profiles-dialog"
			sx={sx}
		>
			<DialogContent>
				{title && (
					<>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="body2" align="left">
								{title}
							</Typography>
							<IconButton aria-label="delete" onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Stack>
						{!isAlert && <Divider sx={{ marginBottom: "1rem" }} />}
					</>
				)}
				<DialogContentText
					id="unsaved-profiles-dialog"
					sx={{ lineHeight: 1.75, color: "black" }}
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onNot}>Não</Button>
				<Button onClick={onYes} variant="contained">
					Sim
				</Button>
				{!type && <Button onClick={onCancel}>Cancelar</Button>}
			</DialogActions>
		</Dialog>
	);
};
