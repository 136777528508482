import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import NavigationHeader from "components/NavigationHeader";
import { PreventExit } from "components/PreventExit";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import { TabPanel } from "components/TabPanel";
import { useNavigation } from "contexts/notificationsContext";
import { usePreventExit } from "hooks/usePreventExit";
import { useSearchZipCode } from "hooks/useSearchZipCode";
import { useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getEmpresaById } from "services/modules/empresas";
import { IEmpresa } from "shared/interfaces/empresa";
import { TabsEmpresa } from "./components/tabs";
import { DadosCadastrais } from "./dadosCadastrais";
import { DashboardForm } from "./dashboard";
import { CadastrosN1Form } from "./cadastrosN";
import { AplicabilidadesForm } from "./aplicabilidades";
import { UsuariosEmpresa } from "../Usuarios";
import { TabelaPrecosForm } from "./tabelaPrecos";

export const CadastroDeEmpresas = () => {
  const { openNotification } = useNavigation();
  const params = useParams();
  const { fillCidadesByEstadoId } = useSearchZipCode();
  const [tabValue, setTabValue] = useState(0);
  const [isVisibleBackdrop, setIsVisibleBackdrop] = useState(false);
  const [abasUtilizadas, setAbasUtilizadas] = useState<number[]>([]);
  const [abasAdicionaisUtilizadas, setAbasAdicionaisUtilizadas] = useState<
    number[]
  >([]);

  const [empresaAtiva, setEmpresaAtiva] = useState(false);

  const tabs = useMemo(() => {
    return [
      {
        label: "DADOS CADASTRAIS",
        element: (
          <DadosCadastrais
            setAbasAdicionaisUtilizadas={setAbasAdicionaisUtilizadas}
            setAbasUtilizadas={setAbasUtilizadas}
            setEmpresaAtiva={setEmpresaAtiva}
          />
        ),
        field: "dados",
        id: 0,
      },
      {
        label: "DASHBOARD",
        element: <DashboardForm />,
        field: "dashboard",
        isDisabled:
          !params.id ||
          (!abasUtilizadas.includes(1) &&
            !abasAdicionaisUtilizadas.includes(1)) ||
          !empresaAtiva,
        id: 1,
      },
      {
        label: "CADASTROS DE N1",
        element: <CadastrosN1Form />,
        field: "cadastro",
        isDisabled:
          !params.id ||
          (!abasUtilizadas.includes(2) &&
            !abasAdicionaisUtilizadas.includes(2)) ||
          !empresaAtiva,
        id: 2,
      },
      {
        label: "APLICABILIDADE",
        element: <AplicabilidadesForm />,
        field: "aplicabilidade",
        isDisabled:
          !params.id ||
          (!abasUtilizadas.includes(3) &&
            !abasAdicionaisUtilizadas.includes(3)) ||
          !empresaAtiva,
        id: 3,
      },
      {
        label: "TABELAS DE PREÇOS",
        element: <TabelaPrecosForm />,
        field: "tabelas",
        isDisabled:
          !params.id ||
          (!abasUtilizadas.includes(4) &&
            !abasAdicionaisUtilizadas.includes(4)) ||
          !empresaAtiva,
        id: 4,
      },
      {
        label: "USUÁRIOS",
        element: <UsuariosEmpresa />,
        field: "usuarios",
        isDisabled:
          !params.id ||
          (!abasUtilizadas.includes(5) &&
            !abasAdicionaisUtilizadas.includes(5)) ||
          !empresaAtiva,
        id: 5,
      },
    ];
  }, [abasUtilizadas, abasAdicionaisUtilizadas, empresaAtiva]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    getValues,
    clearErrors,
    formState,
    ...methods
  } = useForm({
    mode: "onBlur",
  });

  const { errors, isDirty, dirtyFields } = useFormState({ control });

  const {
    isOpenModalUnsaved,
    handleIsOpenModalUnsaved,
    handleLinkNavigateBlocked,
    handleOnCloseModalUnsavedEdit,
    linkNavigateBlocked,
    isGoBack,
    goBack,
  } = usePreventExit(isDirty && Object.values(dirtyFields).length > 0);

  const hasErrors = (field: string): boolean => {
    const key = field as keyof IEmpresa;
    return !!Object.keys(errors[key] ?? {})?.length;
  };

  const hasErrorInTab = (): boolean => {
    const field = tabs.find((_, index) => index === tabValue)?.field ?? "";
    return hasErrors(field);
  };

  async function getEmpresas(idPessoa: number) {
    try {
      const result = await getEmpresaById(idPessoa);
      if (result) {
        const abasUtilizadasPerfil = result.data.abasUtilizadasPerfil.map(
          (aba: any) => aba.id
        );
        setEmpresaAtiva(result.data.ativo);
        const abasUtilizadasAdicionais =
          result.data.abasUtilizadasRecursosAdicionais.map(
            (aba: any) => aba.id
          );

        setAbasAdicionaisUtilizadas([...abasUtilizadasAdicionais]);
        setAbasUtilizadas([
          ...abasUtilizadasPerfil,
          ...abasUtilizadasAdicionais,
        ]);

        if (result.data.idEstado) {
          await fillCidadesByEstadoId(result.data.idEstado);
        }
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  useEffect(() => {
    params.id && getEmpresas(Number(params.id));
  }, [params.id]);

  return (
    <>
      <PreventExit
        handleIsOpenModalUnsaved={handleIsOpenModalUnsaved}
        handleLinkNavigateBlocked={handleLinkNavigateBlocked}
        isOpenModalUnsaved={isOpenModalUnsaved}
        handleOnCloseModalUnsavedEdit={handleOnCloseModalUnsavedEdit}
        linkNavigateBlocked={linkNavigateBlocked}
        isGoBack={isGoBack}
      >
        <DashboardStackCustom>
          <NavigationHeader
            title={params.id ? "Edição de Empresa" : "Cadastrar Empresa"}
            label={params.id ? "Editar Empresa" : "Cadastrar Empresa"}
            links={[
              {
                label: "Empresas",
                link: "/empresasMof",
              },
            ]}
            goBack={() =>
              goBack(
                isDirty && Object.values(dirtyFields).length > 0,
                "/empresasMof"
              )
            }
          />
          <Grid container display={"flex"} alignItems={"center"} mb={3}>
            <TabsEmpresa
              tabValue={tabValue}
              handleChange={(_, value) => setTabValue(value)}
              hasErrorInTab={hasErrorInTab()}
              hasErrors={hasErrors}
              tabs={tabs.filter((tab) => !tab.isDisabled)}
            />
          </Grid>
          <Grid container display={"flex"} alignItems={"center"}>
            <StackCustom>
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isVisibleBackdrop}
              >
                <CircularProgress sx={{ color: "common.white" }} />
              </Backdrop>

              {tabs
                .filter((tab) => !tab.isDisabled)
                .map(({ element, id }, index: number) => (
                  <TabPanel key={id} value={tabValue} index={index}>
                    {element}
                  </TabPanel>
                ))}
            </StackCustom>
          </Grid>
        </DashboardStackCustom>
      </PreventExit>
    </>
  );
};
