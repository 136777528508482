import { AlertColor } from "@mui/material";
import * as React from "react";
import { useCallback } from "react";

import { createContext, useContextSelector } from "use-context-selector";

export type NotificationsProviderProps = {
  children: React.ReactNode;
};

export type OpenNotification = {
  type?: AlertColor;
  message?: string;
  isOpen?: boolean;
};

export type NotificationsValue = {
  openNotification: ({ message, type }: OpenNotification) => void;
  closeNotification: () => void;
  isOpen: boolean;
  message: string;
  type: AlertColor;
};

export const NotificationsContext = createContext({} as NotificationsValue);

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState<AlertColor>("info");

  const openNotification = useCallback(
    ({ message, type }: OpenNotification) => {
      setIsOpen(true);
      setMessage(message ?? "Ocorreu um erro inesperado.");
      setType(type ?? "info");
    },
    []
  );

  const closeNotification = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <NotificationsContext.Provider
      value={{ openNotification, closeNotification, isOpen, message, type }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNavigation = (): NotificationsValue => {
  const openNotification = useContextSelector(
    NotificationsContext,
    (notification) => notification.openNotification
  );
  const closeNotification = useContextSelector(
    NotificationsContext,
    (notification) => notification.closeNotification
  );
  const isOpen = useContextSelector(
    NotificationsContext,
    (notification) => notification.isOpen
  );
  const message = useContextSelector(
    NotificationsContext,
    (notification) => notification.message
  );
  const type = useContextSelector(
    NotificationsContext,
    (notification) => notification.type
  );

  return {
    openNotification,
    closeNotification,
    isOpen,
    message,
    type,
  };
};
