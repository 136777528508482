import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { fontsSizes } from "styles/theme";

interface ContainerGraficoProps {
  children: ReactNode;
  title: string;
  onClick?: () => void;
}

export function ContainerGrafico({
  children,
  title,
  onClick,
}: ContainerGraficoProps) {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #00000029",
        borderRadius: 1,
        height: "100%",
        width: "100%",
        py: 1,
        px: 2,
        minHeight: 315,
        maxHeight: 530,
        boxShadow:
          "0px 12px 17px rgba(0, 0, 0, 0.04), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
        <Typography
          color={"primary.main"}
          fontSize={fontsSizes.large}
          fontWeight={700}
          mb={1}
        >
          {title}
        </Typography>
        {onClick && (
          <Button onClick={onClick} variant="outlined">
            VER MAIS
          </Button>
        )}
      </Stack>
      {children}
    </Box>
  );
}
