import criptoJS from "crypto-js";
import { setAuthorization } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import { api } from "services/axios/api";
import configs from "Config";

export async function getTokenFromUrl() {
    const queryParams = new URLSearchParams(window.location.search);
    const hashId = queryParams.get("hashId");

    if (hashId == null) {
        return;
    }

    const tokenEnv = configs.REACT_APP_API_KEY || "";

    const decodedCriptoValue = atob(hashId);
    const decryptedBytes = criptoJS.AES.decrypt(decodedCriptoValue, tokenEnv);
    const decriptoHashId = decryptedBytes.toString(criptoJS.enc.Utf8);

    if (decriptoHashId == null) {
        return;
    }

    const jwtToken = await getTokenViaHashId(decriptoHashId) ?? "";

    sessionStorage.setItem("@MOF:token", jwtToken);
    setAuthorization(jwtToken);
};

async function getTokenViaHashId(hashId: string) {
    try {
        const response: ApiResponse<string> = await api.get(
        `/Autenticacao/ObterTokenViaHashId?hashId=${hashId}`
        );
        
        return response.data;
    } catch (error: any) {

        const errorMessage =
        error.data.errors.Mensagens[0] ||
        "Tivemos problemas internos ao obter JwtToken via hashId";

        getErrorsByApi(error, errorMessage);
    }
}