import { jwtDecode } from "jwt-decode";
import { createContext, ReactNode, useContext, useState } from "react";

import { api } from "services/axios/api";
import {
  ILogin,
  ITrocaLogin,
  IUsuario,
} from "shared/interfaces/signInInterfaces";
import * as auth from "../../services/auth";

interface AuthContextData {
  signed: boolean;
  user: IUsuario | null;
  signIn(login: ILogin): Promise<void>;
  signInChange(login: ITrocaLogin): Promise<void>;
  signOut(): void;
  havePermission(permissions: string[]): boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const storagedUser = sessionStorage.getItem("@MOF:user");
  const storagedToken = sessionStorage.getItem("@MOF:token");
  let localUser: IUsuario | null = null;

  if (storagedToken && storagedUser) {
    localUser = JSON.parse(storagedUser) as IUsuario;
    api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
  }

  const [user, setUser] = useState<IUsuario | null>(localUser);

  async function signIn(login: ILogin) {
    const response = await auth.signIn(login);
    let localUser = response.usuario;
    const data: any = jwtDecode(response.token);
    localUser.role = data.role.toString().split(",");

    api.defaults.headers.Authorization = `Bearer ${response.token}`;

    sessionStorage.setItem("@MOF:user", JSON.stringify(localUser));
    sessionStorage.setItem("@MOF:token", response.token);
    sessionStorage.setItem("@MOF:hashId", localUser.hashSession);

    setUser(localUser);
  }

  async function signInChange(trocaLogin: ITrocaLogin) {
    const response = await auth.signInChange(trocaLogin);
    let localUser = response.usuario;

    const data: any = jwtDecode(response.token);
    localUser.role = data.role.toString().split(",");

    api.defaults.headers.Authorization = `Bearer ${response.token}`;

    sessionStorage.setItem("@MOF:user", JSON.stringify(localUser));
    sessionStorage.setItem("@MOF:token", response.token);
    sessionStorage.setItem("@MOF:hashId", localUser.hashSession);

    setUser(localUser);
  }

  function havePermission(permissions: string[]): boolean {
    let isValid = false;
    permissions.forEach((permission) => {
      if (user?.role.indexOf(permission) !== -1) {
        isValid = true;
      }
    });

    return isValid;
  }

  function signOut() {
    setUser(null);
    sessionStorage.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        signed: user ? true : false,
        user,
        signIn,
        signOut,
        signInChange,
        havePermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("hook must be used with an Provider");
  }

  return context;
}

export { useAuth };
