import { api } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  IApiResponse,
  IEmpresa,
  IGetEmpresasById,
  IGetListarPorTabelaPreco,
  IGetListarPorTipoMaterial,
  IMaterial,
  IPostEmpresasDadosCadastrais,
  ITipoDashboard,
} from "shared/interfaces/empresa";
import { FSelect } from "shared/interfaces/select";

export const getEmpresas = async (params?: any) => {
  try {
    const { data } = await api.get<IApiResponse>("/Empresa/ListarEmpresas", {
      params: {
        sortBy: params?.sortBy,
        page: params?.page,
        limit: params?.pageSize,
        maxRecords: params?.totalItem,
        direction: params?.direction,
        Filtrar: params?.filtrar || "",
      },
    });
    return data.items;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.errors?.Mensagens?.[0] ||
      "Tivemos problemas internos ao buscar as Empresas";

    getErrorsByApi(error, errorMessage);

    return [];
  }
};

export const getDadosPaginacao = async (params?: any) => {
  try {
    const { data } = await api.get<IApiResponse>("/Empresa/ListarEmpresas", {
      params: {
        sortBy: params?.sortBy,
        page: params?.page,
        limit: params?.pageSize,
        maxRecords: params?.totalItem,
        direction: params?.direction,
        Filtrar: params?.search,
      },
    });
    return data.totalRecords;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.errors?.Mensagens?.[0] ||
      "Tivemos problemas internos ao buscar as Empresas";

    getErrorsByApi(error, errorMessage);

    return 0;
  }
};

export async function getEmpresaById(idEmpresa: number) {
  try {
    const response: ApiResponse<IGetEmpresasById> = await api.get(
      `/Empresa/ObterEmpresaPorId?idEmpresa=${idEmpresa}`
    );
    return response;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar essa Empresa";

    getErrorsByApi(error, errorMessage);
  }

  return null;
}

export const postEmpresasDadosCadastrais = async (
  params: IPostEmpresasDadosCadastrais
) => {
  try {
    const data = await api
      .post("/Empresa/SalvarEmpresa", params)
      .then((res) => res.data);
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao criar essa Empresa";

    getErrorsByApi(error, errorMessage);
  }
};

export const deleteEmpresa = async (idEmpresa: number) => {
  try {
    const response = await api.delete(
      `/Empresa/ExcluirEmpresa?idEmpresa=${idEmpresa}`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao deletar esse Empresa";

    getErrorsByApi(error, errorMessage);
  }
  return null;
};

export const getTipoDashboards = async (params?: any) => {
  try {
    const { data } = await api.get<ITipoDashboard[]>("TipoDashboard/Listar", {
      params,
    });
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Tipos de Dashboard";

    getErrorsByApi(error, errorMessage);
  }
};

export const getTipoMaterial = async (params?: any) => {
  try {
    const { data } = await api.get<IMaterial[]>("TipoMaterial/Listar", {
      params,
    });
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar os Tipos de Materiais";

    getErrorsByApi(error, errorMessage);
  }
};

export const getListarPorTipoMaterial = async (
  idTipoMaterial: number
): Promise<FSelect[]> => {
  if (!idTipoMaterial) throw new Error("Id do tipo material não informado");
  try {
    const response: ApiResponse<IGetListarPorTipoMaterial[]> = await api.post(
      `/Referencia/Listar`
    );
    console.log(response);
    const data: FSelect[] = response.data.map((item) => ({
      value: item.id,
      label: item.descricao,
    }));
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao listar os Tipos de Materiais";

    getErrorsByApi(error, errorMessage);
  }
  return [];
};

export const getListarPorTabelaPreco = async (): Promise<FSelect[]> => {
  try {
    const response: ApiResponse<IGetListarPorTabelaPreco[]> = await api.get(
      `/TipoMaterial/ListarTipoMaterialDeTabelaPreco`
    );

    const data: FSelect[] = response.data.map((item) => ({
      value: item.idTipoMaterial,
      label: item.descricao,
    }));
    return data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao listar os Tipos de Materiais de Tabela Preço";

    getErrorsByApi(error, errorMessage);
  }
  return [];
};

export const postDesativarAtivarEmpresa = async (
  idPerfil: number,
  ativarDesativar: boolean
) => {
  if (!idPerfil) throw new Error("Id do Perfil não informado");
  try {
    const response = await api.post(
      `/Empresa/AtivarDesativar?idEmpresa=${idPerfil}&ativar=${ativarDesativar}`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos com esse Perfil";

    getErrorsByApi(error, errorMessage);
  }
  return null;
};
