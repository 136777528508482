import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Switch, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { PreventExit } from "components/PreventExit";
import SelectComponent from "components/Select";
import { ControlledTextField } from "components/TextField/ControlledTextField";
import { useNavigation } from "contexts/notificationsContext";
import { usePreventExit } from "hooks/usePreventExit";
import { useSearchZipCode } from "hooks/useSearchZipCode";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEmpresaById,
  getTipoDashboards,
  postDesativarAtivarEmpresa,
  postEmpresasDadosCadastrais,
} from "services/modules/empresas";
import {
  IPostEmpresasDadosCadastrais,
  ITipoDashboard,
} from "shared/interfaces/empresa";
import { DadosCadastraisForm } from "shared/interfaces/formEmpresa";
import { FSelect } from "shared/interfaces/select";
import { ViaCepProps } from "shared/interfaces/zipCode";
import { fontsSizes } from "styles/theme";

import { Check, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { ClearIcon } from "@mui/x-date-pickers";
import Autocomplete from "components/Autocomplete";
import { AutocompleteChangeType } from "components/Autocomplete/Autocomplete";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { FormControlCustom } from "components/StackCustom/styles";
import { CadastroEmpresaContext } from "contexts/cadastroEmpresaContext";
import { ModalPerfisDeEmpresa } from "pages/PerfisDeEmpresa/modalCadastroPerfis";
import {
  getPerfisDeEmpresa,
  getPerfisDeEmpresaAtivos,
  getPerfisDeEmpresaById,
} from "services/modules/perfisDeEmpresa";
import { getBrasiliaTime } from "shared/helpers/cadastroEmpresas";
import { IGetPerfisDeEmpresa } from "shared/interfaces/perfisDeEmpresa";
import { DashboardFormType } from "./dashboard";
import { AplicabilidadesForm } from "./aplicabilidades";
import { TiposSelecionadosForm } from "./cadastrosN";

export type TipoStatusPerfilUsuario = "ATIVAR" | "DESATIVAR";

type FormTabelaPreco = {
  tipoSelecionados: Record<number, FSelect[]>;
};

interface DadosCadastraisProps {
  setAbasAdicionaisUtilizadas: Dispatch<SetStateAction<number[]>>;
  setEmpresaAtiva: Dispatch<SetStateAction<boolean>>;
  setAbasUtilizadas: Dispatch<SetStateAction<number[]>>;
}

export const DadosCadastrais = ({
  setAbasAdicionaisUtilizadas,
  setAbasUtilizadas,
  setEmpresaAtiva,
}: DadosCadastraisProps) => {
  const { openNotification } = useNavigation();
  const navigate = useNavigate();
  const params = useParams();
  const {
    isLoadingZipCode,
    onInputZipCode,
    cidades,
    estados,
    fillCidadesByEstadoId,
  } = useSearchZipCode();

  const {
    selectedItems,
    setSelectedItems,
    dataUltimaAtualizacao,
    setDataUltimaAtualizacao,
  } = useContext(CadastroEmpresaContext);

  const handleListCidade = async (value: any, element: string) => {
    if (element === "idEstadoEmpresa") {
      setValue("idEstadoEmpresa", value);

      await fillCidadesByEstadoId(value);
    }
  };

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [isOpenModalCadastroPerfis, setIsOpenModalCadastroPerfis] = useState<{
    isOpen: boolean;
    data?: IGetPerfisDeEmpresa;
  }>({
    isOpen: false,
  });

  const formTiposSelecionados = useForm<TiposSelecionadosForm>({
    defaultValues: {
      tiposSelecionados: {},
      checkExportar: false,
      checkImportar: false,
      checkLiberar: false,
    },
  });

  const formAplicabilidades = useForm<AplicabilidadesForm>({
    defaultValues: {
      selectReferenciasBase: [],
      selectReferenciasAplicaveis: [],
      selectReferenciasProprias: [],
    },
  });

  const formDashboard = useForm<DashboardFormType>({
    defaultValues: {
      accordionsData: {},
    },
  });

  const [checkLiberacao, setCheckLiberacao] = useState(false);
  const [checkImportar, setCheckImportar] = useState(false);
  const [checkExportar, setCheckExportar] = useState(false);

  const [isLoadingPerfis, setIsLoadingPerfis] = useState(false);
  const [perfisEmpresa, setPerfisEmpresa] = useState<FSelect[]>([]);

  const [items, setItems] = useState<FSelect[]>([]);
  const [namePerfil, setNamePerfil] = useState("");
  const [selectedTabela, setSelectedTabela] = useState<Set<number>>(new Set());
  const [selectedDashboards, setSelectedDashboards] = useState<Set<number>>(
    new Set()
  );
  const [mappedIdReferencias, setMappedIdReferencias] =
    useState<Record<number, string>>();
  const [accordionSelected, setAccordionSelected] = useState<number[]>([]);

  const [abasPerfis, setAbasPerfis] = useState<FSelect[]>([]);
  const [abasPerfisUtilizadas, setAbasPerfisUtilizadas] = useState<FSelect[]>(
    []
  );
  const [abasPerfisAdicionais, setAbasPerfisAdicionais] = useState<FSelect[]>(
    []
  );

  const [referenciasSelecionadas, setReferenciasSelecionadas] = useState<
    Record<number, { value: number; label: string }[]>
  >({});

  const [projetistasSelecionados, setProjetistasSelecionados] = useState<
    Record<number, { value: number; label: string }[]>
  >({});

  const [referenciasSelect, setReferenciasSelect] = useState<any[][]>(
    Array.from(
      {
        length: 5,
      },
      () => []
    )
  );
  const [projetistaSelect, setProjetistaSelect] = useState<any[][]>(
    Array.from(
      {
        length: 5,
      },
      () => []
    )
  );

  const [selectedLiberacoes, setSelectedLiberacoes] = useState<Set<number>>(
    new Set()
  );
  const [selectedItemsRef, setSelectedItemsRef] = useState<number[]>([]);
  const [tiposDashboards, setTiposDashboards] = useState<ITipoDashboard[]>([]);
  const [empresaIsAtivo, setEmpresaIsAtivo] = useState(false);

  const [modalDisabled, setModalDisabled] = useState<{
    isOpen: boolean;
    id?: number;
    tipoStatus?: TipoStatusPerfilUsuario;
  }>({
    isOpen: false,
  });

  const [modalRemovedRecurso, setModalRemovedRecurso] = useState<{
    isOpen: boolean;
    id?: number;
  }>({
    isOpen: false,
  });

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [getSelectedItems, setGetSelectedItems] = useState<number[]>([]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    getValues,
    clearErrors,
    formState,
    ...methods
  } = useForm<DadosCadastraisForm>({
    mode: "onBlur",
  });

  const formTabPreco = useForm<FormTabelaPreco>();

  const { errors, isDirty, dirtyFields } = useFormState({ control });

  const requiredFields = watch([
    "nomeFantasiaEmpresa",
    "documentoEmpresa",
    "razaoSocialEmpresa",
    "idPerfilEmpresa",
  ]);

  const isFormValid =
    requiredFields.every(Boolean) && !Object.keys(errors).length;

  const {
    isOpenModalUnsaved,
    handleIsOpenModalUnsaved,
    handleLinkNavigateBlocked,
    handleOnCloseModalUnsavedEdit,
    linkNavigateBlocked,
    isGoBack,
    goBack,
  } = usePreventExit(isDirty && Object.values(dirtyFields).length > 0);

  const fillAddressByZipCode = async (zipCodeData: ViaCepProps) => {
    const findState = estados?.find((state) => state.label === zipCodeData.uf);

    setValue("enderecoEmpresa", zipCodeData.logradouro, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("complementoEmpresa", zipCodeData.complemento);

    if (findState) {
      setValue("idEstadoEmpresa", Number(findState.value), {
        shouldDirty: true,
        shouldValidate: true,
      });

      const cities = await fillCidadesByEstadoId(Number(findState.value));

      const findCity = cities.find(
        (city) =>
          city.label.toLowerCase() === zipCodeData.localidade.toLowerCase()
      );

      if (findCity) {
        setValue("idCidadeEmpresa", findCity.value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        return;
      }
      openNotification({
        isOpen: true,
        message: "Cidade não encontrada",
        type: "warning",
      });
      return;
    }
    openNotification({
      isOpen: true,
      message: "Estado não encontrado",
      type: "warning",
    });
  };

  const handleSwitchChange = (id: number) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
    setValue("abasAdicionaisSelecionadas", [], {
      shouldDirty: true,
    });
  };

  const fetchTiposDashboards = async () => {
    try {
      const result = await getTipoDashboards();
      if (result) {
        const newMappedAccSelectedOptions: Record<
          number,
          { idReferencias: number[]; idProjetistas: number[] }
        > = {};
        const newReferenciasSelecionadas: Record<
          number,
          { value: number; label: string }[]
        > = {};
        result.forEach((tipo) => {
          newMappedAccSelectedOptions[tipo.idTipoDashboard] = {
            idProjetistas: [],
            idReferencias: [],
          };
          newReferenciasSelecionadas[tipo.idTipoDashboard] = [];
        });
        formDashboard.setValue("accordionsData", newMappedAccSelectedOptions);
        setReferenciasSelecionadas(newReferenciasSelecionadas);
        setProjetistasSelecionados(newReferenciasSelecionadas);
        setReferenciasSelect(
          result.map((item) =>
            item.referencias.map((item) => ({
              value: item.id,
              label: item.descricao,
            }))
          )
        );
        setProjetistaSelect(
          result.map((item) =>
            item.projetistas
              ? item.projetistas.map((proj) => ({
                  value: proj.id,
                  label: proj.descricao,
                }))
              : []
          )
        );
        setTiposDashboards(result);
      }
      const referenciasMapeadas: Record<number, string> = {};
      const projetistasMapeadas: Record<number, string> = {};
      const referencias = result?.map((item) => item.referencias).flat();
      const projetistas = result?.map((item) => item.projetistas).flat();
      referencias?.forEach(
        (ref) => (referenciasMapeadas[ref.id] = ref.descricao)
      );
      projetistas?.forEach((proj) => {
        if (proj !== null) {
          projetistasMapeadas[proj.id] = proj.descricao;
        }
      });
      const dadosMapeados = {
        referencias: referenciasMapeadas,
        projetistas: projetistasMapeadas,
      };
      return dadosMapeados;
    } catch (error) {
      openNotification({
        isOpen: true,
        message: "Erro ao buscar tipos de dashboard.",
        type: "error",
      });
    }
  };

  async function getDadosEmpresas(idEmpresa: number) {
    try {
      const result = await getEmpresaById(idEmpresa);
      const dadosMapeados = await fetchTiposDashboards();
      if (result) {
        setEmpresaIsAtivo(result.data.ativo);
        const perfilResult = await getPerfisDeEmpresaById(result.data.idPerfil);
        setNamePerfil(perfilResult?.data.nomePerfil ?? "");
        if (result.data.idEstado) {
          await fillCidadesByEstadoId(result.data.idEstado);
        }

        setGetSelectedItems(
          perfilResult?.data?.abas?.map((item) => item.id) ?? []
        );

        if (result.data.idPerfil) {
          const perfilResult = await getPerfisDeEmpresaById(
            result.data.idPerfil
          );
          if (perfilResult?.data) {
            setValue("nomePerfilEmpresa", perfilResult.data.nomePerfil);
          }
        }

        const produtoNivelUmMaterialVMs =
          result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs;
        const newSelectedLiberacoes = new Set(selectedTabela);
        produtoNivelUmMaterialVMs?.forEach((p) => {
          newSelectedLiberacoes.add(p.idTipoMaterial ?? 0);
        });
        setSelectedTabela(newSelectedLiberacoes);

        const tabelaGetVMs = result.data.tabelaPrecoVMs;
        const newSelectedtabela = new Set(selectedTabela);

        tabelaGetVMs?.forEach((p) => {
          newSelectedtabela.add(p.idTipoMaterial ?? 0);
        });
        setSelectedTabela(newSelectedtabela);

        reset({
          idEmpresa: result.data.idEmpresa,
          cepEmpresa: result.data.cep,
          complementoEmpresa: result.data.complemento,
          idCidadeEmpresa: result.data.idCidade ?? null,
          idEstadoEmpresa: result.data.idEstado ?? null,
          documentoEmpresa: result.data.cpfCnpj,
          enderecoEmpresa: result.data.endereco,
          nomeFantasiaEmpresa: result.data.nomeFantasia,
          numeroEmpresa: result.data.numero ?? null,
          idPerfilEmpresa: result.data.idPerfil,
          razaoSocialEmpresa: result.data.razaoSocial,
          ativoEmpresa: result.data.ativo,
          perfilEmpresa: result.data.idPerfil.toString(),
          abasDoPerfilSelecionado:
            result?.data.abasUtilizadasPerfil.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
          abasParaAdicionar:
            result?.data.abasDisponiveisRecursosAdicionais.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
        });

        const getAbasPerfil = result.data.abasUtilizadasPerfil.map((item) => ({
          value: item.id,
          label: item.nome,
        }));

        const getAbasUtilizadasPerfil =
          result.data.abasUtilizadasRecursosAdicionais.map((item) => ({
            value: item.id,
            label: item.nome,
          }));

        const newAccordionSelectedDash: number[] = [];
        result.data?.tipoDashboardVMs?.forEach((item) => {
          if (item.idTipoDashboard) {
            newAccordionSelectedDash.push(item.idTipoDashboard);
          }
        });
        setAccordionSelected(newAccordionSelectedDash);

        const newReferenciasSelecionadas: Record<
          number,
          { value: number; label: string }[]
        > = {};
        const newProjetistasSelecionados: Record<
          number,
          { value: number; label: string }[]
        > = {};
        const newAccSelectedOptions: Record<
          number,
          {
            idReferencias: number[];
            idProjetistas: number[];
          }
        > = {};

        result.data.tipoDashboardVMs?.forEach((item) => {
          newReferenciasSelecionadas[item.idTipoDashboard ?? 0] =
            item.referencias?.map((ref) => {
              return { value: ref, label: dadosMapeados?.referencias[ref]! };
            })!;
          newProjetistasSelecionados[item.idTipoDashboard ?? 0] =
            item.projetistas?.map((proj) => {
              return { value: proj, label: dadosMapeados?.projetistas[proj]! };
            })!;
          newAccSelectedOptions[item.idTipoDashboard ?? 0] = {
            idReferencias: item.referencias!,
            idProjetistas: item.projetistas!,
          };
        });

        setReferenciasSelecionadas(newReferenciasSelecionadas);
        setProjetistasSelecionados(newProjetistasSelecionados);
        formDashboard.setValue("accordionsData", newAccSelectedOptions);

        formAplicabilidades.setValue(
          "selectReferenciasBase",
          result?.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoBase?.map(
            (ref) => ({
              value: ref,
              label: mappedIdReferencias?.[ref] ?? "",
            })
          ) ?? []
        );

        formAplicabilidades.setValue(
          "selectReferenciasAplicaveis",
          result?.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoAplicavel?.map(
            (ref) => ({
              value: ref,
              label: mappedIdReferencias?.[ref] ?? "",
            })
          ) ?? []
        );

        formAplicabilidades.setValue(
          "selectReferenciasProprias",
          result?.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoProprio?.map(
            (ref) => ({
              value: ref,
              label: mappedIdReferencias?.[ref] ?? "",
            })
          ) ?? []
        );

        setCheckLiberacao(
          result.data.produtoNivelUmVM?.apenasConsulta || false
        );
        setCheckImportar(
          result.data.produtoNivelUmVM?.importarReferenciaMercado || false
        );
        setCheckExportar(
          result.data.produtoNivelUmVM?.exportarReferenciaMercado || false
        );

        setAbasPerfis([...getAbasPerfil]);
        setAbasPerfisUtilizadas([...getAbasUtilizadasPerfil]);
        setAbasPerfisAdicionais(
          result.data.abasDisponiveisRecursosAdicionais.map((item) => ({
            value: item.id,
            label: item.nome,
          }))
        );
        setSelectedItems(
          result.data.abasUtilizadasRecursosAdicionais.map((item) => item.id)
        );
        setDataUltimaAtualizacao(
          result.data.dataUltimaAtualizacao
            ? new Date(result.data.dataUltimaAtualizacao)
            : ""
        );

        const newAccordionSelected: number[] = [];

        result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs?.forEach(
          (item) => {
            if (item.idTipoMaterial) {
              newAccordionSelected.push(item.idTipoMaterial);
            }
          }
        );

        setAccordionSelected(newAccordionSelected);
        formTiposSelecionados.setValue(
          "checkLiberar",
          result.data.produtoNivelUmVM?.apenasConsulta || false
        );
        formTiposSelecionados.setValue(
          "checkImportar",
          result.data.produtoNivelUmVM?.importarReferenciaMercado || false
        );
        formTiposSelecionados.setValue(
          "checkExportar",
          result.data.produtoNivelUmVM?.exportarReferenciaMercado || false
        );
        const newTiposSelecionados = {
          ...formTiposSelecionados.getValues("tiposSelecionados"),
        };
        produtoNivelUmMaterialVMs?.forEach((p) => {
          newTiposSelecionados[p.idTipoMaterial ?? 0] =
            p.idsReferencia?.map((ref) => {
              return {
                value: ref,
                label: mappedIdReferencias?.[ref] ?? "",
              };
            }) ?? [];
        });
        formTiposSelecionados.setValue(
          "tiposSelecionados",
          newTiposSelecionados
        );

        const newTiposSelecionadosTabela = {
          ...formTabPreco.getValues("tipoSelecionados"),
        };
        tabelaGetVMs?.forEach((p) => {
          newTiposSelecionadosTabela[p.idTipoMaterial ?? 0] =
            p.idsReferencia?.map((ref) => {
              return {
                value: ref,
                label: mappedIdReferencias?.[ref] ?? "",
              };
            }) ?? [];
        });
        formTabPreco.setValue("tipoSelecionados", newTiposSelecionadosTabela);

        const newAccordionSelectedTabela: number[] = [];
        result.data?.tabelaPrecoVMs?.forEach((item) => {
          if (item) {
            newAccordionSelectedTabela.push(item?.idTipoMaterial);
          }
        });
        setAccordionSelected(newAccordionSelectedTabela);

        if (result && result.data) {
          const tipoMaterialVMs =
            result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs || [];

          const referenciaItems = tipoMaterialVMs.flatMap(
            (item) => item.idsReferencia || []
          );

          const selectedIds = new Set<number>(
            tipoMaterialVMs
              .map((item) => item.idTipoMaterial)
              .filter((id): id is number => id !== undefined)
          );
          setSelectedLiberacoes(selectedIds);

          setSelectedItemsRef(referenciaItems);
        }
        if (result && result.data) {
          const tipoDashboardVMs = result.data.tipoDashboardVMs || [];

          const selectedIds = new Set<number>(
            tipoDashboardVMs
              .map((item) => item.idTipoDashboard)
              .filter((id): id is number => id !== undefined)
          );
          setSelectedDashboards(selectedIds);
        }
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  const handleSubmitDadosCadastrais = async (data: DadosCadastraisForm) => {
    setIsLoadingSubmit(true);
    try {
      const tabelaVMs = Array.from(selectedTabela).map((idTipoMaterial) => {
        const nomesReferencia =
          formTabPreco.getValues("tipoSelecionados")[idTipoMaterial];
        const idsReferencia = nomesReferencia.map((item) => {
          return item.value;
        });

        return {
          idTipoMaterial,
          idsReferencia,
        };
      });

      const produtoNivelUmMaterialVMs = Array.from(selectedLiberacoes).map(
        (idTipoMaterial) => {
          const nomesReferencia =
            formTiposSelecionados.getValues("tiposSelecionados")[
              idTipoMaterial
            ];
          const idsReferencia = nomesReferencia.map((item) => {
            return item.value;
          });

          return {
            idTipoMaterial,
            idsReferencia,
          };
        }
      );

      const transformToEmpresa = (
        data: DadosCadastraisForm
      ): IPostEmpresasDadosCadastrais => {
        return {
          idEmpresa: data.idEmpresa ?? 0,
          nomeFantasia: data.nomeFantasiaEmpresa,
          cpfCnpj: data.documentoEmpresa,
          cep: data.cepEmpresa,
          ativo: data.ativoEmpresa ?? true,
          complemento: data?.complementoEmpresa ?? "",
          endereco: data.enderecoEmpresa,
          idCidade: data.idCidadeEmpresa ?? null,
          idEstado: data.idEstadoEmpresa ?? null,
          idPerfil: data.idPerfilEmpresa,
          numero:
            data.numeroEmpresa !== null ? Number(data.numeroEmpresa) : null,
          razaoSocial: data.razaoSocialEmpresa,
          dataUltimaAtualizacao: getBrasiliaTime(),
          abas: selectedItems,
          tipoDashboardVMs:
            selectedItems.includes(1) || getSelectedItems.includes(1)
              ? Array.from(selectedDashboards).map((idTipoDashboard) => {
                  const accOptions = formDashboard.getValues("accordionsData")[
                    idTipoDashboard
                  ] || {
                    idProjetistas: [],
                    idReferencias: [],
                  };
                  return {
                    idTipoDashboard: idTipoDashboard,
                    idEmpresa: Number(params.id) ?? 0,
                    projetistas: accOptions.idProjetistas,
                    referencias: accOptions.idReferencias,
                  };
                })
              : undefined,
          produtoNivelUmVM:
            selectedItems.includes(2) || getSelectedItems.includes(2)
              ? {
                  produtoNivelUmMaterialVMs: produtoNivelUmMaterialVMs,
                  apenasConsulta: checkLiberacao,
                  exportarReferenciaMercado: checkExportar,
                  importarReferenciaMercado: checkImportar,
                }
              : undefined,
          referenciasAplicabilidadeVM:
            selectedItems.includes(3) || getSelectedItems.includes(3)
              ? {
                  idsReferenciasCodigoAplicavel: formAplicabilidades
                    .getValues("selectReferenciasAplicaveis")
                    .map((item) => item.value),
                  idsReferenciasCodigoBase: formAplicabilidades
                    .getValues("selectReferenciasBase")
                    .map((item) => item.value),
                  idsReferenciasCodigoProprio: formAplicabilidades
                    .getValues("selectReferenciasProprias")
                    .map((item) => item.value),
                }
              : undefined,
          tabelaPrecoVMs:
            selectedItems.includes(4) || getSelectedItems.includes(4)
              ? tabelaVMs
              : [],
        };
      };

      const postData: IPostEmpresasDadosCadastrais = transformToEmpresa(data);

      if (params.id) {
        await postEmpresasDadosCadastrais(postData);
        openNotification({
          isOpen: true,
          message: "Empresa atualizada com sucesso!",
          type: "success",
        });
        if (postData.abas) {
          setAbasAdicionaisUtilizadas((_) => {
            return [...(postData.abas ?? [])];
          });
          setAbasUtilizadas((_) => {
            const newAbas = [
              ...abasPerfis.map((aba) => aba.value),
              ...(postData.abas ?? []),
            ];
            return newAbas;
          });
        }
        await getDadosEmpresas(Number(params.id));
      } else {
        const result = await postEmpresasDadosCadastrais(postData);
        if (result) {
          openNotification({
            isOpen: true,
            message: "Empresa cadastrada com sucesso!",
            type: "success",
          });
          navigate(`/empresasMof/editar/${result}`, { replace: true });
          await getDadosEmpresas(result);
        }
      }
      setDataUltimaAtualizacao(new Date());
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const handleNovoPerfil = () => {
    setIsOpenModalCadastroPerfis({ isOpen: true });
  };

  const handleAutoCompleteChange = (value: AutocompleteChangeType) => {
    handleSelectPerfilDeEmpresa(Number(value?.id));
    setValue("idPerfilEmpresa", Number(value?.id), { shouldDirty: true });
  };

  const handleSelectPerfilDeEmpresa = async (id: number) => {
    setIsLoadingPerfis(true);
    try {
      const result = await getPerfisDeEmpresaById(id);
      setSelectedItems([]);
      if (result && result.data) {
        const perfilNome = result?.data.nomePerfil;

        const abaPerfil = result?.data.abas.map((item) => ({
          value: item.id,
          label: item.nome,
        }));

        const abaAdicional = result?.data.abasDisponiveis.map((item) => ({
          value: item.id,
          label: item.nome,
        }));

        setNamePerfil(perfilNome ?? "");

        setAbasPerfisAdicionais(abaAdicional);

        setItems([...abaPerfil]);

        setValue("nomePerfilEmpresa", perfilNome);

        setAbasPerfis(abaPerfil);
      } else {
        setPerfisEmpresa([]);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error.message,
        type: "error",
      });
    } finally {
      setIsLoadingPerfis(false);
    }
  };

  const handleRemoveItem = (itemValue: number) => {
    const updatedAbasPerfisUtilizadas = abasPerfisUtilizadas.filter(
      (item) => item.value !== itemValue
    );

    const removedItem = abasPerfisUtilizadas.find(
      (item) => item.value === itemValue
    );

    setAbasPerfisUtilizadas(updatedAbasPerfisUtilizadas);

    if (removedItem) {
      setAbasPerfisAdicionais((prevAdicionais) => [
        ...prevAdicionais,
        removedItem,
      ]);
    }

    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selectedItem) => selectedItem !== itemValue)
    );

    setValue("abasAdicionaisSelecionadas", updatedAbasPerfisUtilizadas, {
      shouldDirty: true,
    });
  };

  async function handleDisabledEmpresa(
    id?: number,
    status?: TipoStatusPerfilUsuario
  ) {
    if (!id) return;
    const isAtivo = status === "ATIVAR" ? true : false;
    try {
      const response = await postDesativarAtivarEmpresa(id, isAtivo);
      if (response) {
        setValue("ativoEmpresa", status === "ATIVAR" ? true : false);
        setEmpresaAtiva(isAtivo);
        openNotification({
          isOpen: true,
          message: `Empresa foi ${
            isAtivo ? "ativada" : "desativada"
          } com sucesso!`,
          type: "success",
        });
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: `Erro ao ${
          isAtivo ? "ativar" : "desativar"
        } a Empresa. Tente novamente.`,
        type: "error",
      });
    }
  }

  useEffect(() => {
    const fetchPerfis = async () => {
      setIsLoadingPerfis(true);
      try {
        const result = await getPerfisDeEmpresaAtivos();
        if (result && Array.isArray(result)) {
          const formattedPerfis = result.map((perfil) => ({
            value: perfil.idPerfil,
            label: perfil.nomePerfil,
          }));
          setPerfisEmpresa(formattedPerfis);
        } else {
          setPerfisEmpresa([]);
        }
      } catch (error: any) {
        openNotification({
          isOpen: true,
          message: error.message,
          type: "error",
        });
      } finally {
        setIsLoadingPerfis(false);
      }
    };

    fetchPerfis();
  }, []);

  useEffect(() => {
    params.id && getDadosEmpresas(Number(params.id));
  }, [params.id]);

  return (
    <PreventExit
      handleIsOpenModalUnsaved={handleIsOpenModalUnsaved}
      handleLinkNavigateBlocked={handleLinkNavigateBlocked}
      isOpenModalUnsaved={isOpenModalUnsaved}
      handleOnCloseModalUnsavedEdit={handleOnCloseModalUnsavedEdit}
      linkNavigateBlocked={linkNavigateBlocked}
      isGoBack={isGoBack}
    >
      <FormProvider
        {...{
          control,
          handleSubmit,
          reset,
          setValue,
          watch,
          setError,
          getValues,
          clearErrors,
          formState,
          onSubmit: handleSubmit(handleSubmitDadosCadastrais),
          ...methods,
        }}
      >
        <Grid container p={1.5}>
          <Grid
            container
            mb={0.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item ml={4}>
              <FormControlCustom
                label={"Ativo"}
                control={
                  <Switch
                    onClick={(e) => {
                      e.preventDefault();
                      setModalDisabled({
                        isOpen: true,
                        id: Number(params.id),
                        tipoStatus: !watch().ativoEmpresa
                          ? "ATIVAR"
                          : "DESATIVAR",
                      });
                    }}
                    checked={watch().ativoEmpresa}
                  />
                }
              />
            </Grid>
            {dataUltimaAtualizacao && (
              <Grid item>
                <Typography
                  variant="caption"
                  marginRight={1}
                  sx={{
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: fontsSizes.medium,
                  }}
                >
                  {`Ultima Atualização: ${new Intl.DateTimeFormat("pt-BR", {
                    dateStyle: "short",
                    timeStyle: "short",
                  }).format(dataUltimaAtualizacao as Date)}`}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <ControlledTextField
                control={control}
                name="documentoEmpresa"
                label="CNPJ / CPF"
                error={Boolean(errors.documentoEmpresa)}
                helperText={errors.documentoEmpresa?.message}
                mask="cpfCnpj"
                maxLength={18}
                required
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={4}>
              <ControlledTextField
                control={control}
                name="razaoSocialEmpresa"
                label="Razão Social"
                maxLength={30}
                error={Boolean(errors.razaoSocialEmpresa)}
                helperText={errors.razaoSocialEmpresa?.message}
                required
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={4}>
              <ControlledTextField
                control={control}
                name="nomeFantasiaEmpresa"
                label="Fantasia"
                maxLength={55}
                error={Boolean(errors.nomeFantasiaEmpresa)}
                helperText={errors.nomeFantasiaEmpresa?.message}
                required
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={3}>
              <ControlledTextField
                control={control}
                name="cepEmpresa"
                label="CEP"
                mask="cep"
                error={Boolean(errors.cepEmpresa)}
                helperText={errors.cepEmpresa?.message}
                onChange={async (event) => {
                  const zipCodeData = await onInputZipCode(
                    event.currentTarget.value ?? ""
                  );
                  zipCodeData && (await fillAddressByZipCode(zipCodeData));
                }}
                maxLength={9}
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={6}>
              <ControlledTextField
                control={control}
                name="enderecoEmpresa"
                label="Endereço"
                error={Boolean(errors.enderecoEmpresa)}
                helperText={errors.enderecoEmpresa?.message}
                maxLength={40}
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={3}>
              <ControlledTextField
                control={control}
                name="numeroEmpresa"
                label="Número"
                error={Boolean(errors.numeroEmpresa)}
                helperText={errors.numeroEmpresa?.message}
                mask="only-numbers"
                maxLength={6}
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={5}>
              <ControlledTextField
                control={control}
                name="complementoEmpresa"
                label="Complemento"
                error={Boolean(errors.complementoEmpresa)}
                helperText={errors.complementoEmpresa?.message}
                maxLength={40}
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={3.5}>
              <SelectComponent
                id="idEstadoEmpresa"
                items={estados ?? []}
                control={control}
                name="idEstadoEmpresa"
                label={"Estado"}
                style={{ width: "100%" }}
                toggleInputData={handleListCidade}
                loading={isLoadingZipCode}
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={3.5}>
              <SelectComponent
                items={cidades ?? []}
                id={"idCidadeEmpresa"}
                control={control}
                name="idCidadeEmpresa"
                label={"Cidade"}
                style={{ width: "100%" }}
                disabled={
                  !watch().idEstadoEmpresa ||
                  (!watch().ativoEmpresa && !!params.id)
                }
                loading={isLoadingZipCode}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            mt={2}
            spacing={2}
          >
            <Grid item md={1.5}>
              <Typography
                sx={{
                  fontSize: fontsSizes.large,
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Perfil da empresa
              </Typography>
            </Grid>
            <Grid item md={10.5}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={5}>
              <Autocomplete
                name="idPerfilEmpresa"
                label={"Perfil"}
                value={{
                  id: getValues().idPerfilEmpresa,
                  title: getValues().nomePerfilEmpresa,
                }}
                onChange={handleAutoCompleteChange}
                items={perfisEmpresa ?? []}
                id="perfilEmpresa"
                clearOnBlur
                required
                loading={isLoadingPerfis}
                disabled={!watch().ativoEmpresa && !!params.id}
              />
            </Grid>
            <Grid item md={4}>
              <LoadingButton
                variant="contained"
                endIcon={<AddIcon />}
                onClick={handleNovoPerfil}
                sx={{
                  marginLeft: 1,
                  height: "56px",
                }}
              >
                Novo Perfil
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={3}>
              <TextField
                id="nomePerfilEmpresa"
                label="Nome do Perfil"
                value={namePerfil || "Selecione um Perfil"}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
                variant="standard"
              />
              {abasPerfis?.map((item, index) => (
                <Typography
                  p={0.5}
                  key={index}
                  mt={0.5}
                  sx={{ color: "#000000" }}
                >
                  {item.label}
                </Typography>
              ))}
              {abasPerfisUtilizadas.length === 0 ? (
                <Grid></Grid>
              ) : (
                <Typography
                  p={0.5}
                  key={1}
                  mt={2}
                  fontSize={"12px"}
                  sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Recursos adicionais
                </Typography>
              )}
              {abasPerfisUtilizadas?.map((item, index) => (
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    p={0.5}
                    key={index}
                    sx={{ color: "rgba(53, 131, 177, 1)" }}
                  >
                    {item.label}
                  </Typography>
                  <CloseIcon
                    onClick={() =>
                      setModalRemovedRecurso({ isOpen: true, id: item.value })
                    }
                    sx={{
                      cursor: "pointer",
                      color: "#ce1515",
                      marginLeft: "8px",
                      display:
                        !watch().ativoEmpresa === false ? "flex" : "none",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item md={3}>
              <TextField
                id="abasAdicionaisSelecionadas"
                label=""
                sx={{ marginTop: 2 }}
                defaultValue="Recursos adicionais"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              {abasPerfisAdicionais.map((item) => (
                <Grid item xs={12} sm={12} md={10} pl={0.5} key={item.value}>
                  <FormControlCustom
                    label={item.label}
                    sx={{ width: "100%" }}
                    id="abasAdicionaisSelecionadas"
                    control={
                      <Switch
                        sx={{ marginLeft: "10px" }}
                        checked={selectedItems.includes(item.value)}
                        onChange={() => handleSwitchChange(item.value)}
                        disabled={!watch().ativoEmpresa && !!params.id}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                endIcon={<Save />}
                onClick={handleSubmit(handleSubmitDadosCadastrais)}
                loading={isLoadingSubmit}
                disabled={!isFormValid || !isDirty}
                sx={{
                  marginLeft: 1,
                }}
              >
                Salvar
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
        <ModalPerfisDeEmpresa
          isOpen={isOpenModalCadastroPerfis.isOpen}
          setNewPerfil={setPerfisEmpresa}
          onClose={() => setIsOpenModalCadastroPerfis({ isOpen: false })}
          handleSelectPerfilEmpresa={handleSelectPerfilDeEmpresa}
          dataPerfisDeEmpresa={isOpenModalCadastroPerfis.data}
        />

        <ConfirmDeleteModal
          title="Remoção do Recurso"
          description="Todas as configurações feitas para o recurso se perderão ao excluir, deseja continuar?"
          open={modalRemovedRecurso.isOpen}
          onClose={() => setModalRemovedRecurso({ isOpen: false })}
          onConfirm={() => {
            if (modalRemovedRecurso.id !== undefined) {
              handleRemoveItem(modalRemovedRecurso.id);
            }
            setModalRemovedRecurso({ isOpen: false });
          }}
        />

        <ConfirmDeleteModal
          title={
            modalDisabled.tipoStatus === "DESATIVAR"
              ? "Tem certeza que deseja desativar essa Empresa?"
              : "Tem certeza que deseja ativar essa Empresa?"
          }
          description=""
          open={modalDisabled.isOpen}
          onClose={() => setModalDisabled({ isOpen: false })}
          onConfirm={() => {
            handleDisabledEmpresa(modalDisabled.id, modalDisabled.tipoStatus)
              .then(() => {
                return getPerfisDeEmpresa();
              })
              .finally(() => {
                setModalDisabled({ isOpen: false });
              });
          }}
          buttonTextDelete={
            modalDisabled.tipoStatus === "DESATIVAR" ? "DESATIVAR" : "ATIVAR"
          }
          customButtonConfirm={
            <LoadingButton
              variant="contained"
              size="medium"
              color={
                modalDisabled.tipoStatus === "DESATIVAR" ? "error" : "success"
              }
              endIcon={
                modalDisabled.tipoStatus === "DESATIVAR" ? (
                  <ClearIcon />
                ) : (
                  <Check />
                )
              }
              sx={{ color: "white" }}
              onClick={() => {
                handleDisabledEmpresa(
                  modalDisabled.id,
                  modalDisabled.tipoStatus
                )
                  .then(() => {
                    return getPerfisDeEmpresa();
                  })
                  .finally(() => {
                    setModalDisabled({ isOpen: false });
                  });
              }}
              loading={isLoadingDelete}
              loadingPosition="end"
            >
              {modalDisabled.tipoStatus === "DESATIVAR"
                ? "DESATIVAR"
                : "ATIVAR"}
            </LoadingButton>
          }
        />
      </FormProvider>
    </PreventExit>
  );
};
