import { useNavigation } from "contexts/notificationsContext";
import { useEffect, useMemo, useState } from "react";
import {
  getListarCidadesPorIdEstado,
  getListarEstados,
} from "services/modules/localizacao";
import { getZipCode } from "services/modules/zipCode";
import { FSelect } from "shared/interfaces/select";

export function useSearchZipCode() {
  const { openNotification } = useNavigation();
  const [cidades, setCidades] = useState<FSelect[]>([]);
  const [estados, setEstados] = useState<FSelect[]>([]);
  const [isLoadingZipCode, setIsLoadingZipCode] = useState(false);

  const onInputZipCode = async (value: string) => {
    const formatedValue = value.replace(/\D/g, "");
    if (formatedValue.length === 8) {
      setIsLoadingZipCode(true);
      const result = await getZipCode(formatedValue).finally(() =>
        setIsLoadingZipCode(false)
      );

      return result;
    }
  };

  async function getListEstados() {
    try {
      const result = await getListarEstados();
      if (result) {
        setEstados(result);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });

      setEstados([]);
    }
  }

  const fillCidadesByEstadoId = async (estadoId: number) => {
    try {
      const listCidades = await getListarCidadesPorIdEstado(estadoId);
      setCidades(listCidades);
      return listCidades;
    } catch {
      openNotification({
        isOpen: true,
        message: "Não foi possivel carregar as cidades",
        type: "error",
      });
    }
    setCidades([]);
    return [];
  };

  useEffect(() => {
    getListEstados();
  }, []);

  const values = useMemo(() => {
    return {
      isLoadingZipCode,
      onInputZipCode,
      estados,
      fillCidadesByEstadoId,
      cidades,
    };
  }, [isLoadingZipCode, estados, cidades]);

  return values;
}
