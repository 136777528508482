import { ModalUnsavedEdit } from "components/ModalUnsavedEdit";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface PreventExitProps {
  description?: string;
  children: ReactNode;
  isOpenModalUnsaved: boolean;
  handleIsOpenModalUnsaved: (isOpen: boolean) => void;
  handleLinkNavigateBlocked: (linkBlocked: string) => void;
  handleOnCloseModalUnsavedEdit: () => void;
  linkNavigateBlocked: string;
  isGoBack: boolean;
}

export function PreventExit({
  description,
  children,
  isOpenModalUnsaved,
  handleIsOpenModalUnsaved,
  handleLinkNavigateBlocked,
  handleOnCloseModalUnsavedEdit,
  linkNavigateBlocked,
  isGoBack,
}: PreventExitProps) {
  const navigate = useNavigate();

  return (
    <>
      {children}
      <ModalUnsavedEdit
        description={
          description ||
          "Ao sair todas as informações preenchidas serão apagadas, deseja continuar?"
        }
        isOpen={isOpenModalUnsaved}
        onClose={() => {
          handleLinkNavigateBlocked("");
          handleIsOpenModalUnsaved(false);
        }}
        onYes={() => {
          if (isGoBack) {
            handleOnCloseModalUnsavedEdit();
            navigate(-1);

            return;
          }

          const currentLinkNavigateBlocked = linkNavigateBlocked;
          handleOnCloseModalUnsavedEdit();
          navigate(currentLinkNavigateBlocked);
        }}
        onNot={() => {
          handleIsOpenModalUnsaved(false);
          handleLinkNavigateBlocked("");
        }}
        type
      />
    </>
  );
}
