import Chart from "react-apexcharts";
import { ContainerGrafico } from "../ContainerGrafico";

const options: ApexCharts.ApexOptions = {
	plotOptions: {
		pie: {
			donut: {
				size: "0%",
			},
		},
	},
	chart: {
		type: "donut",
		width: "100%",
		height: "100%",
	},
	noData: {
		text: "Sem dados",
		align: "left",
		verticalAlign: "top",
		offsetX: 50,
	},
	responsive: [
		{
			breakpoint: 380,
			options: {
				chart: {
					width: 200,
					height: 200,
				},
				legend: {
					position: "bottom",
				},
			},
		},
		{
			breakpoint: 430,
			options: {
				chart: {
					width: 270,
					height: 200,
				},
				legend: {
					position: "bottom",
				},
			},
		},
		{
			breakpoint: 770,
			options: {
				chart: {
					width: 270,
					height: 200,
				},
				legend: {
					position: "bottom",
				},
			},
		},
	],
};

interface GraficoPieProps {
	labels: string[];
	series: number[];
	title: string;
	onClick?: () => void;
}

export function GraficoPizza({ labels, series, title, onClick }: GraficoPieProps) {
	return (
		<ContainerGrafico title={title} onClick={onClick}>
			<Chart
				options={{
					...options,
					labels: labels ?? [],
				}}
				series={series ?? []}
				type="donut"
				width="100%"
				height={315}
			/>
		</ContainerGrafico>
	);
}
