import configs from "Config";
import { Link } from "react-router-dom";

export function Login({}) {
  return (
    <Link className="nav-link" to={`${configs.REACT_APP_SITE_ANTIGO_URL}`}>
      Login
    </Link>
  );
}
