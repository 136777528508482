import { SidebarContext } from "contexts/sidebarContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export function usePreventExit(blockNavigation: boolean) {
  const navigate = useNavigate();
  const {
    handleIsNavigateBlocked,
    linkNavigateBlocked,
    handleLinkNavigateBlocked,
  } = useContext(SidebarContext);
  const [isOpenModalUnsaved, setIsOpenModalUnsaved] = useState(false);
  const [isGoBack, setIsGoBack] = useState(false);

  const handleOnCloseModalUnsavedEdit = () => {
    handleIsNavigateBlocked(false);
    setIsOpenModalUnsaved(false);
    handleLinkNavigateBlocked("");
  };

  const handleIsOpenModalUnsaved = (isOpen: boolean) => {
    setIsOpenModalUnsaved(isOpen);
  };

  function goBack(isBlock: boolean, route?: string) {
    if (!isBlock) {
      if (route) {
        navigate(route);
        return;
      }
      navigate(-1);
    } else {
      handleIsOpenModalUnsaved(true);

      if (route) {
        handleLinkNavigateBlocked(route);
      }
      setIsGoBack(!route);
    }
  }

  useEffect(() => {
    return () => {
      handleLinkNavigateBlocked("");
    };
  }, [blockNavigation]);

  useEffect(() => {
    !!linkNavigateBlocked && setIsOpenModalUnsaved(true);
  }, [linkNavigateBlocked]);

  const values = useMemo(() => {
    return {
      isOpenModalUnsaved,
      handleOnCloseModalUnsavedEdit,
      handleIsOpenModalUnsaved,
      handleLinkNavigateBlocked,
      goBack,
      linkNavigateBlocked,
      isGoBack,
    };
  }, [isOpenModalUnsaved, linkNavigateBlocked, isGoBack]);

  return values;
}
