const LogoMof = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1696 622"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <g transform="matrix(1,0,0,1,-334.013,-334.464)">
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
          <g transform="matrix(1,0,0,1,0,1.21333)">
            <g transform="matrix(1,0,0,1,275.596,182.714)">
              <path
                d="M0,-58.137L0,9.692L-16.612,9.692L-16.612,-67.829L12.459,-67.829L27.687,-8.303L41.528,-67.829L71.984,-67.829L71.984,9.692L55.373,9.692L55.373,-58.137L35.991,9.692L19.381,9.692L0,-58.137Z"
                style={{ fill: "#ffffff", fillRule: "nonzero" }}
              />
            </g>
            <g transform="matrix(1,0,0,1,397.913,175.796)">
              <path
                d="M0,-44.299L-16.611,-44.299C-22.147,-44.299 -24.917,-40.608 -24.917,-33.225L-24.917,-11.076C-24.917,-3.693 -22.147,-0.003 -16.611,-0.003L0,-0.003C5.537,-0.003 8.308,-3.693 8.308,-11.076L8.308,-33.225C8.308,-40.608 5.537,-44.299 0,-44.299M-2.769,-60.91C15.69,-60.91 24.918,-50.759 24.918,-30.455L24.918,-13.844C24.918,6.459 15.69,16.611 -2.769,16.611L-13.843,16.611C-32.299,16.611 -41.527,6.459 -41.527,-13.844L-41.527,-30.455C-41.527,-50.759 -32.299,-60.91 -13.843,-60.91L-2.769,-60.91Z"
                style={{ fill: "#ffffff", fillRule: "nonzero" }}
              />
            </g>
            <g transform="matrix(1,0,0,1,448.249,145.34)">
              <path
                d="M0,16.611L0,47.066L-16.61,47.066L-16.61,-30.455L38.762,-30.455L38.762,-13.844L0,-13.844L0,0L33.225,0L33.225,16.611L0,16.611Z"
                style={{ fill: "#ffffff", fillRule: "nonzero" }}
              />
            </g>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,200.839,194.435)">
              <path
                d="M0,-97.157C-9.972,-106.681 -23.438,-112.398 -37.496,-113.786C-51.454,-115.161 -65.521,-113.036 -80.719,-103.081C-83.023,-101.634 -90.722,-95.498 -93.453,-92.136C-94.497,-90.744 -98.335,-86.236 -100.023,-83.429C-101.707,-80.625 -103.036,-78.181 -103.036,-78.181C-103.036,-78.181 -85.843,-107.644 -45.356,-100.381C-13.186,-94.829 8.668,-63.515 -0.148,-30.851C-6.162,-11.233 -24.497,3.043 -46.197,3.043C-63.695,3.043 -80.444,-5.467 -87.359,-20.151C-90.219,-26.22 -90.805,-30.28 -90.987,-36.458C-91.05,-38.676 -90.677,-39.974 -90.775,-40.279C-90.869,-40.589 -91.641,-37.565 -91.917,-35.124C-92.011,-34.169 -92.07,-33.584 -92.153,-32.402C-92.198,-31.792 -92.228,-31.309 -92.291,-30.248C-92.37,-28.809 -92.208,-26.418 -91.991,-24.448C-89.973,-10.11 -78.657,17.281 -44.371,18.004C-12.26,18.004 10.656,-4.883 17.414,-24.33C20.917,-33.179 21.854,-42.017 21.859,-49.269C21.002,-69.285 14.077,-84.665 0,-97.157"
                style={{ fill: "#ffffff", fillRule: "nonzero" }}
              />
            </g>
            <g transform="matrix(1,0,0,1,102.022,115.288)">
              <path
                d="M0,97.155C9.966,106.679 23.437,112.393 37.49,113.781C51.454,115.16 65.52,113.035 80.72,103.079C83.018,101.632 90.716,95.497 93.447,92.136C94.496,90.743 98.335,86.234 100.018,83.427C101.707,80.624 103.035,78.178 103.035,78.178C103.035,78.178 85.843,107.638 45.355,100.379C13.186,94.829 -8.668,63.515 0.147,30.849C6.157,11.231 24.496,-3.045 46.196,-3.045C63.694,-3.045 80.443,5.465 87.359,20.149C90.219,26.218 90.804,30.279 90.981,36.456C91.046,38.674 90.676,39.973 90.77,40.278C90.869,40.589 91.637,37.564 91.912,35.122C92.01,34.167 92.064,33.581 92.148,32.401C92.192,31.791 92.227,31.308 92.291,30.246C92.37,28.807 92.207,26.415 91.985,24.447C89.973,10.109 78.657,-17.282 44.367,-18.005C12.256,-18.005 -10.662,4.881 -17.415,24.329C-20.919,33.177 -21.854,42.014 -21.859,49.267C-21.007,69.282 -14.077,84.664 0,97.155"
                style={{ fill: "#ffffff", fillRule: "nonzero" }}
              />
            </g>
            <g transform="matrix(0,-1,-1,0,151.431,118.011)">
              <path
                d="M-35.864,-35.864C-55.671,-35.864 -71.728,-19.803 -71.728,0.002C-71.728,19.808 -55.671,35.864 -35.864,35.864C-16.058,35.864 0,19.808 0,0.002C0,-19.803 -16.058,-35.864 -35.864,-35.864"
                style={{ fill: "#ffffff", fillRule: "nonzero" }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoMof;
