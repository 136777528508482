import { Backdrop, CircularProgress } from "@mui/material";
import * as React from "react";

type BackdropLoadingProps = {
	isOpen: boolean;
};

const BackdropLoading: React.FC<BackdropLoadingProps> = ({ isOpen }) => {
	return (
		<Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isOpen}>
			<CircularProgress sx={{ color: "common.white" }} />
		</Backdrop>
	);
};

export default BackdropLoading;
