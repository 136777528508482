import { Stack } from "@mui/material";
import Chart from "react-apexcharts";
import { ContainerGrafico } from "../ContainerGrafico";
import { useMemo } from "react";

interface Serie {
  name: string;
  data: { quantidade: number; mes: number }[];
  color: string;
}

interface GraficoBarraProps {
  series: Serie[];
  yaxisLabel: string;
  title: string;
  onClick?: () => void;
}

export function GraficoColuna({
  series,
  yaxisLabel,
  title,
  onClick,
}: GraficoBarraProps) {
  const options: ApexCharts.ApexOptions = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#000"],
        },
      },
      colors: series.map((serie) => serie.color),
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      noData: {
        text: "Sem dados",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
      },
      series: series.map((serie) => {
        const mesesCompletos = Array(12).fill(0);
        serie.data.forEach((periodo) => {
          mesesCompletos[periodo.mes - 1] = periodo.quantidade;
        });
        return {
          name: serie.name,
          data: mesesCompletos,
        };
      }),
      xaxis: {
        type: "category",
        categories: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
      },
      yaxis: {
        title: {
          text: yaxisLabel,
        },
      },
      fill: {
        opacity: 1,
      },
    };
  }, [series, yaxisLabel]);

  return (
    <Stack>
      <ContainerGrafico title={title} onClick={onClick}>
        <Chart
          options={options}
          series={options?.series ?? []}
          type="bar"
          width="100%"
          height="95%"
        />
      </ContainerGrafico>
    </Stack>
  );
}
