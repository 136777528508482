import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: {
    razaoSocial: string;
    cnpj: string;
    estado: string;
    cidade: string;
    porcentagemUso: number;
  }[];
}

export function ModalClientes({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "id",
          headerName: "Posição",
          sortable: false,
          flex: 0.1,
        },
        {
          field: "razaoSocial",
          headerName: "Razão Social",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "cnpj",
          headerName: "CNPJ",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "estado",
          headerName: "Estado",
          sortable: false,
          flex: 0.15,
        },
        {
          field: "cidade",
          headerName: "Cidade",
          sortable: false,
          flex: 0.2,
        },
        {
          field: "porcentagemUso",
          headerName: "% de uso",
          sortable: false,
          flex: 0.1,
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
