import dayjs from "dayjs";
import { apiDashboard } from "services/axios/api";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  ListaOrcamentoPorRegiao,
  ListaOrcamentosPorPeriodo,
} from "shared/interfaces/dashboardSistemista";
import { ListaAcabamentosMaisCalculadosEmOrcamentos } from "shared/interfaces/dashboardAcabamentos";
import { ListaClientesMaisUtilizaramProjetos } from "shared/interfaces/dashboardSistemista";
import { parseDate } from "shared/helpers/dashboard";

export async function getPorcentagemOrcamentosPorRegiao(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentoPorRegiao> =
      await apiDashboard.get(
        "/FabricanteAcabamento/ListarPorcentagemOrcamentosPorRegiao",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getQuantidadeOrcamentosPorPeriodo(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentosPorPeriodo> =
      await apiDashboard.get(
        "/FabricanteAcabamento/ListarQuantidadeOrcamentosPorPeriodo",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getAcabamentosMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaAcabamentosMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/FabricanteAcabamento/ListarAcabamentosMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getClientesMaisUtilizaramProjetos(
  qtdRanking: number,
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaClientesMaisUtilizaramProjetos> =
      await apiDashboard.get(
        "/FabricanteAcabamento/ListarClientesMaisUtilizaramProjetos",
        {
          params: dates
            ? {
                qtdRanking,
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : { qtdRanking },
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}
