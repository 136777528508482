import {
  Autocomplete as MuiAutocomplete,
  SxProps,
  createFilterOptions,
} from "@mui/material";
import { CustomTextField } from "components/TextField";
import * as React from "react";
import { useMemo } from "react";

const filter = createFilterOptions<Items>();

interface Items {
  value: string | number;
  label: string;
  inputValue?: string;
}

export type AutocompleteChangeType = {
  id?: string | number;
  newValue?: string;
};

type AutocompleteProps = {
  label?: string;
  items: Items[];
  onChange?: (params: AutocompleteChangeType) => void;
  onChangeInput?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  toggleInputData?: (value: any, element: string) => void;
  helperText?: string;
  disabled?: boolean;
  freeSolo?: boolean;
  autoSelect?: boolean;
  clearOnBlur?: boolean;
  withAddNew?: boolean;
  value?: {
    id?: string | number;
    title?: string;
  };
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  loading?: boolean;
  clearOnEscape?: boolean;
  sx?: SxProps<any>;
  disablePortal?: boolean;
  id?: string;
  required?: boolean;
};

const AutoCompleteComponente: React.FC<AutocompleteProps> = ({
  label,
  items,
  onChange,
  toggleInputData,
  onChangeInput,
  helperText,
  disabled,
  value,
  name,
  freeSolo = true,
  withAddNew = true,
  clearOnBlur = false,
  autoSelect = false,
  loading,
  onBlur,
  clearOnEscape,
  sx,
  disablePortal = false,
  id,
  required,
}) => {
  const fieldValue = useMemo(() => {
    const selected = items.find(
      (item) => item.value.toString() === value?.id?.toString()
    );

    if (selected) return selected.label;

    return value?.title ?? null;
  }, [value]);

  return (
    <MuiAutocomplete
      sx={sx}
      data-testid={id}
      autoSelect={autoSelect}
      value={fieldValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          onChange?.({ newValue });
        } else if (newValue && newValue.inputValue) {
          onChange?.({ newValue: newValue.inputValue });
        } else {
          onChange?.({ id: newValue?.value, newValue: newValue?.label });
        }
      }}
      clearOnEscape={clearOnEscape}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = options.some(
          (option) => inputValue === option.label
        );

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      options={items}
      getOptionLabel={(option) => {
        if (typeof option === "string") return option;
        if (option.inputValue) return option.inputValue;
        return option.label ?? "";
      }}
      renderOption={(props, option, state) => {
        return (
          <li {...props} key={state.index}>
            {option.label}
          </li>
        );
      }}
      disablePortal={disablePortal}
      noOptionsText="Sem opções"
      clearOnBlur={clearOnBlur}
      loading={loading}
      loadingText="Carregando..."
      freeSolo={freeSolo}
      disabled={disabled}
      onBlur={onBlur}
      renderInput={(params) => (
        <CustomTextField
          name={name}
          {...params}
          fullWidth
          variant="filled"
          label={label}
          error={!!helperText}
          helperText={helperText}
          placeholder={loading ? "Carregando..." : "Selecione"}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          required={required}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          onChange={(event) => onChangeInput?.(event)}
        />
      )}
    />
  );
};

export default AutoCompleteComponente;
