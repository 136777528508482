import { styled, TablePagination } from "@mui/material";
import { DataGrid, DataGridProps, gridClasses } from "@mui/x-data-grid";

interface CustomDataGridProps extends DataGridProps {
  components?: {
    NoResultsOverlay?: () => JSX.Element;
    Footer?: () => JSX.Element | null;
    NoRowsOverlay?: () => JSX.Element;
    LoadingOverlay?: () => JSX.Element;
  };
}

export const CustomDataGrid = styled(DataGrid)<CustomDataGridProps>(
  ({ theme }) => ({
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#EBF4F9",
      "&:hover": {
        backgroundColor: "#EBF4F9",
      },
    },
    border: "none",
    "& .MuiDataGrid-columnHeadersInner": {
      background: "rgba(226, 232, 240, 1) !important",
      height: "56px",
      width: "100%",
    },
    "& .MuiDataGrid-row:hover": {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary[200]}`,
      backgroundColor: "inherit",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
      color: "#1F2020",
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
    },
    "& .MuiDataGrid-columnHeader": {
      color: "#001A29",
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      textOverflow: "clip",
      whiteSpace: "break-spaces",
      lineHeight: 1,
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      minHeight: "60vh !important",
    },
  })
);

export const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
  border: "none",
  "& .MuiTablePagination-selectLabel": {
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
  },
  "& .MuiTablePagination-displayedRows": {
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
  },
  "& .MuiTablePagination-select": {
    backgroundColor: "transparent",
  },
  "& .MuiTablePagination-toolbar": {
    fontFamily: "Roboto",
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    color: "#757575",
    paddingLeft: 0,
  },
}));
