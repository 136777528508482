import { useState } from "react";

import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridProps } from "@mui/x-data-grid";
import * as React from "react";

import { DeleteForever } from "@mui/icons-material";
import { NoRecordFound } from "./NoRecordFound";
import { SkeletonLoadingOverlay } from "./SkeletonLoadingOverlay";
import { CustomDataGrid, CustomTablePagination } from "./styles";

type ColumnOrder = {
  sortBy: string;
  direction: string;
};

type StripedDataGridProps = DataGridProps & {
  onClickDelete?(ids: number[]): void;
  renderActionsWhenSelected?(ids: ActionsDetails): React.ReactNode;
  onChangeSingleColumnOrder?(params: ColumnOrder): void;
  hideDeleteButton?: boolean;
  rowsPerPageOptions?: number[];
  hideFooter?: boolean;
  footer?: React.ReactNode;
};

type ActionsDetails = {
  ids: string[] | number[];
};

export const ROWS_PER_PAGE_OPTIONS = [400, 50, 10, 5];

export function StripedDataGrid({
  onClickDelete,
  hideDeleteButton = false,
  page = 1,
  pageSize = 10,
  renderActionsWhenSelected,
  onChangeSingleColumnOrder,
  autoHeight = true,
  checkboxSelection = true,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  hideFooter,
  footer,
  ...props
}: StripedDataGridProps) {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleClickDeleteButton = () => {
    onClickDelete?.(selectedRows);
  };

  const getActionsDetails = (): ActionsDetails => ({
    ids: selectedRows,
  });

  return (
    // <div style={{ height: "600px", overflow: "auto" }}>
    <CustomDataGrid
      {...props}
      className={`notranslate ${props.className ?? ""}`}
      checkboxSelection={checkboxSelection}
      disableSelectionOnClick
      disableColumnMenu
      hideFooterPagination
      rowsPerPageOptions={rowsPerPageOptions}
      autoHeight={autoHeight}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
      onSelectionModelChange={(selectionModel, details) => {
        setSelectedRows(selectionModel as number[]);
        props?.onSelectionModelChange?.(selectionModel, details);
      }}
      onSortModelChange={(sortModel) => {
        if (!sortModel.length) {
          return onChangeSingleColumnOrder?.({
            sortBy: "",
            direction: "",
          });
        }

        const [value] = sortModel;
        const orderKey = value.field
          .split(/(?=[A-Z])/)
          .join("-")
          .toLowerCase();
        return onChangeSingleColumnOrder?.({
          sortBy: orderKey,
          direction: value?.sort ?? "",
        });
      }}
      components={{
        NoResultsOverlay: () => <NoRecordFound />,
        Footer: () => {
          return !hideFooter ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              px={2}
              mt={1}
            >
              <table>
                <Box display="flex" flex={1} component="tbody">
                  <tr>
                    <CustomTablePagination
                      count={props.rowCount ?? 0}
                      page={page}
                      rowsPerPage={pageSize}
                      onPageChange={(_, page) => props.onPageChange?.(page, {})}
                      onRowsPerPageChange={(event) =>
                        props.onPageSizeChange?.(Number(event.target.value), {})
                      }
                      rowsPerPageOptions={rowsPerPageOptions}
                      labelRowsPerPage="Linhas por página:"
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count}`
                      }
                    />
                  </tr>
                </Box>
              </table>
              {footer}
              {!!selectedRows.length && (
                <Box>
                  {!hideDeleteButton && (
                    <Button
                      variant="text"
                      onClick={handleClickDeleteButton}
                      endIcon={<DeleteForever color="error" />}
                      sx={{
                        height: 36,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        fontFamily="Inter"
                        color="error"
                      >
                        Excluir selecionados
                      </Typography>
                    </Button>
                  )}
                  {!!renderActionsWhenSelected && (
                    <Box ml={2}>
                      {renderActionsWhenSelected(getActionsDetails())}
                    </Box>
                  )}
                </Box>
              )}
            </Stack>
          ) : null;
        },
        NoRowsOverlay: () => <NoRecordFound />,
        LoadingOverlay: () => (
          <SkeletonLoadingOverlay rowHeight={props.rowHeight ?? 50} />
        ),
      }}
    />
    // </div>
  );
}
