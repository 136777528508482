import Chart from "react-apexcharts";
import { ContainerGrafico } from "../ContainerGrafico";
import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";

export interface Serie {
  name: string;
  data: number[];
}

interface GraficoBarraProps {
  series: Serie[];
  title: string;
  categories: string[];
  onClick?: () => void;
}

export function GraficoBarra({ series, title, categories, onClick }: GraficoBarraProps) {
  const options: ApexCharts.ApexOptions = useMemo(() => {
    return {
      chart: {
        type: "bar",
        height: 350,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => {
          return `${value}%`;
        },
      },
      xaxis: {
        categories,
      },
      series: series.map((serie) => {
        return { data: serie.data, name: serie.name };
      }),
    };
  }, [series, categories]);

  return (
    <ContainerGrafico title={title} onClick={onClick}>
      <Stack direction={"row"} justifyContent={"space-between"} mt={2} mx={1}>
        <Typography sx={{ color: "rgba(97, 165, 188, 1)" }}>Código</Typography>
        <Typography sx={{ color: "rgba(97, 165, 188, 1)" }}>
          % de orçamentos/obras
        </Typography>
      </Stack>
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={350}
      />
    </ContainerGrafico>
  );
}
