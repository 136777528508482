import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { css, Typography } from "@mui/material";

const hasLinkStyles = css`
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const Link = styled(Typography, { shouldForwardProp: isPropValid })<{
	hasLink?: boolean;
}>`
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);

	${({ hasLink = false }) => hasLink && hasLinkStyles}
`;
