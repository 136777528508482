import axios from "axios";
import configs from "Config";

export const baseURL = configs.REACT_APP_BASE_URL;
export const baseURLAntigo = configs.REACT_APP_BASE_URL_ANTIGO;
export const dashboardAPIURL = configs.REACT_APP_DASHBOARD_API;

export const api = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
});

export const apiAntigo = axios.create({
  baseURL: baseURLAntigo,
  headers: { "Content-Type": "application/json" },
});

apiAntigo.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("@MOF:token");

  if (token != null && !config.headers.getAuthorization()) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }
  return config;
});

apiAntigo.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.replace(configs.REACT_APP_SITE_ANTIGO_URL!);
    }
    return Promise.reject(error.response);
  }
);

api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("@MOF:token");

  if (token != null && !config.headers.getAuthorization()) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }
  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.replace(configs.REACT_APP_SITE_ANTIGO_URL!);
    }
    return Promise.reject(error.response);
  }
);

export const apiDashboard = axios.create({
  baseURL: dashboardAPIURL,
  headers: { "Content-Type": "application/json" },
});

apiDashboard.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("@MOF:token");

  if (token != null && !config.headers.getAuthorization()) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }
  return config;
});

apiDashboard.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.replace(configs.REACT_APP_SITE_ANTIGO_URL!);
    }
    return Promise.reject(error.response);
  }
);

export const setAuthorization = (token: string | null): void => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  apiAntigo.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  apiDashboard.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
