import { ILogin, ITrocaLogin } from "shared/interfaces/signInInterfaces";
import { apiAntigo } from "./axios/api";

export async function signIn(login: ILogin) {
  return apiAntigo.post("Usuario/autenticar", login).then((res) => res.data);
}

export async function signInChange(trocaLogin: ITrocaLogin) {
  return apiAntigo.post("login/changeUser", trocaLogin).then((res) => res.data);
}
