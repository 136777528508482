import { useNavigation } from "contexts/notificationsContext";
import { usePagination } from "hooks/usePagination";
import { useState } from "react";
import { getAbasPerfisDeEmpresa } from "services/modules/perfisDeEmpresa";
import {
  GetListaPessoas,
  IGetPerfisDeEmpresa,
  IParamsPessoas,
} from "shared/interfaces/perfisDeEmpresa";

export const SEARCH_BAR_PESSOA_ID = "search-bar-componente-anchor";

export function usePerfisDeEmpresa() {
  const { openNotification } = useNavigation();
  const [pessoas, setPessoas] = useState<IGetPerfisDeEmpresa[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const {
    pagination,
    debouncedSearch,
    handlePagination,
    handleTotalItems,
    totalItems,
  } = usePagination({
    search: "",
    page: 1,
    pageSize: 10,
    direction: "asc",
    orderBy: "",
    totalItemsPage: 0,
  });

  const getPessoasData = async (params?: Partial<IParamsPessoas>) => {
    setIsLoadingData(true);
    try {
      const result = await getAbasPerfisDeEmpresa(params);

      if (result) {
        // setPessoas(result.data);
        // handleTotalItems(result.totalRecords);
        return;
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingData(false);
    }
    setPessoas([]);
  };

  function handleUpdatePage(newPage: number) {
    handlePagination({ page: newPage });

    setTimeout(() => {
      const element = document.getElementById(SEARCH_BAR_PESSOA_ID);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 200);
  }

  return {
    debouncedSearch,
    totalItems,
    handleUpdatePage,
    handlePagination,
    getPessoasData,
    pessoas,
    isLoadingData,
    pagination,
  };
}
